import { IServerSideSelectionState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, createContext, useCallback, useMemo, useState } from 'react';

export type AgGridSelectedRowsContextType = {
    gridRef: AgGridReact<any> | null;
    allRowsId: string[]; //set in case of SSRM enabled and all rows are selected by default
    selectedRowIds: string[];
    selectedRowsData: any[];
    setGridRef: Dispatch<AgGridReact<any> | null>;
    onRowSelected: (e: any) => void;
    clearRowsSelections: () => void;
    selectAllRows: (rowsId: string[]) => void;
    setAllRowsId: (rowsId: string[]) => void;
    getUnselectedRowsId: () => string[];
};

export const AgGridSelectedRowsContext = createContext<AgGridSelectedRowsContextType>({
    gridRef: null,
    allRowsId: [],
    selectedRowIds: [],
    selectedRowsData: [],
    setGridRef: () => {},
    onRowSelected: () => {},
    clearRowsSelections: () => {},
    selectAllRows: () => {},
    setAllRowsId: () => {},
    getUnselectedRowsId: () => [],
} as AgGridSelectedRowsContextType);

export function AgGridSelectedRowsContextProvider({
    children,
    isSSRMEnabled,
}: {
    children: React.ReactNode;
    isSSRMEnabled?: boolean;
}) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [gridRef, setGridRef] = useState<AgGridReact<any> | null>(null);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [allRowsId, setAllRowsId] = useState<string[]>([]);

    function onRowSelected(e: any) {
        if (gridRef !== null) {
            if (e.source !== 'apiSelectAll') {
                setSelectedRowIds(getSelectedRowsId({ gridRef, allRowsId, isSSRMEnabled }));
            }
        }
    }

    const selectedRowsData: any[] = useMemo(() => {
        return getSelectedRowsData({ gridRef, allRowsId, isSSRMEnabled });
    }, [selectedRowIds, isSSRMEnabled, gridRef, allRowsId]);

    const clearRowsSelections = useCallback(() => {
        if (gridRef !== null) {
            gridRef.api.deselectAll();
            setSelectedRowIds([]);
            setAllRowsId([]);
        }
    }, [gridRef]);

    const selectAllRows = useCallback(
        (rowsId: string[]) => {
            if (gridRef !== null) {
                setAllRowsId(rowsId);
                setSelectedRowIds(rowsId);
                gridRef.api.selectAll();
            }
        },
        [gridRef],
    );

    const getUnselectedRowsId = useCallback(() => {
        if (gridRef !== null) {
            const serverSideSelectionState = gridRef.api.getServerSideSelectionState() as IServerSideSelectionState;
            if (serverSideSelectionState?.selectAll) {
                return serverSideSelectionState?.toggledNodes;
            } else {
                return allRowsId.filter((id: string) => !serverSideSelectionState?.toggledNodes.includes(id));
            }
        } else {
            return [];
        }
    }, [gridRef, allRowsId]);

    const contextValue = {
        gridRef,
        allRowsId,
        selectedRowIds,
        selectedRowsData,
        setGridRef,
        onRowSelected,
        clearRowsSelections,
        selectAllRows,
        setAllRowsId,
        getUnselectedRowsId,
    };
    return (
        <AgGridSelectedRowsContext.Provider value={contextValue}>
            {memorizedChildren}
        </AgGridSelectedRowsContext.Provider>
    );
}

type GetSelectedRowsData = {
    gridRef: AgGridReact<any> | null;
    isSSRMEnabled?: boolean;
    allRowsId?: string[];
};

export function getSelectedRowsId({ gridRef, allRowsId = [], isSSRMEnabled = true }: GetSelectedRowsData) {
    if (!gridRef?.api) {
        return [];
    }

    if (isSSRMEnabled) {
        const serverSideSelectionState = gridRef.api.getServerSideSelectionState() as IServerSideSelectionState;
        if (serverSideSelectionState) {
            if (serverSideSelectionState.selectAll) {
                return allRowsId.filter((id) => !serverSideSelectionState.toggledNodes.includes(id));
            }
            return serverSideSelectionState.toggledNodes;
        }
        return [];
    } else {
        return gridRef.api.getSelectedRows().map((row) => row.id);
    }
}

export function getSelectedRowsData({ gridRef, allRowsId = [], isSSRMEnabled = true }: GetSelectedRowsData) {
    if (!gridRef?.api) {
        return [];
    }

    if (isSSRMEnabled) {
        const serverSideSelectionState = gridRef.api.getServerSideSelectionState() as IServerSideSelectionState;
        if (serverSideSelectionState?.selectAll) {
            const selectedRowsIds = allRowsId.filter((id) => !serverSideSelectionState.toggledNodes.includes(id));
            return selectedRowsIds.map((id) => ({ id }));
        }
    }

    return gridRef.api.getSelectedRows();
}
