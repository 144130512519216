import { ETFInfoButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFInfoButton';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { SxProps } from '@mui/material';
import { useCallback } from 'react';

type Props = {
    title: string;
    description: string | React.ReactNode;
    buttonFontSize?: number;
    analyticsOpenModalCallback?: () => void;
    modalBoxStyles?: SxProps;
    zIndex?: number;
    titleStyle?: SxProps;
    descriptionStyle?: SxProps;
};

export function ETFInfo({ title, description, buttonFontSize, analyticsOpenModalCallback, modalBoxStyles, 
        zIndex, titleStyle, descriptionStyle }: Props) {
    const getOpenModalButton = useCallback(
        (handleOpen: () => void) => {
            return <ETFInfoButton onClick={handleOpen} buttonFontSize={buttonFontSize} />;
        },
        [buttonFontSize],
    );

    return (
        <ETFModal
            getOpenComponent={getOpenModalButton}
            title={title}
            titleStyle={titleStyle}
            description={description}
            analyticsOpenModalCallback={analyticsOpenModalCallback}
            modalBoxStyles={{
                display: 'block',
                maxWidth: '1400px',
                maxHeight: 'none',
                overflowY: 'hidden',
                ...modalBoxStyles,
            }}
            zIndex={zIndex}
            descriptionStyle={descriptionStyle}
        />
    );
}
