import { StringKeyValueItemWithData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerFieldsUserData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { AggregatedScreenerFieldsData, UnAggregatedScreenerFieldData } from '../types/savedViews';

// get post request body for screener endpoint
export const aggregateScreenerFieldsData = (data: Array<UnAggregatedScreenerFieldData>): AggregatedScreenerFieldsData =>
    data
        .map((item) => {
            // get view data with new order values
            return {
                [item.metaData.source_field]: item.viewData,
            };
        })
        .reduce(
            // transform data to API request body structure
            (accumulator, currentValue) => ({
                ...accumulator,
                ...currentValue,
            }),
            {},
        );

// get Array<StringKeyValueItemWithViewdata> from user saved data
export function extendUserFieldsData({
    allFieldsData,
    userFieldsData,
}: {
    allFieldsData: Array<StringKeyValueItemWithData>;
    userFieldsData: ScreenerFieldsUserData;
}): Array<StringKeyValueItemWithData> {
    return Object.entries(userFieldsData).map(([metadata_key, value]) => {
        const fullFieldData = allFieldsData.find((item) => item.key === metadata_key);

        if (!fullFieldData) {
            throw new Error(
                `getUserScreenerFieldsData didn't find full field data for user field with metadata key "${metadata_key}"`,
            );
        }

        return {
            ...fullFieldData,
            viewData: { ...fullFieldData.viewData, order: value.view_data.order },
            metaData: { ...fullFieldData.metaData, id: value.metadata.id },
        };
    });
}

// get user data for save
export const transformToScreenerFieldsUserData = (
    selectedFields: Array<StringKeyValueItemWithData>,
): ScreenerFieldsUserData =>
    selectedFields
        .map((item) => ({
            [item.key]: { view_data: { order: item.viewData.order }, metadata: { id: item.metaData.id } },
        }))
        .reduce(
            // transform data to API request body structure
            (accumulator, currentValue) => ({
                ...accumulator,
                ...currentValue,
            }),
            {},
        );
