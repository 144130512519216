import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';

export const ScreenerDefaultViews: Record<string, { label: string; key: string }> = {
    Overview: {
        label: 'Overview',
        key: 'overview',
    },
    Flows: {
        label: 'Flows',
        key: 'flows',
    },
    Performance: {
        label: 'Performance',
        key: 'performance',
    },
    Fundamentals: {
        label: 'Fundamentals',
        key: 'fundamentals',
    },
};

export type AllCustomViewsBaseDataResponse = {
    data: Array<SavedItemTypes>;
};

export type ScreenerFieldsUserData = {
    [key: string]: {
        // key is field metadata key
        view_data: {
            order: number;
        };
        metadata: {
            id: number;
        };
    };
};

export type CustomViewExtendedData = {
    user_id: number;
    value: ScreenerFieldsUserData;
    is_active: boolean;
    created_date: string;
} & SavedItemTypes;

export type CustomViewsExtendedDataResponse = {
    data: CustomViewExtendedData;
};
