import FormFilePicker from '@cfra-nextgen-frontend/shared/src/components/Form/FormFilePicker';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import {
    getHookFormValidationRules,
    validatorNameToFunc,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { quote } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

export default function ScreenerFormFilePicker({
    filtersData,
    filterMetadataKey,
    control,
    layoutProps,
    getValues,
    setValue,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
}: FilterProps) {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);
    const fileValidationConfig = filterMetadata.file_validation_config;

    if (!fileValidationConfig) {
        throw new Error('File validation config is required for file picker');
    }

    // check for unexpected custom validators
    const unexpectedValidatorNames: Array<string> = (
        fileValidationConfig.columns.flatMap((item) => item.validators || []) as Array<string>
    ).filter((item) => Object.keys(validatorNameToFunc).indexOf(item) < 0);

    if (unexpectedValidatorNames.length > 0) {
        throw new Error(
            `FormFilePicker exception. Unexpected validator(s) found: ${unexpectedValidatorNames
                .map((item) => quote(item))
                .join(', ')}`,
        );
    }

    const filter = (
        <FormFilePicker
            name={combineIntoFormElementName({
                componentName: Components.FilePicker,
                filterMetadataKey,
            })}
            validationRules={validationRules}
            control={control}
            getValues={getValues}
            setValue={setValue}
            submitHandler={submitHandler}
            fileValidationConfig={filterMetadata.file_validation_config!}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
}
