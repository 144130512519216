import ScreenerSearchContainer, { fontStyling } from './ScreenerSearchContainer';
import { Box } from '@mui/material';

export function ScreenerFilterNoResults(): JSX.Element {
    return (
        <ScreenerSearchContainer itemStyle={{ paddingTop: '25px', paddingBottom: '10px' }}>
            <Box sx={{ ...fontStyling }}>No Results</Box>
        </ScreenerSearchContainer>
    );
}
