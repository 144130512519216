import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import createTheme from '@mui/material/styles/createTheme';

export const tickerSearchTheme = createTheme(breakpointsTheme, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#3078B5',
                    width: 24,
                    height: 24,
                    padding: 0,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#3078B5',
                    width: 24,
                    height: 24,
                    padding: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '6px 15px 6px 15px',
                },
                input: {
                    padding: 0,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '36px',
                    width: '200px',
                    borderRadius: '4px',
                    margin: '16px 28px 17px 28px',
                    backgroundColor: '#f2f5fc',
                },
                input: {
                    height: '24px',
                    font: fontFamilies.GraphikRegular,
                    fontSize: 14,
                    color: '#57626a',
                    letterSpacing: '0.8px',
                },
            },
        },
    },
});
