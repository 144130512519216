import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { ReadMoreCore } from './ReadMoreCore';

type ReadMoreProps = {
    text: string;
    modalTitle: string;
    textLengthToShowReadMore?: number;
    className?: string;
};

export function ReadMore({ text, modalTitle, textLengthToShowReadMore = 500, className }: ReadMoreProps) {
    return (
        <ETFCard.ItemDescription className={className}>
            <ReadMoreCore
                text={text}
                modalTitle={modalTitle}
                textLengthToShowReadMore={textLengthToShowReadMore}
                linkText='Read More'
            />
        </ETFCard.ItemDescription>
    );
}
