import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { SxProps, ThemeProvider, createTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { textFieldTheme } from './FormControlledTextField';
import { inputBaseStyles } from './FormTextField';
import { LISTBOX_PADDING, StyledPopper } from './FormVirtualizeAutocomplete';
import { CommonFormComponentProps } from './types/form';

const autoCompleteFreesoloTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...inputBaseStyles,
                    ...inputFontStyle,
                },
                input: {
                    padding: '0px !important',
                    '&::placeholder': {
                        ...inputFontStyle,
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    width: '300px !important',
                },
                input: {
                    '&::placeholder': {
                        ...inputFontStyle,
                    },
                    flexBasis: '100%',
                },
                paper: {
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                    boxShadow: 'none',
                },
                listbox: {
                    padding: `${LISTBOX_PADDING}px 0px ${LISTBOX_PADDING}px`,
                    '::-webkit-scrollbar': {
                        width: '20px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#d1d8e8',
                    },
                    '::-webkit-scrollbar-track-piece': {
                        backgroundColor: '#f2f5fc',
                    },
                },
                option: {
                    height: '48px',
                    padding: '0px 24px 0px 24px !important',
                    ':hover': {
                        backgroundColor: '#f7f6f7',
                    },
                },
                popupIndicator: {
                    width: '24px',
                    height: '24px',
                },
            },
        },
        MuiTextField: {
            ...textFieldTheme?.components?.MuiTextField,
        },
        MuiFormHelperText: {
            ...textFieldTheme?.components?.MuiFormHelperText,
        },
    },
});

type FormAutocompleteFreesoloProps = {
    options: Array<string>;
    placeholder: string;
    defaultValue: string;
    validationRules?: any;
    fieldDefaultStyles?: SxProps;
    disabled?: boolean;
} & CommonFormComponentProps;

export default function FormAutocompleteFreesolo(props: FormAutocompleteFreesoloProps) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
            rules={props.validationRules}
            render={({ field, fieldState: { error } }) => (
                <ThemeProvider theme={autoCompleteFreesoloTheme}>
                    <Autocomplete
                        readOnly={props.readOnly}
                        {...field}
                        freeSolo
                        disableClearable
                        disableListWrap
                        disabled={props.options.length === 0 || props.disabled}
                        options={props.options}
                        getOptionLabel={(option) => (Array.isArray(option) ? option.join(',') : option)}
                        value={field.value}
                        PopperComponent={StyledPopper}
                        sx={{ minWidth: '125px', maxWidth: '275px', ...props.fieldDefaultStyles }}
                        slotProps={{
                            popper: {
                                sx: { zIndex: 3000 },
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={props.placeholder}
                                onChange={(e) => {
                                    field.onChange(e.target.value);
                                    props.onChangeClearHandler?.(field.name);
                                    if (Boolean(error?.message)) {
                                        props.submitHandler?.();
                                    }
                                }}
                                error={Boolean(error?.message)}
                                helperText={Boolean(error?.message) ? error?.message : ''}
                            />
                        )}
                        onChange={(_, data) => {
                            field.onChange(data);
                            props.onChangeClearHandler?.(field.name);
                            props.submitHandler?.();
                        }}
                        onBlur={(_) => {
                            props.submitHandler?.();
                        }}
                    />
                </ThemeProvider>
            )}
        />
    );
}
