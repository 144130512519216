import React, { Dispatch, createContext, useState } from 'react';

export type PageWithBreadcrumbsInHeaderContextProps = {
    rightSideSlotContent: React.ReactNode;
    setRightSideSlotContent: Dispatch<React.ReactNode>;
};

export const PageWithBreadcrumbsInHeaderContext = createContext<PageWithBreadcrumbsInHeaderContextProps>(
    {} as PageWithBreadcrumbsInHeaderContextProps,
);

type Props = {
    children: React.ReactNode;
};

export const PageWithBreadcrumbsInHeaderContextProvider: React.FC<Props> = ({ children }) => {
    const [rightSideSlotContent, setRightSideSlotContent] = useState<React.ReactNode | null>(null);

    return (
        <PageWithBreadcrumbsInHeaderContext.Provider value={{ rightSideSlotContent, setRightSideSlotContent }}>
            {children}
        </PageWithBreadcrumbsInHeaderContext.Provider>
    );
};
