import {
    ENABLE_RUM,
    RUM_APPLICATION_ID,
    RUM_APPLICATION_REGION,
    RUM_ENDPOINT,
    RUM_GUEST_ROLE_ARN,
    RUM_IDENTITY_POOL_ID,
} from '@cfra-nextgen-frontend/shared/src/config';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

type PageAttributes = {
    pageId: string;
    pageTags?: string[];
};

class ClientSideLogger {
    private rumInstance: AwsRum | null = null;

    constructor(awsRumInstace: AwsRum | null) {
        this.rumInstance = awsRumInstace;
    }

    /**
     * Update the current page the user is interacting with.
     * @param payload Can be string or PageAttributes object
     *      If string, payload is pageId (The unique ID for the page within the application).
     *      If PageAttributes, payload contains pageId as well as page attributes to include in events with pageId
     */
    recordPageView(payload: string | PageAttributes): void {
        if (this.rumInstance) {
            this.rumInstance.recordPageView(payload);
        }
    }

    /**
     * Record an error using the JS error plugin.
     * @param error An ErrorEvent, Error or primitive.
     */
    recordError(error: any): void {
        if (this.rumInstance) {
            this.rumInstance?.recordError(error);
        }
    }
}

let awsRumInstance: AwsRum | null = null;

try {
    if (ENABLE_RUM) {
        const config: AwsRumConfig = {
            sessionSampleRate: 1,
            guestRoleArn: RUM_GUEST_ROLE_ARN,
            identityPoolId: RUM_IDENTITY_POOL_ID,
            endpoint: RUM_ENDPOINT,
            telemetries: ['performance', 'errors', 'http'],
            allowCookies: true,
            enableXRay: true,
        };

        const APPLICATION_ID: string = RUM_APPLICATION_ID;
        const APPLICATION_VERSION: string = '1.0.0';
        const APPLICATION_REGION: string = RUM_APPLICATION_REGION;

        awsRumInstance = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    }
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

export const clientSideLogger = new ClientSideLogger(awsRumInstance);
