import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { Shadows, ThemeOptions, createTheme } from '@mui/material/styles';

export const breakpointsTheme = createTheme({
    breakpoints: {
        values: {
            lg: 1200,
            md: 900,
            sm: 700,
            xl: 1536,
            xs: 0,
        },
    },
});

const theme = createTheme(breakpointsTheme, {
    typography: {
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: 15,
        allVariants: {
            color: 'black',
        },
    },
    shadows: Array(25).fill('none') as Shadows,
});

export const appTheme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
    },
});

export const tooltipTheme = createTheme(appTheme, {
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
            },
            styleOverrides: {
                popper: {
                    zIndex: 3000,
                },
                tooltip: {
                    padding: '12px 24px 12px 24px',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#57626a',
                    fontSize: '14px',
                    backgroundColor: '#FFF',
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
                arrow: {
                    '&::before': {
                        color: '#FFF',
                    },
                },
            },
        },
    },
});

export const tooltipThemeV2 = createTheme(appTheme, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    popper: {
                        zIndex: 2000,
                    },
                    width: 'auto',
                    fontFamily: 'GraphikRegular',
                    backgroundColor: '#fff',
                    padding: '12px 24px 12px 24px',
                    color: '#57626a',
                    radius: '5px',
                    fontSize: '14px',
                    boxShadow: '0 3px 5px 2px rgba(38, 42, 46, 0.12)',
                    '& .MuiTooltip-arrow': {
                        '&::before': {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
        },
    },
});

export const tooltipThemeV3 = createTheme(appTheme, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    popper: {
                        zIndex: 2000,
                    },
                    width: 'auto',
                    backgroundColor: '#fff',
                    padding: '20px',
                    radius: '10px',
                    boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.25)',
                    '& .MuiTooltip-arrow': {
                        '&::before': {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
        },
    },
});

export const tableTheme = createTheme(tooltipTheme, {
    components: {
        MuiCustomBox: {
            styleOverrides: {
                root: {
                    padding: '28px 0px 28px 0px',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    lineHeight: 1,
                    paddingTop: '0px',
                    fontFamily: fontFamilies.GraphikMedium,
                    color: '#57626a',
                    borderBottom: '1px solid #74828D',
                },
                body: {
                    paddingTop: '16px',
                    verticalAlign: 'center',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#002B5A',
                    lineHeight: '22px',
                    borderBottom: '1px dashed #74828D',
                    borderRight: '2px solid white',
                },
                root: {
                    '&:not(:first-of-type)': {
                        paddingLeft: '0px',
                    },
                    fontSize: '15px',
                    paddingRight: '28px',
                    paddingBottom: '16px',
                    paddingLeft: '28px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                item: {
                    justifyContent: 'space-between',
                    display: 'flex',
                },
            },
        },
        MuiTotalRowBox: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    paddingTop: '16px',
                },
            },
        },
    },
});

export const homePageTheme = createTheme({
    typography: {
        h1: {
            fontSize: '60px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h2: {
            fontSize: '48px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h4: {
            fontSize: '36px',
            lineHeight: '48px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h5: {
            fontSize: '24px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#007BB8',
        },
        h6: {
            fontSize: '18px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        subtitle1: {
            fontSize: '32px',
            fontWeight: fontWeights.SemiBold,
            fontFamily: fontFamilies.GraphikSemibold,
            color: '#002B5A',
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: fontWeights.Medium,
            fontFamily: fontFamilies.GraphikMedium,
            color: '#57626A',
        },
        body1: {
            fontSize: '24px',
            fontWeight: fontWeights.SemiBold,
            fontFamily: fontFamilies.GraphikSemibold,
            color: '#002B5A',
        },
        body2: {
            fontSize: '20px',
            lineHeight: '32px',
            fontWeight: fontWeights.Regular,
            fontFamily: fontFamilies.GraphikRegular,
            color: '#57626A',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    '@media (max-width:1200px)': {
                        fontSize: '52px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '44px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '36px',
                    },
                },
                h2: {
                    '@media (max-width:1200px)': {
                        fontSize: '40px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '32px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '28px',
                    },
                },
                h4: {
                    '@media (max-width:1200px)': {
                        fontSize: '32px',
                        lineHeight: '36px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '28px',
                        lineHeight: '32px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '26px',
                        lineHeight: '28px',
                    },
                },
                h5: {
                    '@media (max-width:900px)': {
                        fontSize: '22px',
                    },
                },
                h6: {
                    '@media (max-width:900px)': {
                        fontSize: '16px',
                    },
                },
                subtitle1: {
                    '@media (max-width:1200px)': {
                        fontSize: '27px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '23px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '18px',
                    },
                },
                body1: {
                    '@media (max-width:1200px)': {
                        fontSize: '22px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '16px',
                    },
                },
                body2: {
                    '@media (max-width:1200px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '16px',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
});

export const scrollbarTheme = {
    '::-webkit-scrollbar': {
        width: '20px',
    },
    '::-webkit-scrollbar-thumb': {
        backgroundColor: '#d1d8e8',
    },
    '::-webkit-scrollbar-track-piece': {
        backgroundColor: '#f2f5fc',
    },
};

export const scrollbarThemeV2 = {
    '::-webkit-scrollbar': {
        width: '15px',
    },
    '::-webkit-scrollbar-button': {
        height: '0px',
    },
    '::-webkit-scrollbar-thumb': {
        minHeight: '51%',
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        backgroundColor: '#BDBDBD',
        borderRadius: '25px',
    },
};

export const scrollbarThemeV3 = {
    '::-webkit-scrollbar': {
        width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
        backgroundColor: '#E4E8F4',
        backgroundClip: 'padding-box',
        borderRadius: '25px',
    },
    '::-webkit-scrollbar-track-piece': {
        backgroundColor: '#FFF',
    },
};

export const overlayModalTheme = createTheme(appTheme, {
    components: {
        MuiModalBox: {
            styleOverrides: {
                root: {
                    padding: '0px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
} as ThemeOptions);
