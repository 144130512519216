import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
    getWatchlistEtfsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { WatchListRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { createSavedItem, getSavedItemsByType } from 'features/etfScreener/api/userSavedItems';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SaveModalForm from '../SaveModalForm';
import { getSavedWatchLists, getWatchListData } from './WatchListUtils';

export default function CreateWatchListComponent({
    placeholder,
    onSuccessCallback,
    onIsDirtyChange,
    externalViewWatchlist,
    watchListData,
    modalTitle,
}: {
    placeholder?: string;
    onSuccessCallback?: () => void;
    onIsDirtyChange: (v: boolean) => void;
    externalViewWatchlist?: boolean;
    watchListData?: Array<WatchListRow>;
    modalTitle?: string;
}) {
    const {
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);
    const { setSearchTerm } = useContext(FiltersModalContext);
    const { watchListDispatcher } = useContext(WatchListContext);
    const { selectedRowsData, clearRowsSelections } = useContext(AgGridSelectedRowsContext);
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);
    const [watchlistName, setWatchlistName] = useState('');
    const [watchlistData, setWatchlistData] = useState<undefined | WatchListRow[]>(undefined);

    const navigate = useNavigate();
    const navigateToScreener: (data: Object) => void = useCallback(
        (data) =>
            navigate('/screener', {
                state: {
                    setSelectedWatchlist: data,
                },
            }),
        [navigate],
    );

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const enableCreateWatchlist = watchlistData !== undefined && watchlistName !== '';

    const saveWatchList = createSavedItem({
        requestBody: {
            name: watchlistName,
            saved_item_lid: UserSavedItemsLookupID.Watchlist,
            value: {
                values: watchlistData,
            },
        },
        config: {
            enabled: enableCreateWatchlist,
        },
    });

    const getAllWatchLists = getSavedItemsByType({ types: [UserSavedItemsLookupID.Watchlist] });

    useEffect(() => {
        if (saveWatchList.isSuccess && watchlistName && enableCreateWatchlist) {
            const analyticsEtfsData = getWatchlistEtfsData(
                (saveWatchList.data.data.value as { values: WatchListRow[] }).values,
            );

            const savedItemOperation = SavedItemOperations.SaveWatchlist;

            const registerActionParams = {
                action: `${savedItemOperation} : ${getSelectedItem(saveWatchList.data.data)}`,
                cardName: modalTitle,
                savedItemName: saveWatchList.data.data.name,
                savedItemId: saveWatchList.data.data.id,
                savedItemOperation: savedItemOperation,
                savedItemLength: analyticsEtfsData.length,
                list2: analyticsEtfsData,
            };

            if (registerAction) {
                registerAction(registerActionParams);
            } else {
                globalThis.analytics?.registerAction?.(registerActionParams);
            }
        }
    }, [
        enableCreateWatchlist,
        modalTitle,
        registerAction,
        saveWatchList.data?.data,
        saveWatchList.isSuccess,
        watchlistName,
    ]);

    useEffect(() => {
        if (saveWatchList.isSuccess && watchlistName && enableCreateWatchlist) {
            const snackbarKey = ShowSnack(
                SnackMessageForm({
                    message: `${watchlistName} created successfully`,
                    actionBtnText: 'View Watchlist',
                    onActionBtnClick: () => {
                        if (saveWatchList?.data?.data) {
                            onChipClearAllClick();

                            if (saveScreenActionDispatcher !== undefined)
                                saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });

                            if (setSearchTerm !== undefined) setSearchTerm('');

                            watchListDispatcher({ type: 'SetSelectedWatchlist', payload: saveWatchList?.data?.data });
                            if (snackbarKey) {
                                closeSnackbar(snackbarKey);
                            }

                            if (externalViewWatchlist) {
                                navigateToScreener(saveWatchList?.data?.data);
                            }
                        }
                    },
                }),
            );
            clearRowsSelections();
            watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
            getAllWatchLists.refetch();
            onSuccessCallback?.();
        }
    }, [
        saveWatchList,
        clearRowsSelections,
        watchListDispatcher,
        getAllWatchLists,
        enqueueSnackbar,
        watchlistName,
        ShowSnack,
        enableCreateWatchlist,
        onChipClearAllClick,
        saveScreenActionDispatcher,
        setSearchTerm,
        closeSnackbar,
        onSuccessCallback,
        navigateToScreener,
        externalViewWatchlist,
    ]);

    const onWatchListNameChange = (newValue: string) => setWatchlistName(newValue);

    function handleSaveClick() {
        setWatchlistData(watchListData === undefined ? getWatchListData(selectedRowsData) : watchListData);
    }

    const savedWatchLists = getSavedWatchLists(getAllWatchLists);
    return (
        <SaveModalForm
            placeholder={placeholder}
            saveType={'Save WatchList'}
            onPositiveAction={handleSaveClick}
            positiveBtnText='Save'
            existingNames={savedWatchLists.map((watchlist: any) => watchlist.name)}
            onWatchListNameChange={onWatchListNameChange}
            onIsDirtyChange={onIsDirtyChange}
        />
    );
}
