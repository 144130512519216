import { ETFCard, Layout } from '@cfra-nextgen-frontend/shared';
import { ReadMore } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMore';

export default function FundDetail(props: { detail: string }) {

    return (
        <ETFCard.ETFCard cardLabel='Fund Details'>
            <Layout.Grid item xs={12}>
                <ReadMore text={props.detail} modalTitle={'Fund Details'} />
            </Layout.Grid>
        </ETFCard.ETFCard>
    );
}
