import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import {
    CustomViewExtendedData,
    ScreenerDefaultViews,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import {
    screenerCurrentViewLabel,
    ssrmMaxRowsToFetch,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { extendUserFieldsData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/savedViews';
import { Tabs } from '@cfra-nextgen-frontend/shared/src/components/Tabs';
import { sortByDateString } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SxProps } from '@mui/material';
import { getAllCustomViews, getCustomViewsDetails } from 'features/etfScreener/api/customViews';
import { SavedViewMenu } from 'features/etfScreener/components/SavedViewsMenu';
import { useContext, useEffect, useMemo } from 'react';
import { prefetchApiData } from 'utils';
import { getScreenerData, getScreenerReqBody } from '../api/screener';
import { SaveViewModal } from './SaveViewModal';

function prefetchScreenerData(requestArgsList: Array<SearchByParams>) {
    prefetchApiData({
        requestArgsList: requestArgsList,
        requestCallback: ({ requestBody, view, search }: SearchByParams) => {
            getScreenerData({
                requestBody,
                view,
                search,
                from: 0,
                size: ssrmMaxRowsToFetch,
                usePrefetchQuery: true,
            });
        },
    });
}

function EditActiveViewHandler({ customViewsDetails }: { customViewsDetails: Array<CustomViewExtendedData> }) {
    const {
        customViewEditorState: { editActiveView },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);
    const {
        screenerViewState: { screenerActiveView, allFieldsData },
    } = useContext(ScreenerViewContext);

    useEffect(() => {
        if (!editActiveView) {
            return;
        }

        const setEditActiveViewDone = () =>
            customViewEditorStateDispatcher({
                type: ['SetEditActiveViewDone'],
            });

        if (!allFieldsData) {
            throw new Error('editActiveView got undefined allFieldsData.');
        }

        const isCurrentViewSelected = screenerActiveView?.isCurrentView;

        if (isCurrentViewSelected) {
            if (!screenerActiveView?.fieldsData) {
                throw new Error('Current view contains empty screenerActiveView?.fieldsData.');
            }
            customViewEditorStateDispatcher({
                type: ['SetScreenerUpdateView'],
                newState: {
                    screenerUpdateView: screenerActiveView,
                },
            });
            setEditActiveViewDone();
            return;
        }

        const isCustomViewSelected = screenerActiveView?.key === 'custom' && !isCurrentViewSelected;

        if (isCustomViewSelected) {
            if (!screenerActiveView.label) {
                throw new Error('Invalid active custom view name.');
            }

            const selectedCustomViewIndex = customViewsDetails
                .map((view) => view.name)
                .indexOf(screenerActiveView.label);

            if (selectedCustomViewIndex < 0) {
                throw new Error("Can't find active custom view index.");
            }

            const selectedCustomViewDetails = customViewsDetails[selectedCustomViewIndex];

            customViewEditorStateDispatcher({
                type: ['SetScreenerUpdateView'],
                newState: {
                    screenerUpdateView: {
                        label: selectedCustomViewDetails.name,
                        savedItemId: selectedCustomViewDetails.id,
                        fieldsData: extendUserFieldsData({
                            allFieldsData,
                            userFieldsData: selectedCustomViewDetails.value,
                        }),
                    },
                },
            });

            setEditActiveViewDone();
            return;
        } else {
            throw new Error("Can't edit default view.");
        }
    }, [
        allFieldsData,
        customViewEditorStateDispatcher,
        customViewsDetails,
        editActiveView,
        screenerActiveView,
        screenerActiveView?.isCurrentView,
        screenerActiveView?.key,
        screenerActiveView?.label,
    ]);

    return null;
}

export function ScreenerViews() {
    const {
        screenerViewState: { refetchPendingType, screenerCurrentView, screenerActiveView, allFieldsData },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);
    const { filtersPostData, searchTerm, openFiltersModal } = useContext(FiltersModalContext);
    const customViewsQueryResult = getAllCustomViews({});

    // refetch all custom views list after save new custom view
    useEffect(() => {
        if (refetchPendingType === 'all') {
            customViewsQueryResult.refetch();
            screenerViewActionDispatcher({ type: 'SetRefetchDone' });
        }
    }, [customViewsQueryResult, screenerViewActionDispatcher, refetchPendingType]);

    const defaultViews = Object.values(ScreenerDefaultViews);

    const customViews: Array<SavedItemTypes> = useMemo(() => {
        // fill customViews only when general info about all views is loaded
        if (
            customViewsQueryResult.data &&
            customViewsQueryResult.data.data &&
            customViewsQueryResult.data.data.length > 0
        ) {
            return customViewsQueryResult.data.data;
        }

        return [];
    }, [customViewsQueryResult.data]);

    // fetch view details
    const customViewsDetailsQueryResults = getCustomViewsDetails({ savedItems: customViews.map((view) => view.id) });

    const isLoadedDetails = useMemo(
        () =>
            customViewsDetailsQueryResults.every(
                (customViewDetailsQueryResult) =>
                    customViewDetailsQueryResult &&
                    !customViewDetailsQueryResult.isLoading &&
                    customViewDetailsQueryResult.data &&
                    customViewDetailsQueryResult.data.data,
            ),
        [customViewsDetailsQueryResults],
    );

    // refetch specific custom view after rename
    useEffect(() => {
        if (isLoadedDetails && typeof refetchPendingType === 'number') {
            customViewsDetailsQueryResults.find((query) => query.data?.data.id === refetchPendingType)?.refetch();
            screenerViewActionDispatcher({ type: 'SetRefetchDone' });
        }
    }, [
        customViewsQueryResult,
        screenerViewActionDispatcher,
        refetchPendingType,
        isLoadedDetails,
        customViewsDetailsQueryResults,
    ]);

    const customViewsDetails = useMemo(() => {
        let result: Array<CustomViewExtendedData> = [];

        if (!allFieldsData) {
            return result;
        }

        customViewsDetailsQueryResults.forEach((customViewDetailsQueryResult) => {
            if (customViewDetailsQueryResult.data && customViewDetailsQueryResult.data.data) {
                result.push({
                    ...customViewDetailsQueryResult.data.data,
                });
            }
        });
        result.sort(sortByDateString('created_date', 'asc'));

        return result;
    }, [customViewsDetailsQueryResults, allFieldsData]);

    // prefetch data for default views
    useEffect(() => {
        if (!openFiltersModal) {
            prefetchScreenerData(
                defaultViews.map((view) => ({
                    requestBody: getScreenerReqBody(filtersPostData),
                    view: view.key,
                    search: searchTerm,
                })),
            );
        }
    }, [defaultViews, filtersPostData, searchTerm, openFiltersModal]);

    // prefetch custom views data
    useEffect(() => {
        if (customViewsDetails.length > 0 && allFieldsData && !openFiltersModal) {
            prefetchScreenerData(
                customViewsDetails.map((view) => ({
                    requestBody: getScreenerReqBody(
                        filtersPostData,
                        extendUserFieldsData({ allFieldsData, userFieldsData: view.value }),
                    ),
                    view: 'custom',
                    search: searchTerm,
                })),
            );
        }
    }, [allFieldsData, customViewsDetails, filtersPostData, searchTerm, openFiltersModal]);

    // prefetch current view data
    useEffect(() => {
        if (screenerCurrentView?.fieldsData && allFieldsData && !openFiltersModal) {
            prefetchScreenerData([
                {
                    requestBody: getScreenerReqBody(filtersPostData, screenerCurrentView.fieldsData),
                    view: 'custom',
                    search: searchTerm,
                },
            ]);
        }
    }, [filtersPostData, screenerCurrentView?.fieldsData, allFieldsData, searchTerm, openFiltersModal]);

    if (!screenerActiveView) {
        throw new Error('screenerActiveView is not set.');
    }

    const isCurrentViewSelected = screenerActiveView.isCurrentView;
    const isCustomKey = screenerActiveView.key === 'custom';
    const isCustomViewSelected = isCustomKey && !isCurrentViewSelected;
    const isDefaultViewSelected = !isCustomKey;

    // get index of active tab based on screenerViewState
    function getActiveIndex(): number | false {
        if (!screenerActiveView) {
            throw new Error('screenerActiveView is not set.');
        }
        // don't set active tab until the custom views is loaded
        if (!isLoadedDetails) {
            return false;
        }
        // handle current view selected
        if (isCurrentViewSelected) {
            return defaultViews.length + customViewsDetails.length;
        }
        // handle custom view selected
        if (isCustomViewSelected) {
            const customViewIndex = customViewsDetails.map((view) => view.name).indexOf(screenerActiveView.label!);

            if (customViewIndex > -1) {
                return (
                    defaultViews.length + customViewsDetails.map((view) => view.name).indexOf(screenerActiveView.label!)
                );
            }
        }
        // handle default view selected
        if (isDefaultViewSelected) {
            return defaultViews.map((view) => view.label).indexOf(screenerActiveView.label!);
        }

        return false;
    }

    function onChange(e: any, value: number) {
        const target = e.target as Element;
        if (!target.classList.contains('MuiTab-root')) {
            return; // ignore click events from the 3 dots icon and all click events inside menu options and their ancestors
        }
        // handle default view selection
        if (value <= defaultViews.length - 1) {
            screenerViewActionDispatcher({
                type: 'SetScreenerActiveView',
                newState: {
                    screenerActiveView: defaultViews[value],
                },
            });
            return;
        }

        // handle current view selection
        if (
            value === defaultViews.length + customViewsDetails.length &&
            screenerCurrentView?.fieldsData &&
            allFieldsData
        ) {
            screenerViewActionDispatcher({
                type: ['SetScreenerActiveView'],
                newState: {
                    screenerActiveView: screenerCurrentView,
                },
            });
            return;
        }

        if (!allFieldsData) {
            return;
        }

        // handle custom view selection
        const customViewsIndex = value - defaultViews.length;
        screenerViewActionDispatcher({
            type: 'SetScreenerActiveView',
            newState: {
                screenerActiveView: {
                    key: 'custom',
                    label: customViewsDetails[customViewsIndex].name,
                    fieldsData: extendUserFieldsData({
                        allFieldsData,
                        userFieldsData: customViewsDetails[customViewsIndex].value,
                    }),
                    savedItemId: customViewsDetails[customViewsIndex].id,
                },
            },
        });
    }

    // add default and custom view tabs
    const tabItems: Array<string> = defaultViews
        .map((view) => view.label)
        .concat(customViewsDetails.map((item) => item.name));

    // add current view tab
    if (screenerCurrentView) {
        tabItems.push(`${screenerCurrentViewLabel}*`);
    }

    // show current view tab label italic
    const currentViewTabStyles = screenerCurrentView
        ? ({
              '& .MuiTab-root:last-of-type': {
                  fontStyle: 'italic',
                  paddingRight: '2px',
              },
          } as SxProps)
        : undefined;

    return (
        <>
            <Tabs
                ariaLabel={'screener tabs'}
                activeTab={getActiveIndex()}
                onChange={onChange}
                tabItems={tabItems}
                tabWithRightDividerIndex={
                    // show divider only if present custom or current views
                    customViewsDetails.length > 0 || screenerCurrentView ? defaultViews.length - 1 : undefined
                }
                sx={currentViewTabStyles}
                getTabIcon={(tabName, index) => {
                    if (index < defaultViews.length) {
                        return;
                    }

                    return (
                        <SavedViewMenu
                            tabName={tabName}
                            tabIndex={index}
                            defaultViews={defaultViews}
                            customViewsDetails={customViewsDetails}
                        />
                    );
                }}
                indicatorWidthAfterDividerTab={'calc(100% - 24px)'}
            />
            <SaveViewModal existingViews={customViewsDetails.map((view) => view.name)} />
            <EditActiveViewHandler customViewsDetails={customViewsDetails} />
        </>
    );
}
