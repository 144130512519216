import { ScreenerView } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/types';
import { WatchListRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';

export function getSearch2Results(searchTerm: string, resultCount: number) {
    if (!searchTerm) return;
    if (resultCount > 0) return `valid search`;
    return 'invalid search';
}

export function getSelectedView(screenerActiveView?: Omit<ScreenerView, 'fieldsData'>) {
    if (screenerActiveView?.isCurrentView) return 'current view';
    if (screenerActiveView?.key === 'custom')
        return `custom view: ${screenerActiveView?.label?.toLocaleLowerCase()} : ${screenerActiveView?.savedItemId}`;
    if (screenerActiveView?.key) return `default view: ${screenerActiveView?.label?.toLowerCase()}`;
}

export function getSelectedItem(selectedItem?: SavedItemTypes): string | undefined {
    return selectedItem?.name && selectedItem?.id ? `${selectedItem?.name} : ${selectedItem?.id}` : undefined;
}

export function getWatchlistEtfsData(rows: WatchListRow[]) {
    return rows.map((row) => `${row.composite_ticker} : ${row.composite_name} : ${row.id}`);
}

export const enum SavedItemOperations {
    SaveView = 'save view',
    SaveScreen = 'save screen',
    SaveWatchlist = 'save watchlist',
    AddToExistingWatchlist = 'add to existing watchlist',
}
