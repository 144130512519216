import FormAutocompleteSearchTable from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompleteSearchTable';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName, getFilterLabel } from './shared';
import { FilterProps } from './types';

export const ScreenerFormAutocompleteSearchTable: React.FC<FilterProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    submitHandler,
    hide,
    SearchComponent,
    parentSectionKey,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    return (
        <>
            {SearchComponent}
            <FormAutocompleteSearchTable
                label={label}
                hide={hide}
                control={control}
                name={combineIntoFormElementName({
                    componentName: Components.AutocompleteSearchTable,
                    filterMetadataKey,
                })}
                options={filtersData.data[filterMetadataKey]?.items}
                placeholder={filterMetadata.placeholder || ''}
                defaultValues={filterMetadata.default_values}
                submitHandler={submitHandler}
            />
        </>
    );
};
