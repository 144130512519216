import Footer from '@cfra-nextgen-frontend/shared/src/components/Footer/Footer';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './Home';

export function HomeRoutes() {
    return (
        <>
            <Routes>
                <Route path='' element={<Home />} />
                <Route path='*' element={<Navigate to='.' />} />
            </Routes>
            <Footer />
        </>
    );
}
