import { CFRAChips, DefaultCFRASnack, MaterialIcons } from '@cfra-nextgen-frontend/shared';
import { CFRAMuiIconWrapper } from '@cfra-nextgen-frontend/shared/src/components/Icon/SvgIcon';
import { ScreenerChipTheme, TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { useEffect, useState } from 'react';
import { TypeSearch } from 'features/topNavigation/components/TypeSearch';
import { AutoSuggestETF } from 'features/topNavigation/types/opensearch';
import { Grid, ThemeProvider, createTheme } from '@mui/material';
import { chipItem } from '@cfra-nextgen-frontend/shared/src/components/CFRAChip/CFRAChipStack';
import { SavedItemDetailsType } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { UpdateWatchlistButton } from './watchLists/UpdateWatchlist';
import { useSnackbar } from 'notistack';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

type AddSymbolModalProps = {
    id?: number;
    title: string;
    showModal: boolean;
    closeModal: () => void;
    selectedWatchlist: SavedItemDetailsType;
};

export const AddSymbolModalButton = (props: { onClick: () => void }) => {
    const buttonText = 'Add Symbol';
    return (
        <TransparentButtonChip
            key='AddSymbolButton'
            text={buttonText}
            startIcon={<CFRAMuiIconWrapper MuiIcon={MaterialIcons.AddCircleOutline} />}
            onClick={props.onClick}
        />
    );
}

const ChipComponentList = (props: { chipItems: AutoSuggestETF[]; onChipDeleteClick: (chipItems: AutoSuggestETF[], key: string) => () => void }) => {

    const { chipItems, onChipDeleteClick } = props;

    if (chipItems?.length === 0) {
        return null;
    }

    const chipList: Array<chipItem> = chipItems?.map((chip) => {
        const { composite_ticker, firstbridge_id } = chip || {};
        let chipLabel = `${composite_ticker}`;

        return {
            chipKey: firstbridge_id,
            chipLabel,
            chipProps: {
                title: chipLabel,
                deleteIcon: <MaterialIcons.Clear style={{ fontSize: '13px', color: '#0B2958' }} />,
                onDelete: onChipDeleteClick(chipItems, firstbridge_id),
                onClick: () => { },
            }
        };
    });

    return (
        <ThemeProvider key='ChipsTheme' theme={ScreenerChipTheme}>
            <CFRAChips key='allAddSymbolChips' chipItems={chipList} />
        </ThemeProvider>
    );
};

export const AddSymbolModal = (props: AddSymbolModalProps) => {
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [allSelectedSymbols, setAllSelectedSymbols] = useState<AutoSuggestETF[]>([]);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [shouldConfirmClose, setShouldConfirmClose] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const registerAnalytics = AnalyticsFn('AddSymbolModal');
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const modalMaxWidth = '650px';

    useEffect(() => {
        if (allSelectedSymbols?.length > 0) {
            setIsFormDirty(true);
        } else {
            setIsFormDirty(false);
        }
    }, [allSelectedSymbols])

    const onSelectedValue = (value: AutoSuggestETF) => {
        if (!allSelectedSymbols.find(p => p.firstbridge_id === value.firstbridge_id)) {
            setAllSelectedSymbols([...allSelectedSymbols, value]);
        }
    };

    const onChipDeleted = (chipItems: AutoSuggestETF[], key: string) => () => {
        setAllSelectedSymbols(allSelectedSymbols.filter(p => p.firstbridge_id !== key));
    }

    const clearAllChips = () => {
        setAllSelectedSymbols([]);
    }

    const closeModalWindow = () => {
        clearAllChips();
        props.closeModal();
    }

    const modalTitle = props?.title || 'Add Symbol';

    const typeSearchTheme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        width: `calc(${modalMaxWidth} - 160px) !important`
                    }
                }
            }
        }
    });

    const updateWatchlistButtonTheme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        '&:hover': {
                            color: '#3078B5',
                            backgroundColor: '#E5E9EF',
                            border: '1px solid #007BB8',
                        },
                        color: '#FFF',
                        backgroundColor: '#007BB8',
                        '&:disabled': {
                            backgroundColor: '#9E9E9E',
                            color: '#FFF',
                        },
                    },
                },
            },
        }
    })
    return (
        <>
            <Modal
                openModal={props.showModal}
                title={modalTitle}
                //titleStyle={{ fontSize: '24px' }}
                closeButtonFontSize={20}
                callbackOnClose={() => {
                    if (isFormDirty) {
                        setShouldConfirmClose(true);
                    } else {
                        closeModalWindow();
                    }
                }}
                modalBoxStyles={{
                    padding: '32px 30px 29px',
                    maxWidth: modalMaxWidth,
                    maxHeight: '280px',
                    overflowY: 'auto',
                    ...scrollbarThemeV2
                }}>

                <Grid container direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowSpacing={2}
                    marginTop={1}>
                    <Grid item width={'100%'}>
                        <TypeSearch
                            showInput={showSearchInput}
                            setShowInput={setShowSearchInput}
                            navigateOnClick={false}
                            setSelectedValue={onSelectedValue}
                            overrideSearchBarMainContainerStyle={{
                                borderRadius: 2,
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                height: '44px',
                            }}
                            customTheme={typeSearchTheme}
                            popperPlacement='bottom-start'
                            analyticsAction='add symbol ticker search'></TypeSearch>
                    </Grid>

                    <Grid item container direction="row" minHeight={'50px'}>
                        <ChipComponentList chipItems={allSelectedSymbols} onChipDeleteClick={onChipDeleted}></ChipComponentList>
                    </Grid>
                    <Grid item alignSelf={'center'}>
                        <Grid sx={{
                            width: '200px'
                        }}>
                        <UpdateWatchlistButton buttonText='Add' appendWatchlistData={allSelectedSymbols.map((sym) => {
                            return {
                                id: sym.firstbridge_id,
                                composite_name: sym.composite_name,
                                composite_ticker: sym.composite_ticker,
                                type: 'ETF'
                            };
                        })} 
                        cardName={'AddSymbol'} 
                        disabled={allSelectedSymbols?.length === 0} 
                        hideIcon={true}
                        customTheme={updateWatchlistButtonTheme}
                        onClickCallBack={() => {
                            closeModalWindow();
                            ShowSnack(`${props.selectedWatchlist.name} was updated`);
                        }}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <ConfirmationModal
                openModal={shouldConfirmClose}
                modalText={`Exit without saving?`}
                cancelCallback={() => {
                    registerAnalytics('click on Cancel');
                    setShouldConfirmClose(false);
                }}
                cancelButtonText='Cancel'
                confirmCallback={() => {
                    registerAnalytics('click on Confirm');
                    setShouldConfirmClose(false);
                    closeModalWindow();
                }}
                confirmButtonText='Confirm'
            />
        </>
    )
}

function AnalyticsFn(cardName: string) {
    return (action: string) =>
        globalThis.analytics?.registerAction?.({
            action: action,
            cardName: cardName,
        });
}

