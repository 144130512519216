import { RoundedIconTextButton } from '@cfra-nextgen-frontend/shared';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { WatchListIcon } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListIcon';
import { WatchListButtonTheme } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { WatchListRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CreateWatchListComponent from './CreateWatchListComponent';
import UpdateWatchListComponent from './UpdateWatchListComponent';
import { ModelBoxStyle } from './WatchListUtils';

export function WatchListModalOpenButton({ outlineIcon = false }: { outlineIcon?: boolean }) {
    const { watchListDispatcher } = useContext(WatchListContext);

    return (
        <RoundedIconTextButton
            theme={WatchListButtonTheme}
            icon={<WatchListIcon outline={outlineIcon} />}
            buttonText={'Add to Watchlist'}
            onClickCallback={() => watchListDispatcher({ type: 'OpenSaveWatchlistModal' })}></RoundedIconTextButton>
    );
}

export function WatchListModal({
    cardName,
    title,
    createWatchlistPlaceholder,
    showUpdateWatchlist = true,
    onFinishCallback,
    handleDuplicateIdsOnUpdate,
    externalViewWatchlist,
    watchListData,
}: {
    cardName: string;
    title?: string;
    createWatchlistPlaceholder?: string;
    showUpdateWatchlist?: boolean;
    onFinishCallback?: (fromCloseButton?: boolean) => void;
    handleDuplicateIdsOnUpdate?: boolean;
    externalViewWatchlist?: boolean;
    watchListData?: Array<WatchListRow>;
}) {
    const {
        watchListState: { openModal },
        watchListDispatcher,
    } = useContext(WatchListContext);
    const [wasFormDirty, setWasFormDirty] = useState(false);
    const [shouldConfirmClose, setShouldConfirmClose] = useState(false);
    const registerAnalytics = AnalyticsFn(cardName);

    useEffect(() => {
        setWasFormDirty(false);
        setShouldConfirmClose(false);
    }, [openModal]);

    const modalTitle = title || 'Save Watchlist';
    return (
        <>
            <Modal
                title={modalTitle}
                openModal={openModal}
                modalBoxStyles={ModelBoxStyle}
                closeButtonFontSize={20}
                callbackOnClose={() => {
                    if (wasFormDirty) {
                        setShouldConfirmClose(true);
                    } else {
                        onFinishCallback?.(true);
                        watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
                    }
                }}>
                <Box sx={{ width: '100%' }}>
                    {openModal && (
                        <>
                            <CreateWatchListComponent
                                placeholder={createWatchlistPlaceholder}
                                onSuccessCallback={onFinishCallback}
                                onIsDirtyChange={setWasFormDirty}
                                externalViewWatchlist={externalViewWatchlist}
                                watchListData={watchListData}
                                modalTitle={modalTitle}
                            />
                            {showUpdateWatchlist && (
                                <UpdateWatchListComponent
                                    registerAnalytics={registerAnalytics}
                                    onSuccessCallback={onFinishCallback}
                                    onIsDirtyChange={setWasFormDirty}
                                    handleDuplicateIds={handleDuplicateIdsOnUpdate}
                                    externalViewWatchlist={externalViewWatchlist}
                                    watchListData={watchListData}
                                    modalTitle={modalTitle}
                                />
                            )}
                        </>
                    )}
                </Box>
            </Modal>
            <ConfirmationModal
                openModal={shouldConfirmClose}
                modalText={`Exit without saving?`}
                cancelCallback={() => {
                    registerAnalytics('click on Cancel');
                    setShouldConfirmClose(false);
                }}
                cancelButtonText='Cancel'
                confirmCallback={() => {
                    registerAnalytics('click on Confirm');
                    setShouldConfirmClose(false);
                    onFinishCallback?.();
                    watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
                }}
                confirmButtonText='Confirm'
            />
        </>
    );
}

function AnalyticsFn(cardName: string) {
    return (action: string) =>
        globalThis.analytics?.registerAction?.({
            action: action,
            cardName: cardName,
        });
}
