import {
    AllCustomViewsBaseDataResponse,
    CustomViewsExtendedDataResponse,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { ApiNames, RequestTypes, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { UseData, UseMultipleData } from 'utils';

export function getAllCustomViews({ types = [UserSavedItemsLookupID.ETFScreenerSavedView] }: SearchByParams) {
    return UseData<AllCustomViewsBaseDataResponse>({
        requestQuery: getRequestQuery({ types: types }, 'saved-items'),
        queryKey: ['getAllCustomViews', types],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.GET,
    });
}

export function getCustomViewsDetails({ savedItems }: SearchByParams) {
    if (!Array.isArray(savedItems)) {
        throw new Error(`getCustomViewsDetails received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseMultipleData<CustomViewsExtendedDataResponse>(
        savedItems.map((item) => ({
            requestQuery: getRequestQuery({ savedItems: item }),
            queryKey: ['getCustomViewsDetails', item],
            apiName: ApiNames.UserManagement,
            requestType: RequestTypes.GET,
        })),
    );
}

export function saveCustomView({ requestBody, config }: SearchByParams) {
    return UseData<CustomViewsExtendedDataResponse>({
        requestQuery: 'saved-items',
        queryKey: ['saveCustomView', requestBody, config],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.POST,
        requestBody: { ...requestBody, saved_item_lid: UserSavedItemsLookupID.ETFScreenerSavedView },
        config: config,
    });
}

export function updateCustomView({ savedItems, requestBody, config }: SearchByParams) {
    const queryKey = ['updateCustomView', savedItems, requestBody, config];
    queryClient.invalidateQueries(queryKey); // avoid cashing of query
    return UseData<CustomViewsExtendedDataResponse>({
        requestQuery: savedItems ? getRequestQuery({ savedItems: savedItems }) : '',
        queryKey,
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.PUT,
        requestBody,
        config,
    });
}

export function deleteCustomView({ savedItems, config }: SearchByParams) {
    return UseData<CustomViewsExtendedDataResponse>({
        requestQuery: savedItems ? getRequestQuery({ savedItems: savedItems }) : '',
        queryKey: ['deleteCustomView', savedItems, config],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.DELETE,
        config,
    });
}
