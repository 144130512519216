import { ETFMenu } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as ThreeDotsIconV2 } from '@cfra-nextgen-frontend/shared/src/assets/images/ThreeDotsIconV2.svg';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { Action, ActionTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/types';
import {
    CustomViewExtendedData,
    ScreenerDefaultViews,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/views';
import { extendUserFieldsData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/savedViews';
import { DeleteSavedViewModal } from 'features/etfScreener/components/DeleteSavedViewModal';
import { useContext, useState } from 'react';

type SavedViewMenuProps = {
    tabName: string;
    tabIndex: number;
    defaultViews: Array<{
        label: string;
        key: string;
    }>;
    customViewsDetails: Array<CustomViewExtendedData>;
};

export function SavedViewMenu({ tabName, tabIndex, defaultViews, customViewsDetails }: SavedViewMenuProps) {
    const [deleteView, setDeleteView] = useState<{ id?: number; name: string; isSelected: boolean } | undefined>(
        undefined,
    );
    const {
        screenerViewState: { screenerCurrentView, screenerActiveView, allFieldsData },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);
    const { customViewEditorStateDispatcher } = useContext(CustomViewEditorContext);
    const isDefaultView = tabIndex < defaultViews.length;
    if (isDefaultView) {
        // CRUD operation menu is not available for default views
        return null;
    }
    const isCustomView = !isDefaultView && tabIndex < defaultViews.length + customViewsDetails.length;
    const customViewIndex = tabIndex - defaultViews.length;

    function handleEdit() {
        if (!allFieldsData) {
            throw new Error('handleEdit got undefined allFieldsData.');
        }

        if (isCustomView) {
            const customViewDetails = customViewsDetails[customViewIndex];

            customViewEditorStateDispatcher({
                type: ['SetScreenerUpdateView'],
                newState: {
                    screenerUpdateView: {
                        label: customViewDetails.name,
                        savedItemId: customViewDetails.id,
                        fieldsData: extendUserFieldsData({
                            allFieldsData,
                            userFieldsData: customViewDetails.value,
                        }),
                    },
                },
            });
        } else {
            if (!screenerCurrentView?.fieldsData) {
                throw new Error('Custom or current view contains empty screenerCurrentView?.fieldsData.');
            }
            customViewEditorStateDispatcher({
                type: ['SetScreenerUpdateView'],
                newState: {
                    screenerUpdateView: screenerCurrentView,
                },
            });
        }
    }

    function handleRename() {
        screenerViewActionDispatcher({
            type: 'SetScreenerSaveView',
            newState: {
                screenerSaveView: {
                    label: tabName,
                    savedItemId: customViewsDetails[customViewIndex].id,
                },
            },
        });
        customViewEditorStateDispatcher({
            type: 'OpenNameViewModalInRenameMode',
        });
    }

    function handleSaveCurrentView() {
        if (!screenerCurrentView) {
            return;
        }

        screenerViewActionDispatcher({
            type: 'SetScreenerSaveView',
            newState: {
                screenerSaveView: {
                    key: screenerCurrentView.key,
                    fieldsData: screenerCurrentView.fieldsData,
                    isCurrentView: screenerCurrentView.isCurrentView,
                    // don't include label key to avoid "Current View" label present in save view modal's input field
                },
            },
        });
        customViewEditorStateDispatcher({
            type: 'OpenNameViewModalInSaveMode',
        });
    }

    function handleDelete() {
        if (!screenerActiveView) {
            throw new Error('screenerActiveView is not set.');
        }
        if (isCustomView) {
            // handle custom view delete
            const viewDetails = customViewsDetails[customViewIndex];
            setDeleteView({
                id: viewDetails.id,
                name: `${viewDetails.name} view`,
                isSelected: screenerActiveView.label === viewDetails.name,
            });
        } else {
            // handle current view delete
            setDeleteView({
                name: 'Current View',
                isSelected: Boolean(screenerActiveView.isCurrentView),
            });
        }
    }

    // option for custom and current view
    const menuItems = [
        {
            itemName: 'Edit',
            callback: handleEdit,
        },
    ];

    if (isCustomView) {
        // option for custom views only
        menuItems.push({
            itemName: 'Rename',
            callback: handleRename,
        });
    } else {
        // option for current view only
        menuItems.push({
            itemName: 'Save',
            callback: handleSaveCurrentView,
        });
    }

    // option for custom and current view
    menuItems.push({
        itemName: 'Delete',
        callback: handleDelete,
    });

    function addSelectOverview(inputAction: Array<ActionTypes>): Action {
        return {
            type: [...inputAction, 'SetScreenerActiveView'],
            newState: {
                screenerActiveView: ScreenerDefaultViews.Overview,
            },
        };
    }

    return (
        <>
            <ETFMenu.ETFMenu
                menuItemStyles={{
                    paddingTop: '9.5px',
                    paddingBottom: '7.5px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    borderLeft: '0px',
                    borderBottom: '2px solid transparent',
                    lineHeight: '20px',
                }}
                menuItems={menuItems}
                paperProps={{
                    width: '164px',
                }}
                onHoverItemStyles={{
                    borderLeft: '0px',
                    borderBottom: '2px solid #3078B5',
                }}
                addBorderBetweenItems={false}>
                <ThreeDotsIconV2 className='MuiTab-iconWrapper' />
            </ETFMenu.ETFMenu>
            {deleteView && (
                <DeleteSavedViewModal
                    id={deleteView.id}
                    name={deleteView.name}
                    onCancel={() => setDeleteView(undefined)}
                    onSuccessDelete={() => {
                        const actionTypes: Array<ActionTypes> = ['SetRefetchAllPending'];
                        const action: Action = deleteView.isSelected
                            ? addSelectOverview(actionTypes)
                            : {
                                  type: actionTypes,
                              };
                        screenerViewActionDispatcher(action);
                        setDeleteView(undefined);
                    }}
                    onConfirmClick={
                        deleteView.id === undefined // if id undefined - remove current view
                            ? () => {
                                  const actionTypes: Array<ActionTypes> = ['RemoveScreenerCurrentView'];
                                  const action: Action = deleteView.isSelected
                                      ? addSelectOverview(actionTypes)
                                      : {
                                            type: actionTypes,
                                        };

                                  screenerViewActionDispatcher(action);
                                  setDeleteView(undefined);
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}
