import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { getSearch2Results, getSelectedItem, getSelectedView } from './utils';

type ScreenerAnalyticsServiceProps = {
    registerAction: RegisterActionFunc;
};

export const ScreenerAnalyticsServiceContext = createContext<ScreenerAnalyticsServiceProps>(
    {} as ScreenerAnalyticsServiceProps,
);

export function ScreenerAnalyticsServiceContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const {
        watchListState: { selectedWatchlist },
    } = useContext(WatchListContext);
    const {
        screenerViewState: { screenerActiveView },
    } = useContext(ScreenerViewContext);
    const {
        saveScreenState: { selectedScreen },
    } = useContext(SaveScreenContext);
    const { searchTerm } = useContext(FiltersModalContext);
    const {
        chipStateManager: {
            chipState: { resultCount },
        },
    } = useContext(ResultsContext);

    const search2Results = useMemo(() => {
        return getSearch2Results(searchTerm, resultCount);
    }, [resultCount, searchTerm]);

    const selectedView = useMemo(() => {
        return getSelectedView(screenerActiveView);
    }, [screenerActiveView]);

    const registerAction: RegisterActionFunc = useCallback(
        (actionProps) =>
            globalThis.analytics?.registerAction?.({
                selectedWatchlist: getSelectedItem(selectedWatchlist),
                selectedView: selectedView,
                selectedScreen: getSelectedItem(selectedScreen),
                search2Term: searchTerm || undefined,
                search2Results: search2Results,
                resultsCount: resultCount,
                ...actionProps,
            }),
        [search2Results, searchTerm, selectedScreen, selectedView, selectedWatchlist, resultCount],
    );

    return (
        <ScreenerAnalyticsServiceContext.Provider value={{ registerAction }}>
            {memorizedChildren}
        </ScreenerAnalyticsServiceContext.Provider>
    );
}
