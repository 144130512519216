import { ReactComponent as CloseIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/CloseIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, InputAdornmentProps, SvgIcon, TextField } from '@mui/material';
import ScreenerSearchContainer, { fontStyling } from './ScreenerSearchContainer';

const getAdornment = (icon: JSX.Element, adornmentProps: InputAdornmentProps) => {
    let key = adornmentProps.position === 'start' ? 'startAdornment' : 'endAdornment';
    return {
        [key]: (
            <InputAdornment {...adornmentProps}>
                <SvgIcon viewBox='0 0 40 40'>{icon}</SvgIcon>
            </InputAdornment>
        ),
    };
};

export function ScreenerFilterSearch({
    searchTerm,
    setSearchTerm,
}: {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}) {
    const getEndAdornment = () =>
        searchTerm.length > 0
            ? getAdornment(<CloseIcon fill='#3078B5' />, {
                  position: 'end',
                  sx: { cursor: 'pointer' },
                  onClick: () => setSearchTerm(''),
              })
            : {};

    const searchInputBorderBottomStyle = '1px solid ' + (searchTerm.length > 0 ? '#0B2958' : '#EDF0F6');
    const searchIconFillColor = searchTerm.length > 0 ? '#0B2958' : '#57626A';

    return (
        <ScreenerSearchContainer itemStyle={{ paddingTop: '25px', paddingBottom: '10px' }}>
            <TextField
                placeholder='Search filters'
                variant='standard'
                value={searchTerm}
                sx={{
                    input: { '&::placeholder': { color: '#57626A' } },
                    width: '100%',
                    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: searchInputBorderBottomStyle,
                    },
                    '& .MuiInputBase-root:before': { borderBottom: searchInputBorderBottomStyle },
                    '& .MuiInputBase-root:after': { borderBottom: searchInputBorderBottomStyle },
                }}
                onChange={(e) => setSearchTerm(e.target.value.trimStart())}
                InputProps={{
                    ...getAdornment(<SearchIcon style={{ color: searchIconFillColor }} />, {
                        position: 'start',
                    }),
                    ...getEndAdornment(),
                    style: { ...fontStyling, color: searchIconFillColor },
                }}
            />
        </ScreenerSearchContainer>
    );
}
