import { getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils/objects';
import _ from 'lodash';

// eslint-disable-next-line no-template-curly-in-string
export const defaultHeaderTemplate = '{label} ({symbol}{scale})';

// eslint-disable-next-line no-template-curly-in-string
export const defaultValueTemplate = '{value}%';

const getValueToReplace = (value: string) => `{${value}}`;

export function fillTemplate({
    templateName,
    template,
    dataObject,
    formattedValue,
    keepValuesType,
}: {
    templateName: string;
    template: string;
    dataObject: Object;
    formattedValue?: string;
    keepValuesType?: boolean;
}): string | undefined {
    const templatesRegex = /{(.+?)}/g;
    let isFilledTemplate = true;
    let matches: string[] = [];
    let match;
    while ((match = templatesRegex.exec(template)) !== null) {
        matches.push(match[1]);
    }
    if (matches) {
        matches.forEach((key) => {
            const throwError = (additionalErrorText?: string) => {
                throw new Error(
                    `The ${templateName} template contains value out of dataObject - "${key}". ${
                        additionalErrorText || ''
                    }`,
                );
            };

            const valueToReplace = getValueToReplace(key);

            if (key === 'value') {
                if (formattedValue === undefined) {
                    throwError();
                }

                template = template.replaceAll(valueToReplace, formattedValue!);
            } else {
                const values = getValuesByPath(dataObject, key);

                if (values.length === 0) {
                    isFilledTemplate = false; // avoid returning template with empty values
                    return;
                }

                if (keepValuesType && template === valueToReplace) {
                    template = values[0];
                    return;
                }

                template = template.replaceAll(valueToReplace, String(values[0] || ''));
            }
        });
    }

    return isFilledTemplate ? template : undefined;
}

export function replaceAllPlaceholdersInObject(obj: any, data: any, keepValuesType?: boolean): any {
    obj = _.cloneDeep(obj);

    const _replaceAllPlaceholdersInObject = (obj: any, data: any) => {
        if (typeof obj === 'object') {
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    _replaceAllPlaceholdersInObject(obj[key], data);
                } else if (typeof obj[key] === 'string') {
                    obj[key] = fillTemplate({
                        templateName: 'replacePlaceholders',
                        template: obj[key],
                        dataObject: data,
                        keepValuesType,
                    });
                }
            }
        }
    };

    _replaceAllPlaceholdersInObject(obj, data);

    return obj;
}
