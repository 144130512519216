import { StyledFormLabel } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { WatchListIcon } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListIcon';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { UserSavedItemsLookupID, fontFamilies, sortByDateString } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSavedItemsByType } from 'features/etfScreener/api/userSavedItems';
import { useContext, useState } from 'react';
import { MenuMessageStyles, ScreenerMenu, ScreenerMenuItem } from '../BaseScreenerMenu';
import { DeleteSavedItemModal } from '../DeleteSavedItemModal';
import { RenameSavedItemModal } from '../RenameSavedItemModal';

export function WatchListsMenu({ hideButtonText }: { hideButtonText?: boolean }) {
    const {
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);
    const {
        watchListState: { selectedWatchlist },
        watchListDispatcher,
    } = useContext(WatchListContext);
    const { setSearchTerm } = useContext(FiltersModalContext);
    const [deleteScreen, setDeleteScreen] = useState<SavedItemTypes>();
    const [renameScreen, setRenameScreen] = useState<SavedItemTypes>();
    const getAllWatchlistQry = getSavedItemsByType({ types: [UserSavedItemsLookupID.Watchlist] });
    const watchlistItems: SavedItemTypes[] = getAllWatchlistQry.data?.data || [];
    const sortedWatchlists = watchlistItems.sort(sortByDateString('updated_date', 'desc'));
    const selectedItemIndex = sortedWatchlists.findIndex((e) => e.id === selectedWatchlist?.id);
    const disableMyWatchlistBtn = !getAllWatchlistQry?.data?.data?.length && getAllWatchlistQry.isLoading;

    const menuItems = [
        ...sortedWatchlists.map((screen) => ({
            itemName: (
                <ScreenerMenuItem
                    label={screen.name}
                    id={screen.id}
                    onItemClick={handleOnItemClick}
                    onEditClick={handleOnEditClick}
                    onDeleteClick={handleDeleteClick}
                />
            ),
            callback: () => {},
        })),
        ...(sortedWatchlists.length === 0
            ? [
                  {
                      itemName: (
                          <StyledFormLabel sx={{ ...MenuMessageStyles, color: '#3078B5', lineHeight: '21px' }}>
                              You have no Watchlist
                          </StyledFormLabel>
                      ),
                      callback: () => {},
                  },
              ]
            : []),
    ];

    function handleOnItemClick(id: number) {
        onChipClearAllClick();
        saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });
        setSearchTerm('');
        const selectedItem = sortedWatchlists.find((e) => e.id === id);
        watchListDispatcher({ type: 'SetSelectedWatchlist', payload: selectedItem });
    }

    function handleOnEditClick(id: number) {
        setRenameScreen(sortedWatchlists.find((e) => e.id === id));
    }

    function handleDeleteClick(id: number) {
        const deleteItem = sortedWatchlists.find((e) => e.id === id);
        setDeleteScreen(deleteItem);
    }

    function handleDeleteCancelClick() {
        setDeleteScreen(undefined);
    }

    function handleDeleteConfirmClick() {
        refetchAllSavedScreens();
    }

    function resetSelectedWatchlist() {
        onChipClearAllClick();
        watchListDispatcher({ type: 'ClearSelectedWatchlist' });
    }

    async function refetchAllSavedScreens() {
        const { data, isFetching, isError } = await getAllWatchlistQry.refetch();
        if (!isFetching && !isError) {
            if (selectedWatchlist?.id && selectedWatchlist?.id === deleteScreen?.id) {
                resetSelectedWatchlist();
            }
            if (selectedWatchlist?.id && selectedWatchlist?.id === renameScreen?.id) {
                const savedItems: SavedItemTypes[] = data?.data || [];
                const selectedItem = savedItems.find((e) => e.id === renameScreen?.id);
                watchListDispatcher({ type: 'RenameSelectedWatchlist', payload: selectedItem?.name });
            }
            setDeleteScreen(undefined);
            setRenameScreen(undefined);
        }
    }

    return (
        <>
            <ScreenerMenu menuItems={menuItems} selectedItemIndex={selectedItemIndex}>
                <TransparentButtonChip
                    disabled={disableMyWatchlistBtn}
                    key='My Watchlists'
                    text={hideButtonText ? '' : 'My Watchlists'}
                    disableiconrightmargin={hideButtonText ? 'true' : undefined} // avoid Warning: Received `false` for a non-boolean attribute `disableiconrightmargin`.
                    startIcon={<WatchListIcon />}
                    sx={{ fontFamily: fontFamilies.GraphikMedium, minWidth: hideButtonText ? 'unset' : '132px' }}
                />
            </ScreenerMenu>
            {typeof deleteScreen?.id === 'number' && (
                <DeleteSavedItemModal
                    id={deleteScreen.id}
                    name={deleteScreen.name}
                    onCancel={handleDeleteCancelClick}
                    onConfirm={handleDeleteConfirmClick}
                />
            )}
            {typeof renameScreen?.id === 'number' && (
                <RenameSavedItemModal
                    id={renameScreen.id}
                    name={renameScreen.name}
                    savedItemType={UserSavedItemsLookupID.Watchlist}
                    modalTitle='Rename Watchlist'
                    onCancel={() => setRenameScreen(undefined)}
                    onConfirm={() => refetchAllSavedScreens()}
                />
            )}
        </>
    );
}
