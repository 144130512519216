import { ETFDetailsButton } from '@cfra-nextgen-frontend/shared';
import Footer from '@cfra-nextgen-frontend/shared/src/components/Footer/Footer';
import { PageWithBreadcrumbsInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader';
import { PageWithBreadcrumbsInHeaderContextProvider } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeaderContext';
import { Link } from '@mui/material';
import { Page } from 'analytics/Page';
import { Route, Link as RouterLink, Routes } from 'react-router-dom';
import { MarketTrendsDetails } from '../components/MarketTrendsDetails';
import { MarketTrendsHome } from '../components/MarketTrendsHome';
import { MarketTrend } from '../types/market';

export const pathMapping: Record<string, string> = {
    [MarketTrend.FlowsToAssetsDetails]: 'flows-to-assets-details',
    [MarketTrend.PerformanceDetails]: 'performance-details',
    [MarketTrend.KeyAssetClassesDetails]: 'key-asset-classes-performance-details',
    [MarketTrend.SectorPerformanceDetails]: 'sector-performance-details',
    Home: '',
    'Market Trends': 'market-trends',
};

type LinkProps = {
    pathName: MarketTrend;
    analyticsCallback?: () => void;
};

export function MarketTrendsDetailsLink({ pathName, analyticsCallback }: LinkProps) {
    return (
        <Link component={RouterLink} to={`/market-trends/${pathMapping[pathName]}`} onClick={analyticsCallback}>
            <ETFDetailsButton sx={{ paddingRight: '16px' }} />
        </Link>
    );
}

export function MarketTrendsRoutes() {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <PageWithBreadcrumbsInHeader pathMapping={pathMapping} />
                        <Footer />
                    </>
                }>
                <Route path='/' element={<MarketTrendsHome />} />
            </Route>

            <Route
                element={
                    <PageWithBreadcrumbsInHeaderContextProvider>
                        <PageWithBreadcrumbsInHeader
                            pathMapping={pathMapping}
                            contentOuterContainerStyles={{ minHeight: 'unset' }}
                            contentInnerContainerPaddingBottom={0}
                        />
                    </PageWithBreadcrumbsInHeaderContextProvider>
                }>
                {Object.entries(MarketTrend).map(([, marketTrend], i) => (
                    <Route
                        path={pathMapping[marketTrend]}
                        key={i}
                        element={
                            <Page name={marketTrend} group='market trends details'>
                                <MarketTrendsDetails label={marketTrend} />
                            </Page>
                        }
                    />
                ))}
            </Route>
        </Routes>
    );
}
