import { waitUntil } from 'utils';

export class Analytics implements IAnalytics {
    constructor() {
        globalThis.cfraData = {
            pageData: {},
            actionData: {},
            siteData: {},
            userData: {},
        };

        globalThis.cfraData.siteData.siteDomain = window.location.hostname.toLowerCase();
    }

    public registerAction(actionProps: AnalyticsActionProps) {
        this.satelliteTrack('registerAction', { ...globalThis.cfraData, actionData: actionProps });
    }

    private satelliteTrack(elementName: string, detail: typeof cfraData) {
        function assignBuildDate() {
            if (globalThis._satellite.buildInfo && !detail.siteData.buildDate) {
                detail.siteData.buildDate = globalThis._satellite.buildInfo.buildDate;
            }
        }

        waitUntil(
            () =>
                Boolean(
                    globalThis._satellite && globalThis._satellite.environment && globalThis._satellite.environment.id,
                ),
            [assignBuildDate, () => globalThis._satellite?.track(elementName, detail)],
        );
    }

    public registerPageView(pageProps: AnalyticsPageProps, clearPreviousSection: boolean = true) {
        if (!clearPreviousSection) {
            globalThis.cfraData.pageData = { ...pageProps, section: globalThis.cfraData.pageData.section };
        } else {
            globalThis.cfraData.pageData = pageProps;
        }

        if (!globalThis.cfraData.pageData.group) {
            globalThis.cfraData.pageData.group = globalThis.cfraData.pageData.name;
        }
        globalThis.cfraData.pageData.pageReferrer =
            decodeURIComponent(document.referrer).toLowerCase().trim() || 'no referrer';
        globalThis.cfraData.pageData.url = decodeURIComponent(window.location.href).toLowerCase().trim();

        this.satelliteTrack('registerPageView', globalThis.cfraData);
    }

    public fillUserDetails(userDetails: UserDetailsResponse) {
        // default values
        globalThis.cfraData.userData.company = 'unauthorized';
        globalThis.cfraData.userData.group = 'unauthorized';
        globalThis.cfraData.userData.authType = 'unauthorized';

        if (!userDetails) {
            return;
        }

        // in case if something is wrong with username from token, it can be useful if we have ERIGHTS_USERID but don't have LOGIN_ID
        if (globalThis.cfraData.userData.name === 'unauthorized' || globalThis.cfraData.userData.name === undefined) {
            if (userDetails.LOGIN_ID != null) {
                globalThis.cfraData.userData.name = userDetails.LOGIN_ID;
            } else if (userDetails.ERIGHTS_USERID != null) {
                globalThis.cfraData.userData.name = userDetails.ERIGHTS_USERID;
            }
        }

        if (userDetails.COMPANY != null) {
            globalThis.cfraData.userData.company = userDetails.COMPANY;
        }

        if (userDetails.GROUP != null) {
            globalThis.cfraData.userData.group = userDetails.GROUP;
        }

        if (userDetails.IS_SAML_AUTH) {
            globalThis.cfraData.userData.authType = 'SAML';
        } else if (userDetails.IS_IP_AUTH) {
            globalThis.cfraData.userData.authType = 'IPAuth';
        } else if (userDetails.IS_BARCODE_AUTH) {
            globalThis.cfraData.userData.authType = 'BCAuth';
        } else if (userDetails.IS_SEAMLESS_LOGIN) {
            globalThis.cfraData.userData.authType = 'Seamless';
        } else {
            globalThis.cfraData.userData.authType = 'Regular';
        }
    }
}
