import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { SxProps, ThemeProvider } from '@mui/material';
import MUIIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

type IconButtonProps = {
    tooltipText?: string;
    onClick?: () => void;
    icon: string | React.ReactNode;
    sx?: SxProps;
    className?: string;
};

export function IconButton(props: IconButtonProps) {
    const { icon, tooltipText, onClick, ...rest } = props;

    const IconButtonComponent = (
        <MUIIconButton onClick={onClick} {...rest}>
            {icon}
        </MUIIconButton>
    );

    if (tooltipText) {
        return (
            <ThemeProvider theme={tooltipTheme}>
                <Tooltip title={tooltipText}>
                    <MUIIconButton onClick={onClick} {...rest}>
                        {icon}
                    </MUIIconButton>
                </Tooltip>
            </ThemeProvider>
        );
    }

    return IconButtonComponent;
}
