import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';
import { Grid, useMediaQuery } from '@mui/material';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { CustomCheckbox } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { defaultStarsRatingLength } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { useState } from 'react';

type FormStarsRatingProps = {
    defaultValue?: boolean;
} & CommonFormComponentProps;

export default function FormStarsRating(props: FormStarsRatingProps) {
    const maxStarsToRender = Array.from(Array(defaultStarsRatingLength)).map((val, index) => index).reverse();
    const isBelowMd = useMediaQuery(breakpointsTheme.breakpoints.down('md'));
    
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => {
                let defaultValues: any = {};
                props?.getValues?.(props.name)?.map((val: any) => {
                    defaultValues[val.key] = val.value
                });
                return (
                    <Grid container item md={12} display={'flex'} direction={'row'} flexWrap={'wrap'}>
                        {maxStarsToRender.map((val, index) => (
                            <Grid key={val + 1} item display={'flex'} alignItems={'center'} minHeight={35} xs={isBelowMd && 12} sm={isBelowMd && 5}>
                                <CustomCheckbox
                                    name={(val + 1).toString()}
                                    sx={{ padding: '0px', width: '24px', height: '24px' }}
                                    onChange={(event, data) => {
                                        const latestSelectedRatings: any = {...defaultValues,
                                            [event.target.name]: data ? event.target.name : undefined
                                        };
                                         
                                        props?.setValue?.(props.name, (Object.keys(latestSelectedRatings).map((key) =>
                                            latestSelectedRatings[key] ? {
                                                    key,
                                                    value: key // Setting value as key, so chips shows '5', '4', etc. not 'true' or 'false'
                                                } : undefined
                                        ).filter(p => p)), {
                                            shouldDirty: true
                                        });
                                        props.submitHandler?.();
                                    }}
                                    checked={((defaultValues)?.hasOwnProperty(val + 1) && defaultValues[(val + 1).toString()]) ? true : false}
                                />
                                <Rating value={val + 1} max={val + 1} sx={{ marginRight: 3 }} />
                            </Grid>
                        ))}
                    </Grid>
                );
            }}
        />
    );
}
