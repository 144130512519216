import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { TransparentButtonChip } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { Box } from '@mui/material';
import SaveIcon from 'assets/images/SaveIcon.svg';
import { useContext } from 'react';
import { FiltersForm } from './FiltersForm';

export function SaveScreenButton(props: { cardName: string }) {
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);
    const buttonText = 'Save Screen';
    return (
        <TransparentButtonChip
            key='SaveScreenButton'
            text={buttonText}
            startIcon={<Box component='img' src={SaveIcon} alt='Save Icon' />}
            sx={{ margin: '4px 8px' }}
            onClick={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${buttonText}`,
                    cardName: props.cardName,
                });
                saveScreenActionDispatcher({ type: 'OpenSaveScreenModal' });
            }}
        />
    );
}

export function FiltersModal(props: { etfScreenerCardName: string }) {
    const { openFiltersModal, setOpenFiltersModal } = useContext(FiltersModalContext);

    return (
        <Modal // ETF Filters modal
            title={'ETF Filters'}
            openModal={openFiltersModal}
            callbackOnClose={() => setOpenFiltersModal(false)}
            titleAndDescriptionContainerStyle={{
                padding: '32px 30px 0px',
            }}
            modalBoxStyles={{
                height: '78.7%',
                width: '95%',
            }}>
            <FiltersForm analyticsCardName={props.etfScreenerCardName} isFormOpen={openFiltersModal} />
        </Modal>
    );
}
