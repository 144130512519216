import { ETFMenu, ETFNavMenuIconButton, Layout } from '@cfra-nextgen-frontend/shared';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ENABLE_CFRA_INSIGHTS, ENABLE_ETF_SCREENER } from '@cfra-nextgen-frontend/shared/src/config';
import {
    HorizontalPagePaddingString,
    MaxPageWidthString,
    topNavigationHeight,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Avatar, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import { Breakpoint, ThemeProvider, createTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CFRALogo from 'assets/images/CFRABlueLogo.svg';
import FUNDynamixLogo from 'assets/images/FUNDynamixLogo.svg';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutePaths, PageNames } from 'utils/enums';
import { logoutFn, useAuth } from '../../../lib/authentication';
import { TypeSearch } from './TypeSearch';

const topNavItems: Record<string, { name: string; href: string }> = {
    [PageNames.Home]: {
        name: PageNames.Home,
        href: '/',
    },
    [PageNames.MarketTrends]: {
        name: PageNames.MarketTrends,
        href: AppRoutePaths.MarketTrends,
    },
};

if (ENABLE_ETF_SCREENER) {
    topNavItems[PageNames.Screener] = {
        name: PageNames.Screener,
        href: AppRoutePaths.Screener,
    };
}

if (ENABLE_CFRA_INSIGHTS) {
    topNavItems[PageNames.CfraInsights] = {
        name: PageNames.CfraInsights,
        href: AppRoutePaths.CfraInsights,
    };
}

export function TopNavigation() {
    const [currentActiveTab, setCurrentActiveTab] = useState<number | boolean>(false);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const { pathname } = useLocation();
    useEffect(() => {
        const activeTabIndex = Object.values(topNavItems)
            .map((value) => value.href)
            .indexOf(`/${pathname.slice(1).split('/')[0]}`);

        setCurrentActiveTab(activeTabIndex > -1 ? activeTabIndex : false);
    }, [pathname, setCurrentActiveTab]);

    const navigate = useNavigate();

    const { user } = useAuth();

    let theme = createTheme(breakpointsTheme);
    const isScreenDisplay = useMediaQuery('screen');

    theme = createTheme(theme, {
        components: {
            MuiAppBar: {
                defaultProps: {
                    position: isScreenDisplay ? 'sticky' : 'static',
                },
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#FFF',
                    },
                    root: {
                        borderBottom: 'solid #E4E5E9 1px',
                        borderTop: 'solid #3078B5 4px',
                        boxShadow: 'none',
                        zIndex: 1500,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#3078B5',
                        fontSize: 18,
                        fontFamily: fontFamilies.GraphikLight,
                        height: 48,
                        textTransform: 'uppercase',
                        width: 48,
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    disableGutters: true,
                    maxWidth: MaxPageWidthString as Breakpoint,
                },
                styleOverrides: {
                    root: {
                        maxWidth: MaxPageWidthString,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        marginLeft: '18px',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: '#3078B5',
                        },
                        color: '#0B2958',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '15px',
                        lineHeight: 1,
                        marginRight: '28px',
                        minWidth: '0px',
                        padding: 0,
                        paddingBottom: '25px',
                        paddingTop: '25px',
                        textTransform: 'none',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        background: '#4375BB',
                        borderRadius: '5px',
                        height: '4px',
                    },
                    root: {
                        [theme.breakpoints.down('lg')]: {
                            visibility: 'collapse',
                            width: 0,
                        },
                        paddingTop: '3px',
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
                styleOverrides: {
                    root: {
                        paddingLeft: HorizontalPagePaddingString,
                        paddingRight: HorizontalPagePaddingString,
                        display: 'flex',
                        height: topNavigationHeight,
                        justifyContent: 'space-between',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    mr: 1,
                    noWrap: true,
                    variant: 'h6',
                },
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        fontSize: '28px',
                        fontFamily: fontFamilies.GraphikLight,
                        lineHeight: 1.2,
                        textDecoration: 'none',
                    },
                },
            },
        },
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentActiveTab(newValue);
    };

    const isBelowSM = useMediaQuery(theme.breakpoints.down('sm'));

    const cfraLogoStyles = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: showSearchInput ? 'none' : 'flex',
            position: 'absolute',
            left: '76px',
        },
    };

    const isBelow420px = useMediaQuery('(max-width:420px)');

    const LogoImage = styled('img')(() => ({
        height: 48,
        paddingTop: 'unset',
        paddingBottom: 'unset',
        '@media (max-width: 420px)': {
            height: 24,
            paddingTop: '22px',
            paddingBottom: '22px',
        },
    }));

    return (
        <ThemeProvider theme={theme}>
            <AppBar>
                <Container>
                    <Toolbar>
                        {/* https://stackoverflow.com/questions/69455056/override-box-component-in-createtheme/69455458#69455458 */}
                        <ETFMenu.ETFMenu
                            menuItems={Object.keys(topNavItems).map((key) => {
                                return {
                                    itemName: topNavItems[key].name,
                                    callback: () => {
                                        globalThis.analytics?.registerAction?.({
                                            action: `top navigation button : ${topNavItems[
                                                key
                                            ].name.toLocaleLowerCase()}`,
                                        });
                                        navigate(topNavItems[key].href);
                                    },
                                };
                            })}>
                            <ETFNavMenuIconButton
                                sx={{
                                    marginLeft: 0,
                                    paddingRight: '18px',
                                    [theme.breakpoints.up('lg')]: {
                                        display: 'none',
                                    },
                                }}
                            />
                        </ETFMenu.ETFMenu>
                        <Box
                            sx={cfraLogoStyles}
                            onClick={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: 'logo',
                                });
                                navigate(topNavItems.Home.href);
                            }}>
                            <LogoImage src={isBelow420px ? CFRALogo : FUNDynamixLogo} alt='CFRA FundDynamix Logo' />
                        </Box>
                        {isBelowSM ? (
                            !showSearchInput && <div style={{ flexGrow: 1 }} />
                        ) : (
                            <div style={{ flexGrow: 1 }} />
                        )}
                        <Tabs value={currentActiveTab} onChange={handleChange} aria-label='top navigation tabs'>
                            {Object.keys(topNavItems).map((key, index) => {
                                const topNavItem = topNavItems[key];
                                return (
                                    <Tab
                                        label={topNavItem.name}
                                        key={index}
                                        {...Layout.a11yProps(index)}
                                        onClick={() => {
                                            globalThis.analytics?.registerAction?.({
                                                action: `top navigation tabs : ${topNavItem.name.toLocaleLowerCase()}`,
                                            });
                                            navigate(topNavItem.href);
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                        {isBelowSM ? (
                            !showSearchInput && <div style={{ flexGrow: 1 }} />
                        ) : (
                            <div style={{ flexGrow: 1 }} />
                        )}
                        <TypeSearch
                            showInput={showSearchInput}
                            setShowInput={setShowSearchInput}
                            analyticsAction='search'></TypeSearch>
                        <ETFMenu.ETFMenu
                            menuItems={[
                                {
                                    itemName: user?.username || '',
                                },
                                {
                                    itemName: 'Logout',
                                    callback: () => {
                                        globalThis.analytics?.registerAction?.({
                                            action: 'avatar : logout',
                                        });
                                        logoutFn();
                                    },
                                },
                            ]}>
                            <Tooltip title={user?.username || ''}>
                                <IconButton>
                                    <Avatar>{user?.username[0]}</Avatar>
                                </IconButton>
                            </Tooltip>
                        </ETFMenu.ETFMenu>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}
export default TopNavigation;
