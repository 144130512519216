import { ETFMenu, IconButton } from '@cfra-nextgen-frontend/shared';
import PencilIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PencilIcon.svg';
import RedBinIcon from '@cfra-nextgen-frontend/shared/src/assets/images/RedBinIcon.svg';
import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';

export type ScreenerMenuItemProps = {
    label: string;
    id: number;
    onDeleteClick: (id: number) => void;
    onItemClick: (id: number) => void;
    onEditClick: (id: number) => void;
};

export function ScreenerMenu(props: { menuItems: Array<any>; selectedItemIndex?: any; children: React.ReactNode }) {
    const menuItemStyles = {
        padding: '0px',
        ':not(:last-child)': {
            borderBottom: 0,
        },
    };

    const paperProps = {
        width: '265px',
        borderTop: '11px solid white',
        borderBottom: '11px solid white',
        maxHeight: 'min(380px, 80vh)',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-button': {
            height: '0px',
        },
        '&::-webkit-scrollbar-thumb': {
            minHeight: '51%',
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            backgroundColor: '#BDBDBD',
            borderRadius: '25px',
        },
    };

    return (
        <ETFMenu.ETFMenu
            menuItemStyles={menuItemStyles}
            menuItems={props.menuItems}
            paperProps={paperProps}
            selectedItemIndex={props.selectedItemIndex}
            onHoverItemStyles={{
                borderLeft: 'solid #fff 4px',
            }}>
            {props.children}
        </ETFMenu.ETFMenu>
    );
}

export function ScreenerMenuItem(props: ScreenerMenuItemProps) {
    const { label, id, onDeleteClick, onItemClick, onEditClick } = props;

    const MenuItemContainer = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'default',
    }));

    const ItemLabelButton = styled(LinkButton)(({ theme }) => ({
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '21px',
        fontSize: '13px',
        fontFamily: fontFamilies.GraphikRegular,
        color: '#0B2958',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0.46px',
        display: 'inline-block',
        textAlign: 'left',
        width: '182px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '182px',
        '&:hover': {
            color: '#007BB8',
            backgroundColor: '#FFF',
        },
    }));

    return (
        <MenuItemContainer>
            <ItemLabelButton onClick={() => onItemClick(id)}>{label}</ItemLabelButton>
            <IconButton
                sx={{ gap: '24px' }}
                icon={<img src={PencilIcon} alt='edit' style={{ height: '14px' }} />}
                tooltipText='Edit Name'
                onClick={() => onEditClick(id)}
            />
            <IconButton
                icon={<img src={RedBinIcon} alt='edit' style={{ height: '14px' }} />}
                tooltipText='Delete'
                onClick={() => onDeleteClick(id)}
            />
        </MenuItemContainer>
    );
}

export const MenuMessageStyles = {
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.46px',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '7px',
    width: '100%',
    gap: '8px',
};
