import { ReactNode, createContext, useMemo, useReducer } from 'react';
import { defaultState, watchlistReducer } from './Reducer';
import { WatchListContextProps, WatchlistState } from './types';

export const WatchListContext = createContext<WatchListContextProps>({} as WatchListContextProps);

const InitialState: WatchlistState = defaultState;

export function WatchListContextProvider({ children }: { children: ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);

    const [watchListState, watchListDispatcher] = useReducer(watchlistReducer, InitialState);

    return (
        <WatchListContext.Provider value={{ watchListState, watchListDispatcher }}>
            {memorizedChildren}
        </WatchListContext.Provider>
    );
}
