import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, SxProps, Typography, styled } from '@mui/material';

export const CommonDescription = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: fontFamilies.GraphikRegular,
}));

export const Description = styled(CommonDescription)(({ theme }) => ({
    fontSize: '18px',
    lineHeight: '32px',
}));

export const CommonMediumLabelStyle = {
    color: '#0B2958',
    fontFamily: fontFamilies.GraphikMedium,
};

export const LabelStyle = {
    ...CommonMediumLabelStyle,
    paddingBottom: '16px',
    fontSize: '26px',
    lineHeight: 'normal',
};

export function Image({ src, sx }: { src: string; sx: SxProps }) {
    return (
        <Box
            component='img'
            src={src}
            alt='insight image'
            sx={{
                borderRadius: '4px',
                objectFit: 'cover',
                ...sx,
            }}
        />
    );
}

export const animation = 'all .5s ease';
