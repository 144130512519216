import { ETFCard, Layout, Typography } from '@cfra-nextgen-frontend/shared';
import { TableExportMenus } from '@cfra-nextgen-frontend/shared/src/components/Chart/ExportMenus';
import { LinearProgressBar } from '@cfra-nextgen-frontend/shared/src/components/Progress/LinearProgressBar';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import { ReadMore } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMore';
import { ETFInfo } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFInfo/ETFInfo';
import {
    breakpointsTheme,
    scrollbarThemeV2,
    tableTheme,
} from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    ValueTypes,
    excludeNodeOnImageExport,
    fontFamilies,
    fontWeights,
    formatValue,
    getJPEGDownloadOption,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SectionKeys, SectionsWithInformation } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { HTMLToImage } from '@cfra-nextgen-frontend/shared/src/utils/htmlToImage';
import { Box, ThemeProvider } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FUNDynamixLogo from 'assets/images/FUNDynamixLogo.svg';
import { getDefaultFontStyle } from 'components/Chart/Options';
import { useMemo, useRef } from 'react';
import { getETFCFRARatings, getEtfDataHoldingsAndExposure } from '../api/etfDetailsData';
import { ETFDetailsParams } from '../types/research';

export default function CFRARatings({ cfraId, companyData }: { cfraId: string; companyData: ETFDetailsParams }) {
    const cardElement = useRef(null);
    const cardName = 'CFRA ETF Ratings';

    const cfraRatingsResult = getETFCFRARatings({
        cfraId: cfraId,
        config: {
            refetchOnWindowFocus: false,
        },
    });

    // getting UseQueryResult object with data for etfDataHoldingsAndExposure.current
    const etfDataHoldingsAndExposureQueryResult = getEtfDataHoldingsAndExposure({
        cfraId: cfraId,
        top: 10,
        config: {
            refetchOnWindowFocus: false,
        },
    });

    const modifyCFRARatingBeforeExport = (
        node: HTMLElement | null,
        showExportOnly: boolean = true,
    ): HTMLElement | any => {
        function processElement(elements: any) {
            if (elements) {
                for (let i = 0; i < elements.length; i++) {
                    const element: any = elements[i];
                    if (showExportOnly) {
                        element.style.display = 'block';
                    } else {
                        element.style.display = 'none';
                    }
                }
            }
        }

        let elements = node?.getElementsByClassName('show-export-only');
        processElement(elements);

        elements = node?.getElementsByClassName('hide-export-only');
        showExportOnly = !showExportOnly;
        processElement(elements);

        return node;
    };

    const downloadOptions = useMemo(() => {
        const htmlToImage = new HTMLToImage(
            cardElement,
            `CFRA-ETF-Ratings-${companyData?.ticker}-${companyData?.exchange}`,
        );
        return getJPEGDownloadOption(cardName, () =>
            htmlToImage
                .toJPEG({ modifyNode: modifyCFRARatingBeforeExport, filter: excludeNodeOnImageExport })
                ?.then(() => {
                    modifyCFRARatingBeforeExport(cardElement.current, false);
                }),
        );
    }, [companyData?.ticker, companyData?.exchange]);

    // show card loading if data still loading
    if (cfraRatingsResult.isLoading && !cfraRatingsResult.data) {
        return <ETFCard.ETFCard isLoading={cfraRatingsResult.isLoading} />;
    }

    const cfraRatingsData = cfraRatingsResult.data;

    // return EmptyCard if no data
    if (
        !(
            cfraRatingsData &&
            cfraRatingsData.stars_rating &&
            cfraRatingsData.cost != null &&
            cfraRatingsData.reward != null &&
            cfraRatingsData.risk != null
        )
    ) {
        return <ETFCard.ETFEmptyCard cardLabel={cardName}></ETFCard.ETFEmptyCard>;
    }

    const ItemSubHeader = styled(ETFCard.ItemHeader)(({ theme }) => ({
        ...getDefaultFontStyle(15),
    }));

    const SubHeder = styled(Paper)(({ theme }) => ({
        fontSize: '15px',
        fontFamily: fontFamilies.GraphikMedium,
        color: '#002B5A',
        paddingBottom: '10px',
    }));

    const Title = styled(Typography)(({ theme }) => ({
        color: '#002B5A',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        lineHeight: '21px',
    }));

    const Value = styled(Typography)(({ theme }) => ({
        color: '#002B5A',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        lineHeight: 1,
        fontWeight: fontWeights.Bold,
        marginLeft: '10px',
        marginTop: '-1px',
    }));

    const LogoImage = styled('img')(() => ({
        height: 24,
    }));

    const subHeader =
        "CFRA ETF Ratings apply forward-looking metrics and the unique characteristics of the fund's holdings rather than solely relying on historical perfomance";

    const scoreFootnote = 'Scores range from 1 to 100; 100 is most favorable';

    const CommentarySection = ({ commentary }: { commentary: string }) => {
        return (
            <>
                <SubHeder>Commentary</SubHeder>
                <ReadMore text={commentary} modalTitle={'Commentary'} className='export-ignore hide-export-only' />
                <div className='show-export-only' style={{ display: 'none' }}>
                    <ReadMore
                        text={commentary}
                        modalTitle={'Commentary'}
                        textLengthToShowReadMore={commentary.length + 1}
                    />
                </div>
            </>
        );
    };

    const componentScores = [
        { text: 'Cost', value: cfraRatingsData.cost, titleLow: 'Most Expensive', titleHigh: 'Least Expensive' },
        { text: 'Reward', value: cfraRatingsData.reward, titleLow: 'Low', titleHigh: 'High' },
        { text: 'Risk', value: cfraRatingsData.risk, titleLow: 'Riskiest', titleHigh: 'Safest' },
    ];

    let percentValue = '';

    if (etfDataHoldingsAndExposureQueryResult?.data?.total_weight_percentage) {
        percentValue = formatValue({
            value: etfDataHoldingsAndExposureQueryResult?.data?.total_weight_percentage,
            formattingType: ValueTypes.Percentage,
        }).toString();
    }

    const informationModal = SectionsWithInformation[SectionKeys.CFRARatings];
    return (
        <ThemeProvider theme={tableTheme}>
            <ETFCard.ETFCard containerStyles={{ position: 'relative' }} ref={cardElement}>
                <Layout.Grid
                    item
                    xs={12}
                    sx={{ paddingLeft: '28px', paddingRight: '28px', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <ETFCard.ItemHeader style={{ paddingBottom: '12px' }}>{cardName}</ETFCard.ItemHeader>
                        <Box sx={{ marginLeft: '5px', paddingBottom: '2px' }}>
                            <ETFInfo
                                title={informationModal.title}
                                description={informationModal.information}
                                buttonFontSize={16}
                                modalBoxStyles={{
                                    maxHeight: '78.7%',
                                    overflowY: 'auto',
                                    ...scrollbarThemeV2,
                                }}
                                descriptionStyle={{
                                    boxShadow: 'none',
                                }}
                                titleStyle={{
                                    boxShadow: 'none',
                                }}
                            />
                        </Box>
                    </Box>
                    <div className='export-ignore'>
                        <TableExportMenus menuItemsProps={downloadOptions} exportCallback={() => {}} />
                    </div>
                </Layout.Grid>
                <ItemSubHeader style={{ paddingLeft: '28px', paddingRight: '28px', lineHeight: '21px' }}>
                    {subHeader}
                </ItemSubHeader>
                <Layout.Grid container spacing={4} style={{ paddingLeft: '28px', paddingRight: '28px' }}>
                    <Layout.Grid container item xs={12} md={7}>
                        <Layout.Grid item xs={12} flexDirection={'column'} justifyContent={'flex-start'}>
                            <SubHeder>Stars Rating</SubHeder>
                            <Rating value={Number(cfraRatingsData.stars_rating)} size={'medium'} />
                        </Layout.Grid>
                        <Layout.Grid
                            item
                            xs={12}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                                marginTop: '30px',
                            }}>
                            <CommentarySection
                                commentary={cfraRatingsData.commentary.replace('{percent}', percentValue)}
                            />
                        </Layout.Grid>
                    </Layout.Grid>
                    <Layout.Grid
                        item
                        xs={12}
                        md={5}
                        flexDirection={'column'}
                        display={'block'}
                        sx={{
                            [breakpointsTheme.breakpoints.down('md')]: {
                                marginTop: '20px',
                            },
                        }}>
                        <SubHeder>Rating Scores</SubHeder>
                        {componentScores.map((score, index) => (
                            <Layout.Grid key={index} container flexDirection={'column'} height={'70px'}>
                                <Layout.Grid>
                                    <Title>{score.text}</Title>
                                </Layout.Grid>
                                <Layout.Grid item>
                                    <Layout.Grid item xs={10} display={'block'}>
                                        <LinearProgressBar
                                            value={score.value}
                                            variant={'determinate'}
                                            titlelow={score.titleLow}
                                            titlehigh={score.titleHigh}
                                        />
                                    </Layout.Grid>
                                    <Layout.Grid item xs={2}>
                                        <Value>{score.value}</Value>
                                    </Layout.Grid>
                                </Layout.Grid>
                            </Layout.Grid>
                        ))}
                        <Box
                            component='div'
                            sx={{
                                fontFamily: fontFamilies.GraphikRegular,
                                color: 'rgb(118, 129, 140)',
                                fontSize: '11px',
                            }}>
                            {scoreFootnote}
                        </Box>
                    </Layout.Grid>
                    <Layout.Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                            marginTop: '20px',
                        }}>
                        <CommentarySection commentary={cfraRatingsData.commentary.replace('{percent}', percentValue)} />
                    </Layout.Grid>
                </Layout.Grid>
                <Layout.DataAsOfDate date={cfraRatingsData.ratings_as_of_data} />
                <Layout.Grid
                    className='show-export-only'
                    style={{ paddingLeft: '28px', paddingTop: '28px', display: 'none' }}>
                    <LogoImage src={FUNDynamixLogo} alt='CFRA FundDynamix Logo' />
                </Layout.Grid>
            </ETFCard.ETFCard>
        </ThemeProvider>
    );
}
