import { RefObject, useEffect, useState } from 'react';

export function useIsVisible(ref: RefObject<HTMLElement[]>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

        if (ref.current) {
            for (var curr of ref.current) {
                observer.observe(curr);
            }
        }
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
}
