import { IconButton } from '@cfra-nextgen-frontend/shared';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import {
    getSelectedItem,
    getSelectedView,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { AnalyticsTickerSearchContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { SelectedWatchlistState } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { tickerSearchTheme } from '@cfra-nextgen-frontend/shared/src/components/Screener/tickerSearch/theme';
import { CancelRounded } from '@mui/icons-material';
import { SvgIcon, SxProps, TextField, ThemeProvider } from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/images/SearchIcon.svg';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type SearchInputProps = {
    cardName: string;
    inputStyles?: SxProps;
    placeholder: string;
};

export function SearchInput({ cardName, inputStyles, placeholder }: SearchInputProps) {
    const { setSearchTerm, searchTerm, filtersPostData } = useContext(FiltersModalContext);
    const [inputText, setInputText] = useState<string>('');

    const { clearRowsSelections } = useContext(AgGridSelectedRowsContext);

    const { watchListState, watchListDispatcher } = useContext(WatchListContext);
    const selectedWatchlist = watchListState?.selectedWatchlist;
    const selectedWatchlistState = watchListState?.selectedWatchlistState;

    const { state } = useLocation();

    useEffect(() => {
        if (state && state.setSelectedWatchlist) {
            watchListDispatcher?.({ type: 'SetSelectedWatchlist', payload: state.setSelectedWatchlist });
        }
    }, [state, watchListDispatcher]);

    const { screenerViewState } = useContext(ScreenerViewContext);
    const screenerActiveView = screenerViewState?.screenerActiveView;

    const { saveScreenState } = useContext(SaveScreenContext);
    const selectedScreen = saveScreenState?.selectedScreen;

    const { setTickerSearchActionProps } = useContext(AnalyticsTickerSearchContext);

    const setDebouncedSearchTerm = useMemo(
        () =>
            debounce((value: string) => {
                setSearchTerm(value);

                if (!value) return;

                setTickerSearchActionProps({
                    action: 'screener ticker search',
                    cardName: cardName,
                    selectedWatchlist: getSelectedItem(selectedWatchlist),
                    selectedView: getSelectedView(screenerActiveView),
                    selectedScreen: getSelectedItem(selectedScreen),
                    search2Term: value,
                });
            }, 500),
        [setSearchTerm, cardName, selectedScreen, selectedWatchlist, screenerActiveView, setTickerSearchActionProps],
    );

    const clearSearch = useCallback(() => {
        setInputText('');
        setSearchTerm('');
        setDebouncedSearchTerm('');
    }, [setSearchTerm, setDebouncedSearchTerm]);

    useEffect(() => {
        if (!searchTerm) {
            clearSearch();
        }
    }, [searchTerm, clearSearch]);
    const isWatchlistApplied =
        typeof selectedWatchlist?.id === 'number' && selectedWatchlistState === SelectedWatchlistState.SELECTED;
    useEffect(() => {
        //clear current selections whenever search is applied and watchlist is not selected
        if (!isWatchlistApplied) {
            clearRowsSelections();
        }
    }, [searchTerm, clearRowsSelections, isWatchlistApplied]);

    useEffect(() => {
        //clear selections whenever filter/search is applied or watchlist is removed or switched
        if (typeof selectedWatchlist?.id !== 'number' || selectedWatchlistState === SelectedWatchlistState.LOADING) {
            clearRowsSelections();
        }
    }, [filtersPostData, selectedWatchlist, selectedWatchlistState, clearRowsSelections]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            clearSearch();
            return;
        }
        setInputText(event.target.value);
        setDebouncedSearchTerm(event.target.value);
    };

    return (
        <ThemeProvider theme={tickerSearchTheme}>
            <TextField
                placeholder={placeholder}
                value={inputText}
                onChange={handleChange}
                InputProps={{
                    sx: inputStyles,
                    endAdornment: inputText ? (
                        <IconButton onClick={clearSearch} icon={<CancelRounded />} />
                    ) : (
                        <SvgIcon viewBox='0 0 40 40'>
                            <SearchIcon />
                        </SvgIcon>
                    ),
                }}
            />
        </ThemeProvider>
    );
}
