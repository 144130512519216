import { PlusIconTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/PlusIconTextButton';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import {
    cancelButtonText,
    confirmButtonText,
    okButtonText,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/utils';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { ActionTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/types';
import { useContext } from 'react';
import { CustomViewEditorForm } from './CustomViewEditorForm';

export function CustomViewEditorModalOpenButton(props: { cardName: string }) {
    const { customViewEditorStateDispatcher } = useContext(CustomViewEditorContext);
    const createViewButtonText = 'Create View';
    return (
        <PlusIconTextButton
            onClickCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${createViewButtonText}`,
                    cardName: props.cardName,
                });
                customViewEditorStateDispatcher({ type: 'OpenCustomViewEditor' });
            }}
            buttonText={createViewButtonText}
        />
    );
}

export function CustomViewEditorModal() {
    const {
        customViewEditorState: {
            openModal,
            isDirtyRightColumn,
            openExitConfirmationModal,
            openNoColumnsSelectedModal,
            screenerUpdateView,
            openEditActiveViewConfirmationModal,
        },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);

    const editViewButtonText = 'Edit View';
    const customViewEditorTitle = 'Custom View Editor';
    const analyticsCardName = `${customViewEditorTitle} close confirmation popup`;
    const maxNumberOfColumnsExceededPopup = 'Max number of columns exceeded popup';

    return (
        <>
            <Modal // Custom View Editor modal
                title={screenerUpdateView?.label || customViewEditorTitle}
                openModal={openModal}
                callbackOnClose={() => {
                    const actions: Array<ActionTypes> = [];

                    if (isDirtyRightColumn) {
                        actions.push('OpenExitConfirmationModal');
                    } else {
                        actions.push('CloseCustomViewEditor', 'SetResetColumnsPending');
                    }
                    customViewEditorStateDispatcher({
                        type: actions,
                    });
                }}
                modalBoxStyles={{
                    padding: '32px 30px 29px',
                    display: 'flex',
                    alignContent: 'start',
                    flexDirection: 'column',
                    maxWidth: '850px',
                    maxHeight: '650px',
                    height: '78.7%',
                }}
                titleAndDescriptionContainerStyle={{
                    display: 'flex',
                }}
                titleAndDescriptionContainerXs={false}>
                <CustomViewEditorForm analyticsCardName={customViewEditorTitle} />
            </Modal>
            <ConfirmationModal // Custom View Editor close confirmation modal
                openModal={openExitConfirmationModal}
                modalText={`Exit without saving?`}
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelButtonText}`,
                        cardName: customViewEditorTitle,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseExitConfirmationModal',
                    });
                }}
                cancelButtonText={cancelButtonText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmButtonText}`,
                        cardName: analyticsCardName,
                    });
                    customViewEditorStateDispatcher({
                        type: ['CloseExitConfirmationModal', 'CloseCustomViewEditor', 'SetResetColumnsPending'],
                    });
                }}
                confirmButtonText={confirmButtonText}
            />
            <ConfirmationModal // Custom View Editor view now when no columns selected modal
                openModal={openNoColumnsSelectedModal}
                modalText='Please add at least one column to your custom view.'
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${okButtonText}`,
                        cardName: analyticsCardName,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseNoColumnsSelectedModal',
                    });
                }}
                confirmButtonText={okButtonText}
            />
            <ConfirmationModal // Confirmation modal display on export screener data with more than 25 columns in active view
                openModal={openEditActiveViewConfirmationModal}
                modalText='To keep things running smoothly, we limit exports to 25 columns at a time. Please edit your custom view and try again.'
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${editViewButtonText}`,
                        cardName: maxNumberOfColumnsExceededPopup,
                    });
                    customViewEditorStateDispatcher({
                        type: ['CloseEditActiveViewConfirmationModal', 'SetEditActiveViewPending'],
                    });
                }}
                confirmButtonText={editViewButtonText}
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelButtonText}`,
                        cardName: maxNumberOfColumnsExceededPopup,
                    });
                    customViewEditorStateDispatcher({
                        type: 'CloseEditActiveViewConfirmationModal',
                    });
                }}
                cancelButtonText={cancelButtonText}
            />
        </>
    );
}
