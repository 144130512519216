import { SavedItemDetailsType, SelectedWatchlistState, WatchlistAction, WatchlistState } from "./types";
export const defaultState: WatchlistState = {
    openModal: false,
    selectedWatchlist: undefined,
    selectedWatchlistState: SelectedWatchlistState.NONE,
};;

export function watchlistReducer(state: WatchlistState, action: WatchlistAction): WatchlistState {
    switch (action.type) {
        case 'OpenSaveWatchlistModal':
            return { ...defaultState, openModal: true };
        case 'CloseSaveWatchlistModal':
            return { ...defaultState, openModal: false };
        case 'SetSelectedWatchlist':
            return {
                ...state,
                selectedWatchlist: action.payload,
                selectedWatchlistState: SelectedWatchlistState.LOADING
            };
        case 'SetSelectedWatchlistDetails':
            return {
                ...state,
                selectedWatchlist: { ...state.selectedWatchlist, ...action.payload },
                selectedWatchlistState: SelectedWatchlistState.SELECTED
            };
        case 'ClearSelectedWatchlist':
            if (state.selectedWatchlist === undefined) {
                return state
            } else {
                return { ...state, selectedWatchlist: undefined, selectedWatchlistState: SelectedWatchlistState.NONE };
            }
        case 'RenameSelectedWatchlist':
            return {
                ...state,
                selectedWatchlist: { ...state.selectedWatchlist, name: action.payload } as SavedItemDetailsType,
            };
    }
}
