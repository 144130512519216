import { UnAggregatedScreenerFieldData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedViews';
import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { aggregateScreenerFieldsData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/savedViews';
import { SearchByParams, getFullRequestQuery, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UseData, getData, prefetchQuery } from 'utils';

export function getScreenerData({
    requestBody,
    securityType = 'etf',
    view = 'default',
    search,
    from,
    size,
    usePrefetchQuery = false,
    includeData,
    includeMetadata,
    thematicScreen,
}: SearchByParams) {
    const requestQuery = getRequestQuery(
        {
            securityType,
            view,
            search,
            from,
            size,
            includeData,
            includeMetadata,
            thematicScreen,
        },
        'screener',
    );
    const queryKey = [
        'getScreenerEtfData',
        securityType,
        view,
        search,
        requestBody,
        from,
        size,
        includeData,
        includeMetadata,
        thematicScreen,
    ];

    if (usePrefetchQuery) {
        return prefetchQuery({
            requestQuery,
            queryKey,
            apiName: ApiNames.OpenSearch,
            requestType: RequestTypes.POST,
            requestBody: requestBody,
        });
    }

    return UseData<ScreenerEtfData>({
        requestQuery,
        queryKey,
        apiName: ApiNames.OpenSearch,
        requestType: RequestTypes.POST,
        requestBody: requestBody,
    });
}

export function getScreenerDataSSR({
    requestBody,
    securityType = 'etf',
    view = 'default',
    search,
    from,
    size,
    sortDirection,
    orderBy,
}: SearchByParams) {
    const requestQuery = getRequestQuery(
        { securityType, view, search, from, size, sortDirection, orderBy },
        'screener',
    );

    return getData<ScreenerEtfData>({
        requestQuery: getFullRequestQuery({ apiName: ApiNames.OpenSearch, requestQuery }),
        requestBody: requestBody,
        apiName: ApiNames.OpenSearch,
        requestType: RequestTypes.POST,
    });
}

export function getScreenerReqBody(filtersData?: Object, fieldsData?: Array<UnAggregatedScreenerFieldData>) {
    return filtersData || fieldsData
        ? {
              ...(filtersData && { filters: { values: filtersData } }),
              ...(fieldsData && { fields: { values: aggregateScreenerFieldsData(fieldsData) } }),
          }
        : undefined;
}
