import { AgGrid, ETFCard, Utils } from '@cfra-nextgen-frontend/shared';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { getCompanyDetailsLinkRenderer } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import {
    Categories,
    CategoryToLidDbFieldName,
    MarketTrendsDateRanges,
    marketTrendsDateRangesToDisplayString,
    valuesTypesToExcelNumberFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef } from 'react';
import { getEtfs } from '../../api/market';
import { getMinWidthForHeader } from '../../components/shared/utils';
import { MarketTrend } from '../../types/market';

export type EtfsGridCardProps = {
    lid: number;
    categoryLevel: Categories;
    label: MarketTrend;
    dateRange: MarketTrendsDateRanges;
    maxGridContainerHeightPercentage?: number;
    analyticsCardName?: string;
};

export function getAsOfDateField(label: MarketTrend) {
    switch (label) {
        case MarketTrend.FlowsToAssetsDetails:
            return 'data_latest_nav_stats.as_of_date';
        case MarketTrend.PerformanceDetails:
            return 'data_latest_price_stats.as_of_date';
    }
    return '';
}

function getDataFieldPath(label: MarketTrend, dateRange: MarketTrendsDateRanges) {
    if (label === MarketTrend.FlowsToAssetsDetails) {
        switch (dateRange) {
            case MarketTrendsDateRanges.OneMonth:
                return 'data_latest_nav_stats.flows_to_assets_ratio_one_month';
            case MarketTrendsDateRanges.ThreeMonth:
                return 'data_latest_nav_stats.flows_to_assets_ratio_three_month';
            case MarketTrendsDateRanges.OneYear:
                return 'data_latest_nav_stats.flows_to_assets_ratio_one_year';
            case MarketTrendsDateRanges.YTD:
                return 'data_latest_nav_stats.flows_to_assets_ratio_ytd';
        }
    }
    if (label === MarketTrend.PerformanceDetails) {
        switch (dateRange) {
            case MarketTrendsDateRanges.OneMonth:
                return 'data_latest_price_stats.return_split_and_dividend_one_month';
            case MarketTrendsDateRanges.ThreeMonth:
                return 'data_latest_price_stats.return_split_and_dividend_three_month';
            case MarketTrendsDateRanges.OneYear:
                return 'data_latest_price_stats.return_split_and_dividend_one_year';
            case MarketTrendsDateRanges.YTD:
                return 'data_latest_price_stats.split_and_dividend_ytd_return';
        }
    }
    return '';
}

function getDataColumnHeaderName(label: MarketTrend, dateRange: MarketTrendsDateRanges) {
    if (label === MarketTrend.FlowsToAssetsDetails) {
        return `${marketTrendsDateRangesToDisplayString[dateRange]} Flows to Asset Ratio`;
    }
    if (label === MarketTrend.PerformanceDetails) {
        return `${marketTrendsDateRangesToDisplayString[dateRange]} Performance`;
    }
}

export const EtfsGridCard = forwardRef<AgGridReact, EtfsGridCardProps>((props, ref) => {
    const { lid, categoryLevel, label, dateRange, maxGridContainerHeightPercentage, analyticsCardName } = props;

    const categoryFields = [
        'composite_name',
        'composite_ticker',
        getAsOfDateField(label),
        'sponsor',
        getDataFieldPath(label, dateRange),
    ];

    const etfsData = getEtfs({
        fields: categoryFields,
        filterBy: [
            {
                fieldName: CategoryToLidDbFieldName[categoryLevel],
                value: String(lid),
            },
        ],
        sortDirection: 'desc',
        orderBy: getDataFieldPath(label, dateRange),
    });

    if (etfsData.isLoading) {
        return <ETFCard.ETFCard isLoading={etfsData.isLoading} />;
    }

    if (!(etfsData.data && etfsData.data.data?.length > 0)) {
        return <NoInformationAvailable />;
    }

    const companyDetailsLinkRenderer = getCompanyDetailsLinkRenderer({
        cfraIdPath: 'firstbridge_id',
        cardName: analyticsCardName ? analyticsCardName : label,
        categoryLevel: categoryLevel,
        dateRange: dateRange,
    });

    let columnDefs: Array<ColumnDef> = [
        {
            headerName: 'ETF Name',
            field: 'composite_name',
            cellRenderer: companyDetailsLinkRenderer,
        },
        { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: companyDetailsLinkRenderer },
        {
            headerName: 'Sponsor',
            field: 'sponsor',
            flex: 1,
        },
        {
            headerName: getDataColumnHeaderName(label, dateRange),
            field: getDataFieldPath(label, dateRange),
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
            type: 'rightAligned',
            sort: 'desc',
            flex: 1,
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
        },
    ];

    return (
        <AgGirdCard
            columnDefs={columnDefs}
            rowsData={etfsData.data.data}
            ref={ref}
            maxNumberOfRowsToDisplay={13}
            maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
            customFlexibleColumns={[Categories.CategoryOne, Categories.CategoryTwo, 'ETF Name']}
            getResizableMinWidthForColumn={getMinWidthForHeader}
            excelExportAsOfDateField='as_of_date'
            defaultMaxWidth={350}
        />
    );
});
