import {
    ScreenerActiveViewLocalStorageKey,
    ScreenerCurrentViewLocalStorageKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { getIsActionMatch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/reducers';
import { removeDataFromLocalStorage, setDataToLocalStorage } from '@cfra-nextgen-frontend/shared/src/utils/storage';
import { Action, State } from './types';

export function ScreenerViewReducer(state: State, action: Action): State {
    const isActionMatch = getIsActionMatch(action.type);

    if (isActionMatch('SetScreenerActiveView')) {
        if (!action.newState?.screenerActiveView) {
            throw new Error('Invalid SetScreenerActiveView new state.');
        }
        setDataToLocalStorage(ScreenerActiveViewLocalStorageKey, action.newState.screenerActiveView);
        state = { ...state, screenerActiveView: action.newState.screenerActiveView };
    }

    if (isActionMatch('SetScreenerCurrentView')) {
        if (!action.newState?.screenerCurrentView) {
            throw new Error('Invalid SetScreenerCurrentView new state.');
        }
        setDataToLocalStorage(ScreenerCurrentViewLocalStorageKey, action.newState.screenerCurrentView);
        state = { ...state, screenerCurrentView: action.newState.screenerCurrentView };
    }
    if (isActionMatch('RemoveScreenerCurrentView')) {
        removeDataFromLocalStorage(ScreenerCurrentViewLocalStorageKey);
        state = { ...state, screenerCurrentView: undefined };
    }

    if (isActionMatch('SetScreenerSaveView')) {
        if (!action.newState?.screenerSaveView) {
            throw new Error('Invalid SetScreenerSaveView new state.');
        }
        state = { ...state, screenerSaveView: action.newState.screenerSaveView };
    }
    if (isActionMatch('RemoveScreenerSaveView')) {
        state = { ...state, screenerSaveView: undefined };
    }

    if (isActionMatch('SetRefetchAllPending')) {
        state = { ...state, refetchPendingType: 'all' };
    }
    if (isActionMatch('SetRefetchPendingForSpecificView')) {
        state = { ...state, refetchPendingType: action.newState?.refetchPendingType };
    }
    if (isActionMatch('SetRefetchDone')) {
        state = { ...state, refetchPendingType: undefined };
    }

    if (isActionMatch('SetAllFieldsData')) {
        state = { ...state, allFieldsData: action.newState?.allFieldsData };
    }

    return state;
}
