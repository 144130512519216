import { toJpeg } from 'html-to-image';
import { Options } from 'html-to-image/lib/types';
import { RefObject } from 'react';

export class HTMLToImage {

    private element: RefObject<HTMLElement>;
    private imageName: string;

    constructor(ref: RefObject<HTMLElement>, imageName: string = 'CFRA_Data') {
        this.element = ref;
        this.imageName = imageName;
    }

    toJPEG(props: { modifyNode?: (node: HTMLElement | null) => HTMLElement} & Options = {}) {
        if (this.element?.current) {
            return toJpeg(props?.modifyNode ? props?.modifyNode(this.element.current) : this.element.current, { cacheBust: true, ...props })
                .then((dataUrl) => {
                    const link = document.createElement('a')
                    link.download = this.imageName + '.jpeg'
                    link.href = dataUrl
                    link.click()
                })
                .catch((err) => {
                    console.error('Error generating Image: ' + this.imageName + ' from HTML');
                    return;
                })
        }
    }
}