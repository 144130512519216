import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment, { Moment } from 'moment';
dayjs.extend(utc);

export const UnixTimestamp = (): number => {
    return Math.floor(Date.now() / 1000);
};

export const todayDateStringUS = (): string => {
    return moment().format('MM/DD/YYYY'); // 09/27/2022
};

export const todayDateString = (format: string = 'YYYY-MM-DD'): string => {
    return moment().format(format); // 9/27/2022
};

export const isWeekEnd = (date: Moment): boolean => {
    return date.isoWeekday() === 7 || date.isoWeekday() === 6; // return true is date is Sunday or Saturday
};

export const getNextMonday = (date: Moment): Moment => {
    let result = date.clone();
    while (result.isoWeekday() !== 1) result.add(1, 'days');
    return result;
};

export const standardDateFormat = 'YYYY-MM-DD';
const standardDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const getMomentObjectFrom = (date: string, format: string = standardDateFormat): Moment => {
    const dateObject = moment.utc(date, format);

    if (format === standardDateFormat && !dateObject.isValid()) {
        return moment.utc(date, standardDateTimeFormat);
    }

    return moment.utc(date, format);
};

export function getDateTimeUtcNow(format: string = 'Iso'): string {
    const momentUtcNow = moment.utc();

    if (format === 'Iso') {
        return momentUtcNow.toISOString();
    }

    return momentUtcNow.format(format);
}

export const asOfDateFormat = (date: string, format: string = 'l'): string => {
    return getMomentObjectFrom(date).format(format);
};

export function getDateOrUndefined(dateTimeUtc: any) {
    let dateObject = new Date(`${dateTimeUtc}Z`);
    return !isNaN(dateObject.getTime()) ? dateObject : undefined;
}

export function getDayjsUtcDate(inputValue: Dayjs | string | null): Dayjs | null {
    if (inputValue instanceof dayjs || inputValue === null) {
        return inputValue as Dayjs | null;
    }

    return dayjs.utc(inputValue);
}
