import { ExportMenusBase } from '@cfra-nextgen-frontend/shared/src/components/Chart/ExportMenus';
import { ValueTypes } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { MultipleExport, exportChart, exportMultipleCharts } from './Export';

export type CustomExportsProps = {
    type: string;
    callback: (props?: any) => void;
}[];

type ExportMenusProps = {
    chartRef: React.RefObject<HighchartsReact.RefObject | null>;
    analyticsCallback?: (type: string) => void;
    customExports?: CustomExportsProps;
    headers?: {
        title?: string;
        asOfDate?: string;
        ticker?: string;
        etfName?: string;
        columns?: Map<string, ValueTypes>;
    };
};

export default function ExportMenus(exportMenusProps: ExportMenusProps) {
    const defaultExportTypes = ['XLSX', 'JPEG'];
    const menuItemsProps = new Map<string, () => void>();

    defaultExportTypes.forEach((type) =>
        menuItemsProps.set(type, () =>
            exportChart({
                chartRef: exportMenusProps.chartRef,
                type: type.toLocaleLowerCase(),
                headers: exportMenusProps.headers,
            }),
        ),
    );

    exportMenusProps.customExports?.forEach((_export) => {
        menuItemsProps.set(_export.type, () =>
            _export.callback({ chartRef: exportMenusProps.chartRef, type: _export.type.toLocaleLowerCase() }),
        );
    });

    return ExportMenusBase({ menuItemsProps: menuItemsProps, analyticsCallback: exportMenusProps.analyticsCallback });
}

export function ExportMenusForMultipleCharts({
    multipleExport,
    analyticsCallback,
    customExports,
}: {
    multipleExport: MultipleExport;
    analyticsCallback?: (type: string) => void;
    customExports?: CustomExportsProps;
}) {
    const defaultExportTypes = ['XLSX', 'JPEG'];
    const menuItemsProps = new Map<string, () => void>();

    defaultExportTypes.forEach((type) =>
        menuItemsProps.set(type, () =>
            exportMultipleCharts({ multipleExport: multipleExport, type: type.toLocaleLowerCase() }),
        ),
    );

    customExports?.forEach((_export) => {
        menuItemsProps.set(_export.type, () => _export.callback());
    });

    return ExportMenusBase({ menuItemsProps, analyticsCallback });
}
