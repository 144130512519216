import { FormInputSlider } from '@cfra-nextgen-frontend/shared/src/components/Form/FormInputSlider';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import {
    getCombinedSliderFilterItemMetadata,
    getDynamicScale,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import {
    applyFieldMetadataToValue,
    sliderApplyScaleToValue,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { useMediaQuery } from '@mui/material';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

export const ScreenerFormInputSlider: React.FC<FilterProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    validate,
    submitHandler,
    getValues,
    setValue,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
}) => {
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));
    const isAboveMd = useMediaQuery(breakpointsTheme.breakpoints.up('md'));
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const stackLabelWithContainer = filterMetadata.sections[parentSectionKey]?.stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    if (!filterMetadata.range || filterMetadata.range.length !== 2) {
        throw new Error('No range provided for component slider.');
    }

    let min =
        typeof filtersData.data[filterMetadataKey]?.min === 'number'
            ? (filtersData.data[filterMetadataKey]?.min as number)
            : filterMetadata.range[0];
    let max =
        typeof filtersData.data[filterMetadataKey]?.max === 'number'
            ? (filtersData.data[filterMetadataKey]?.max as number)
            : filterMetadata.range[1];
    let selectedValueMin: number | null = null;
    let selectedValueMax: number | null = null;

    const isDollarDataPoint = filterMetadata.item_metadata.symbol === '$';
    const isPercentageDataPoint = filterMetadata.item_metadata.symbol === '%';

    const sliderApplyScaleToValueLocal = (value: number) =>
        sliderApplyScaleToValue({
            value: value,
            isPercentageDataPoint: isPercentageDataPoint,
            itemMetadata: filterMetadata.item_metadata,
        });

    if (!isDollarDataPoint) {
        min = sliderApplyScaleToValueLocal(min);
        max = sliderApplyScaleToValueLocal(max);
    }

    if (filterMetadata.default_values && filterMetadata.range.length === 2) {
        selectedValueMin = filterMetadata.default_values[0];
        selectedValueMax = filterMetadata.default_values[1];

        if (!isDollarDataPoint) {
            if (selectedValueMin !== null) {
                selectedValueMin = sliderApplyScaleToValueLocal(selectedValueMin);
            }
            if (selectedValueMax !== null) {
                selectedValueMax = sliderApplyScaleToValueLocal(selectedValueMax);
            }
        }
    }

    let combinedSliderMetadata = getCombinedSliderFilterItemMetadata(filterMetadata);

    const filter = (
        <FormInputSlider
            control={control}
            getValues={getValues}
            setValue={setValue}
            validate={validate}
            submitHandler={submitHandler}
            size='small'
            min={min}
            max={max}
            step={Number(((max - min) / 200).toFixed(2))}
            selectedValueMin={selectedValueMin !== null ? selectedValueMin : min}
            selectedValueMax={selectedValueMax !== null ? selectedValueMax : max}
            name={combineIntoFormElementName({
                componentName: Components.Slider,
                filterMetadataKey,
            })}
            helperTexts={filterMetadata.helperTexts}
            formatTextInputValue={({ rawValue, isFocused }) => {
                if (isFocused) {
                    return String(rawValue);
                }

                const scale = isDollarDataPoint ? getDynamicScale(Number(rawValue)) : combinedSliderMetadata.scale;

                return applyFieldMetadataToValue({
                    fieldMetadata: {
                        ...combinedSliderMetadata,
                        scale: scale,
                        value_template: isPercentageDataPoint
                            ? combinedSliderMetadata.value_template
                            : // eslint-disable-next-line no-template-curly-in-string
                              '{value}{scale}',
                    },
                    rawValue: Number(rawValue),
                    skipScale: !isDollarDataPoint,
                });
            }}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            hide={hide}
            label={label}
            layoutProps={layoutProps}
            labelLayout={isBelowLg && isAboveMd ? 3.5 : 5}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
