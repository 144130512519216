import { ETFCard, Hooks, Layout } from '@cfra-nextgen-frontend/shared';
import {
    DateRanges,
    ValueTypes,
    getMomentObjectFrom,
    getSumOfAllValues,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { getMinAndMaxTick, getTickIntervalFrom } from 'components/Chart/Chart';
import {
    addAsOfDateToHighchart,
    addLogoToHighchart,
    exportChartAsImgWithFonts,
    getExportDefaultChartOptions,
} from 'components/Chart/Export';
import { CustomExportsProps } from 'components/Chart/ExportMenus';
import { StyledFlowsChart, formatFlowsNumber, getDateForTooltipFormatter } from 'components/Chart/Flows';
import { getMarginOptions, getSpacingOptions, getTooltipHTML } from 'components/Chart/Options';
import { DateValuePair } from 'components/Chart/types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import moment, { Moment } from 'moment';
import { getETFFlows } from '../api/etfDetailsData';
import { ETFDataAllRanges, ETFDataAllRangesQueryResult, ETFDataFlows, ETFFlowsProps } from '../types/research';

type ETFFlowsChartData = {
    xAxisDates: Array<Moment>;
    categoriesData: Array<DateValuePair>;
};

const localStorageID = 'etf-flows-chart-';

const getTotalRow = (periodSelected: DateRanges, categoriesData: Array<number | null>): JSX.Element => {
    const periodSelectedToDisplayRepresentation: Record<DateRanges, string> = {
        [DateRanges.OneWeek]: '1 Week',
        [DateRanges.OneMonth]: '1 Month',
        [DateRanges.ThreeMonth]: '3 Month',
        [DateRanges.SixMonth]: '6 Month',
        [DateRanges.OneYear]: '1 Year',
        [DateRanges.ThreeYears]: '3 Years',
        [DateRanges.FiveYears]: '5 Years',
    };

    const totalRowText = `${periodSelectedToDisplayRepresentation[periodSelected]} Net Flows: `;
    const totalRowValue = formatFlowsNumber({
        value: getSumOfAllValues(categoriesData),
        skipSpaceBetweenDollarAndNumber: true,
    })
        .replace('B', 'billions')
        .replace('M', 'millions')
        .replace('K', 'thousands');

    return (
        <ETFCard.TotalRowBox>
            {totalRowText} {totalRowValue}
        </ETFCard.TotalRowBox>
    );
};

const getNetFlows = (periodSelected: DateRanges, categoriesData: Array<number | null>): string => {
    const periodSelectedToDisplayRepresentation: Record<DateRanges, string> = {
        [DateRanges.OneWeek]: '1 Week',
        [DateRanges.OneMonth]: '1 Month',
        [DateRanges.ThreeMonth]: '3 Month',
        [DateRanges.SixMonth]: '6 Month',
        [DateRanges.OneYear]: '1 Year',
        [DateRanges.ThreeYears]: '3 Years',
        [DateRanges.FiveYears]: '5 Years',
    };

    const totalRowText = `${periodSelectedToDisplayRepresentation[periodSelected]} Net Flows: `;
    const totalRowValue = formatFlowsNumber({
        value: getSumOfAllValues(categoriesData),
        skipSpaceBetweenDollarAndNumber: true,
    })
        .replace('B', 'billions')
        .replace('M', 'millions')
        .replace('K', 'thousands');

    return `${totalRowText} ${totalRowValue}`;
};

export const sortByPeriodAsc = (ETFFlowsData: Array<ETFDataFlows>) => {
    ETFFlowsData.sort((a, b) => (new Date(a.period_of) > new Date(b.period_of) ? 1 : -1)); // sort ETFFlowsData by as_of_date asc order
    return ETFFlowsData;
};

function getChartDataFor(ETFFlowsData: Array<ETFDataFlows>) {
    const result: ETFFlowsChartData = {
        xAxisDates: [],
        categoriesData: [],
    };

    ETFFlowsData.forEach((val) => {
        result.xAxisDates.push(getMomentObjectFrom(val.period_of));
        result.categoriesData.push([getMomentObjectFrom(val.period_of).toDate().getTime(), val.net_flows]);
    });

    return result;
}

export const getChartDataAllRanges = (cfraId: string): ETFDataAllRangesQueryResult => {
    const etfDataAllRanges: ETFDataAllRangesQueryResult = {};
    Object.values(DateRanges).forEach((dateRange) => {
        etfDataAllRanges[dateRange] = getETFFlows({
            cfraId: cfraId,
            dateRange: dateRange,
        });
    });
    return etfDataAllRanges;
};

export function extractETFDataForAllRanges(etfDataAllRangesQueryResult: ETFDataAllRangesQueryResult): ETFDataAllRanges {
    const etfDataAllRanges: ETFDataAllRanges = {};
    Object.keys(etfDataAllRangesQueryResult).forEach((key) => {
        if (!etfDataAllRangesQueryResult[key].data) etfDataAllRanges[key] = [];
        if (etfDataAllRangesQueryResult[key].data?.data !== undefined) {
            etfDataAllRanges[key] = etfDataAllRangesQueryResult[key].data?.data as ETFDataFlows[];
        }
    });
    return etfDataAllRanges;
}

export function determineDateRangeToShowByDefault(
    etfDataAllRanges: ETFDataAllRanges,
    mapFuncton: (element: ETFDataFlows) => boolean,
): DateRanges | undefined {
    const defaultDateRangeSequence: Array<DateRanges> = [
        DateRanges.ThreeMonth,
        DateRanges.OneMonth,
        DateRanges.OneWeek,
        DateRanges.SixMonth,
        DateRanges.OneYear,
        DateRanges.ThreeYears,
        DateRanges.FiveYears,
    ];

    const disabledDateRanges = getDateRangesWithDisabledButton(etfDataAllRanges, mapFuncton);

    return defaultDateRangeSequence.find((range) => !disabledDateRanges.has(range));
}

export function getDateRangesWithDisabledButton(
    etfDataAllRanges: ETFDataAllRanges,
    mapFuncton: (element: ETFDataFlows) => boolean,
): Set<DateRanges> {
    const result = new Set<DateRanges>();

    const isEmptyRange = (dataList: Array<ETFDataFlows>) => !dataList.map(mapFuncton).some((x) => x);

    Object.keys(etfDataAllRanges).forEach((key) => {
        // add range to always disabled if have no data
        if (isEmptyRange(etfDataAllRanges[key])) result.add(key as DateRanges);
    });

    const orderedDateRanges: Array<DateRanges> = Object.values(DateRanges);

    orderedDateRanges.forEach((element, index) => {
        if (index === orderedDateRanges.length - 1) return;
        const currentDateRange = orderedDateRanges[index];
        const nextDateRange = orderedDateRanges[index + 1];
        const currentETFDataMinPeriodOf: Moment = moment.min(
            etfDataAllRanges[currentDateRange].map((element) => getMomentObjectFrom(element.period_of)),
        );

        // remove all data with period of greater than currentETFDataMinPeriodOf
        const filteredNextETFData = etfDataAllRanges[nextDateRange].filter((element) => {
            return getMomentObjectFrom(element.period_of).isBefore(currentETFDataMinPeriodOf);
        });

        // add next range to always disabled if it have no additional data in compairing with current range
        if (isEmptyRange(filteredNextETFData)) result.add(nextDateRange as DateRanges);
    });

    return result;
}

export default function ETFFlows({ companyData, cfraId, componentRendered }: ETFFlowsProps) {
    const [periodSelected, setPeriodSelected] = Hooks.useLocalStorage<DateRanges>(
        localStorageID + 'selected-date-range',
        DateRanges.ThreeMonth,
    );
    const etfDataAllRangesQueryResult: ETFDataAllRangesQueryResult = getChartDataAllRanges(cfraId);

    // create function for setting columns names in csv export
    const columnHeaderFormatter = function (item: any) {
        if (item instanceof Highcharts.Axis && item.isXAxis) {
            return 'Date';
        } else return periodSelected + ' Flows';
    };

    // is data for any of ranges still loading, show loading element
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map((element) => element.isLoading)
            .some((x) => x)
    ) {
        return <ETFCard.ETFCard isLoading={true} />;
    }

    const chartTitle = 'Fund Flows';
    const chartDescription = 'Historical fund flows for multiple time periods';

    // return EmptyCard if no data
    if (
        Object.values(etfDataAllRangesQueryResult)
            .map(
                (element) =>
                    Boolean(element) &&
                    Boolean(element.data) &&
                    typeof element.data?.data === 'object' &&
                    Array.isArray(element.data.data) &&
                    element.data.data.length > 0 &&
                    element.data.data.some((obj) => Number.isFinite(obj.net_flows)),
            )
            .every((x) => !x)
    ) {
        return <ETFCard.ETFEmptyCard cardLabel={chartTitle}></ETFCard.ETFEmptyCard>;
    }

    const etfDataAllRanges = extractETFDataForAllRanges(etfDataAllRangesQueryResult);

    const mapFunction = (element: ETFDataFlows) => Number.isFinite(element.net_flows);
    const defaultDateRange = determineDateRangeToShowByDefault(etfDataAllRanges, mapFunction);

    // show nothing if no data for all the ranges
    if (!defaultDateRange) return <ETFCard.ETFEmptyCard cardLabel={chartTitle}></ETFCard.ETFEmptyCard>;
    if (periodSelected === undefined) setPeriodSelected(defaultDateRange);
    // if no periodSelected set - show loading;
    if (periodSelected === undefined) return <ETFCard.ETFCard isLoading={true} />;

    const ETFFlowsData = sortByPeriodAsc(etfDataAllRanges[periodSelected]);
    const chartData = getChartDataFor(ETFFlowsData) as ETFFlowsChartData;
    const xAxisDates = chartData.xAxisDates;
    const categoriesData = chartData.categoriesData;
    const roundedTick = getTickIntervalFrom(categoriesData.map((value) => value[1]));

    const tooltipFormatter = function (this: Highcharts.TooltipFormatterContextObject) {
        const date = getDateForTooltipFormatter(this, periodSelected);

        return getTooltipHTML(date, `Fund Flow: ${formatFlowsNumber({ value: this.y as number })}`);
    };

    const exportFileName = `fund-flows-chart-${companyData.ticker}-${companyData.exchange}-${periodSelected}`;

    const [minTick, maxTick]: [number, number] = getMinAndMaxTick(
        roundedTick,
        categoriesData.map((value) => value[1]),
        true,
    );

    const asOfDate = [...Object.values(etfDataAllRanges).flatMap((x) => x.map((y) => y.as_of_date))]
        .sort()
        .reverse()[0];

    const customExportsProps: CustomExportsProps = [
        {
            type: 'JPEG',
            callback: ({ chartRef }) =>
                exportETFFlowsJpegChart({
                    chartRef: chartRef.current,
                    title: chartTitle,
                    subTitle: chartDescription,
                    ticker: companyData.ticker,
                    asOfDate,
                    netFlows: getNetFlows(
                        periodSelected,
                        categoriesData.map((value) => value[1]),
                    ),
                }),
        },
    ];
    const columns: Map<string, ValueTypes> = new Map<string, ValueTypes>([
        ['Date', ValueTypes.Date],
        [periodSelected + ' Flows', ValueTypes.Currency],
    ]);

    return (
        <ETFCard.ETFCard containerStyles={{ position: 'relative' }}>
            <StyledFlowsChart
                series={categoriesData}
                title={chartTitle}
                subTitle={chartDescription}
                ticks={{ min: minTick, max: maxTick, tickerInterval: roundedTick }}
                tooltipFormatter={tooltipFormatter}
                columnHeaderFormatter={columnHeaderFormatter}
                periodSelected={periodSelected}
                xAxisDates={xAxisDates}
                exportFileName={exportFileName}
                setPeriodSelected={setPeriodSelected}
                dateRangesWithDisabledButton={getDateRangesWithDisabledButton(etfDataAllRanges, mapFunction)}
                plotOptionsAnimations={componentRendered ? false : true}
                customExports={customExportsProps}
                exports={{
                    asOfDate,
                    columns,
                    etfName: companyData.composite_name,
                    ticker: companyData.ticker,
                }}
            />
            <Layout.Grid item xs={12}>
                {getTotalRow(
                    periodSelected,
                    categoriesData.map((value) => value[1]),
                )}
            </Layout.Grid>
            <Layout.DataAsOfDate date={asOfDate} />
        </ETFCard.ETFCard>
    );
}

async function exportETFFlowsJpegChart(props: {
    chartRef: HighchartsReact.RefObject;
    title: string;
    subTitle: string;
    ticker: string;
    asOfDate: string;
    netFlows: string;
}) {
    const { chartRef, title, subTitle, ticker, asOfDate, netFlows } = props;
    const chart: Highcharts.Chart = chartRef.chart;

    const defaultChartOptions: Highcharts.Options = getExportDefaultChartOptions({ title, subtitle: subTitle, ticker });

    const chartOptions: Highcharts.Options = {
        ...defaultChartOptions,
        chart: {
            ...defaultChartOptions.chart,
            ...getSpacingOptions([25, 45, 40, 40]),
            ...getMarginOptions([110, undefined, 110]),
            height: 600,
            width: 1000,
            events: {
                load: function (this: Highcharts.Chart) {
                    addLogoToHighchart(this);
                    addAsOfDateToHighchart(this, asOfDate);

                    if (netFlows) {
                        this.renderer
                            .text(netFlows, this.chartWidth / 2, this.chartHeight - 40)
                            .attr({ align: 'center', zIndex: 99 })
                            .css({ color: '#57626a', fontSize: '14px' })
                            .add();
                    }
                },
            },
        },
    };

    exportChartAsImgWithFonts(chart, chartOptions);
}
