import { styled } from '@mui/material';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import { Layout, Typography } from '@cfra-nextgen-frontend/shared';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 14,
    borderRadius: '100px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#E5E9EF',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#002B5A',
    },
}));

const ProgressLabel = styled('div')(({ theme }) => ({
    color: '#002B5A',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '12px',
    lineHeight: 1
}));

export function LinearProgressBar({ ...props }: { titlelow?: string, titlehigh?: string } & LinearProgressProps) {
    return (
        <>
            <BorderLinearProgress {...props} />
            {(props.titlelow || props.titlehigh) &&
            <Layout.Grid flexDirection={'row'} display={'flex'} justifyContent={'space-between'} marginTop={'5px'}>
                <ProgressLabel>{props.titlelow}</ProgressLabel>
                <ProgressLabel sx={{ textAlign: 'end' }}>{props.titlehigh}</ProgressLabel>
            </Layout.Grid>}
        </>
    );
}
