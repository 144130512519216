import { createContext, useCallback, useMemo, useReducer } from 'react';
import { multipleModalsReducer } from './MultipleModalsReducer';
import { DispatcherState, State } from './types';

type MultipleModalsState = {
    getModalState: (modalId: string) => State['modals'][string];
} & DispatcherState;

export const MultipleModalsContext = createContext<MultipleModalsState>({} as MultipleModalsState);

const InitialState: State = {
    modals: {},
};

export function MultipleModalsContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [state, stateDispatcher] = useReducer(multipleModalsReducer, InitialState);

    const getModalState: MultipleModalsState['getModalState'] = useCallback(
        (modalId) => {
            return state.modals[modalId];
        },
        [state.modals],
    );

    const contextValue = {
        multipleModalsState: state,
        multipleModalsStateDispatcher: stateDispatcher,
        getModalState,
    };

    return <MultipleModalsContext.Provider value={contextValue}>{memorizedChildren}</MultipleModalsContext.Provider>;
}
