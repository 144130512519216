import { getDownloadAction } from '../analytics/utils';

export const excludeNodeOnImageExport = (node: HTMLElement) => {
    const exclusionClasses = ['export-ignore'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}

export const getJPEGDownloadOption = (cardName: string, callback: any) => {
    const registerDownload = (downloadType: string) =>
        globalThis.analytics?.registerAction?.({
            action: getDownloadAction(downloadType),
            cardName,
            reportType: 'JPEG'
        });

    const downloadOptions = new Map<string, () => void>();

    downloadOptions.set('Download JPEG', () => {
        callback?.();
        registerDownload('DownloadJPEG');
    });

    return downloadOptions;
}