import {
    CellRendererValueProcessor,
    getCellRenderer,
    getCompanyDetailsLinkRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import {
    getFilterKeysToRowLevelFilter,
    getObjectWithFilter,
    getRowLevelFilterCellRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/rowFilters';
import {
    IViewDataFields,
    ScreenerData,
    ScreenerEtfData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { ViewdataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { getMomentObjectFrom, standardDateFormat } from '@cfra-nextgen-frontend/shared/src/utils/time';
import { Box } from '@mui/material';
import { ValueFormatterParams } from 'ag-grid-community';
import { getExcelExportDateFormat, getExcelNumberFormat } from './excelExport';
import { defaultHeaderTemplate, fillTemplate } from './templates';
import { defaultNoRatingText, defaultNoResultsSymbol, getScreenerValueFormatter } from './valueFormatters';
import { getScreenerNumberFilterValueGetter } from './valueGetters';

function sortViewdataAsc(viewdataFields: Array<IViewDataFields>): Array<IViewDataFields> {
    return viewdataFields.sort((n1, n2) => Object.values(n1)[0].order - Object.values(n2)[0].order);
}

function getHeaderTemplate(header_template?: string, symbol?: string) {
    return !header_template && symbol === '$' ? defaultHeaderTemplate : header_template;
}

function getHeaderName(fieldMetadata: MetadataFieldDefinition, headerTemplate?: string) {
    let result = fieldMetadata.label;

    if (headerTemplate) {
        result =
            fillTemplate({
                templateName: 'header_template',
                template: headerTemplate,
                dataObject: fieldMetadata,
            }) || result;
    }

    return result;
}

function getCellRendererValueProcessor(fieldMetadata: MetadataFieldDefinition): CellRendererValueProcessor {
    return ({ resultChild, component }) => {
        switch (component) {
            case 'rating':
                if (!resultChild) {
                    return fieldMetadata.no_value_symbol || defaultNoRatingText;
                }
                return <Rating value={Number(resultChild)} />;

            case 'list':
                return <Box>{resultChild}</Box>;

            default:
                throw new Error(
                    `The component ${component} is not supported by the screener renderer result child wrapper.`,
                );
        }
    };
}

export function extractFromScreenerData(
    screenerData: ScreenerEtfData | ScreenerData,
    cardName: string,
    filterKeysToRowLevelFilter?: ReturnType<typeof getFilterKeysToRowLevelFilter>,
): {
    minWidths: Record<string, number>;
    customFlexibleColumns: Array<string>;
    columnDefs: Array<ColumnDef>;
} {
    const minWidths: Record<string, number> = {};
    const customFlexibleColumns: Array<string> = [];

    function fillMinWidth(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (fieldViewdata.min_width && typeof fieldViewdata.min_width === 'number') {
            minWidths[headerName] = fieldViewdata.min_width;
        }
    }

    function fillCustomFlexibleColumns(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (typeof fieldViewdata.custom_flex === 'boolean' && fieldViewdata.custom_flex) {
            customFlexibleColumns.push(headerName);
        }
    }

    const columnDefs: Array<ColumnDef> = [];

    sortViewdataAsc(screenerData._viewdata.fields).forEach((fieldDict) => {
        const field = Object.keys(fieldDict)[0];
        const fieldViewdata = fieldDict[field];

        if (fieldViewdata.hide) {
            return;
        }

        const fieldMetadata = screenerData._metadata.fields.filter((dict) => Object.keys(dict)[0] === field)[0][field];

        const headerTemplate = getHeaderTemplate(fieldMetadata.header_template, fieldMetadata.symbol);

        const headerNameFromMetadata = getHeaderName(fieldMetadata, headerTemplate);
        const hederNameFromViewData = fieldViewdata.header_name;
        const headerName = hederNameFromViewData || headerNameFromMetadata;

        fillMinWidth(fieldViewdata, headerName);
        fillCustomFlexibleColumns(fieldViewdata, headerName);

        function cellRendererGetter() {
            if (fieldViewdata.link) {
                return getCompanyDetailsLinkRenderer({
                    cfraIdPath: 'id',
                    cardName,
                    urlLinkPattern: fieldMetadata.url_link?.pattern,
                    target: fieldViewdata.link_target,
                });
            }

            if (!fieldViewdata.cell_renderer_params) {
                return;
            }

            const objectWithFilter = getObjectWithFilter(fieldViewdata.cell_renderer_params);

            if (objectWithFilter && filterKeysToRowLevelFilter) {
                return getRowLevelFilterCellRenderer({
                    cellRendererParam: objectWithFilter,
                    filterKeysToRowLevelFilter,
                    fieldMetadata,
                    screenerData,
                });
            }

            return getCellRenderer({
                cellRendererParams: fieldViewdata.cell_renderer_params,
                cardName: cardName,
                urlLinkPattern: fieldMetadata.url_link?.pattern,
                cellRendererValueProcessor: getCellRendererValueProcessor(fieldMetadata),
            });
        }

        const valueFormatter = getScreenerValueFormatter(
            {
                ...fieldMetadata,
                value_template: fieldViewdata.value_template || fieldMetadata.value_template,
            },
            screenerData,
        );

        let result: ColumnDef = {
            headerName: headerName,
            field: field,
            filter: 'agTextColumnFilter',
            cellRenderer: cellRendererGetter(),
            valueFormatter: valueFormatter,
            flex: fieldViewdata.flex,
            headerClass: fieldViewdata.header_class,
            cellClass: fieldViewdata.cell_class,
            sort: fieldViewdata.default_sort_order,
            maxWidth: fieldViewdata.max_width,
            pinned: fieldViewdata.pinned,
            comparator: (valueA, valueB, nodeA, nodeB) => {
                if (fieldMetadata.type === 'date' || fieldMetadata.type === 'number') {
                    if (!valueA && valueB) return 1;
                    if (valueA && !valueB) return -1;
                }

                if (fieldMetadata.type === 'date') {
                    const dateFormat = fieldMetadata.input_format || standardDateFormat;
                    return getMomentObjectFrom(valueA, dateFormat).isAfter(getMomentObjectFrom(valueB, dateFormat))
                        ? 1
                        : -1;
                }

                if (fieldMetadata.type === 'number') {
                    return valueA > valueB ? 1 : -1;
                }

                // convert to string
                const formattedValueA = String(
                    valueFormatter({ data: nodeA.data, colDef: result } as ValueFormatterParams),
                );
                const formattedValueB = String(
                    valueFormatter({ data: nodeB.data, colDef: result } as ValueFormatterParams),
                );

                // always move down empty values
                if (formattedValueA === defaultNoResultsSymbol && formattedValueB !== defaultNoResultsSymbol) return 1;
                if (formattedValueB === defaultNoResultsSymbol && formattedValueA !== defaultNoResultsSymbol) return -1;

                return formattedValueA?.toLowerCase?.() > formattedValueB?.toLowerCase?.() ? 1 : -1;
            },
            exportType: fieldViewdata.export_cell_type
        };

        if (fieldMetadata.type === 'number') {
            result = {
                ...result,
                ...(!fieldViewdata.cell_type && {
                    type: 'rightAligned',
                }),
                filter: 'agNumberColumnFilter',
                filterValueGetter: getScreenerNumberFilterValueGetter(fieldMetadata),
                excelExportNumberFormat: getExcelNumberFormat(fieldMetadata),
            };
        }

        if (fieldMetadata.type === 'date') {
            result = {
                ...result,
                excelExportDateFormat: fieldMetadata.format
                    ? getExcelExportDateFormat(fieldMetadata.format)
                    : undefined,
            };
        }

        columnDefs.push(result);
    });

    return { minWidths, customFlexibleColumns, columnDefs };
}
