import { AxiosError } from 'axios';
import { QueryClient, UseMutationOptions, UseQueryOptions } from 'react-query';

const defaultStaleTime = 10 * 60 * 1000; // 10 minutes
const defaultCacheTime = 30 * 60 * 1000; // 30 minutes

export function getQueryClient(staleTime: number = defaultStaleTime, cacheTime: number = defaultCacheTime) {
    return new QueryClient({
        defaultOptions: {
            queries: {
                useErrorBoundary: true,
                refetchOnWindowFocus: false,
                retry: false,
                staleTime,
                cacheTime,
            },
        },
    });
}

export const queryClient = getQueryClient();

/** Extract Return type of query function */
export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

/** Type for query config */
export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
    UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
    'queryKey' | 'queryFn'
>;

/** Type for mutation config */
export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
>;
