import { COGNITO_REFRESH_TOKEN_DELTA } from '@cfra-nextgen-frontend/shared/src/config';
import { Buffer } from 'buffer';
import { AuthUser, CognitoAuthResponse, idToken } from '../features/auth';

export const storage = {
    /** Get Token from LocalStorage */
    getToken: () => {
        var token: AuthUser;
        try {
            token = JSON.parse(window.localStorage.getItem('user') as string) as AuthUser;
        } catch {
            token = {} as AuthUser;
        }
        return token;
    },
    /** Set Token in LocalStorage */
    setToken: (token: CognitoAuthResponse) => {
        var userData: AuthUser =
            (JSON.parse(window.localStorage.getItem('user') as string) as AuthUser) || ({} as AuthUser);
        if (token) {
            var tokenPayload: string = token.idToken.split('.')[1];
            var tokenDecoded: idToken = JSON.parse(Buffer.from(tokenPayload, 'base64').toString('utf8'));

            userData.accessToken = token.accessToken;
            userData.idToken = token.idToken;
            userData.refreshToken = token.refreshToken ? token.refreshToken : userData.refreshToken;
            userData.tokenType = token.tokenType;
            userData.authTime = tokenDecoded.auth_time;
            userData.iat = tokenDecoded.iat;
            userData.refreshTokenTimeExp = tokenDecoded.auth_time + COGNITO_REFRESH_TOKEN_DELTA;
            userData.exp = tokenDecoded.exp;
            userData.username = token.username;
            userData.email = token.email;
            userData.role = token.role;

            //DON'T DELETE FOR NOW
            //token.authTime = tokenDecoded.auth_time;
            //token.exp = tokenDecoded.exp;
            //token.refreshTokenTime = token.refreshToken ? tokenDecoded.auth_time : userData.refreshTokenTime;
            //token.refreshTokenTimeExp = token.refreshTokenTime + COGNITO_REFRESH_TOKEN_DELTA;
            //token.refreshToken = userData && userData.refreshToken ? userData.refreshToken : token.refreshToken;

            window.localStorage.setItem('user', JSON.stringify(userData));
        }
    },
    /** Clear Token in LocalStorage */
    clearToken: () => {
        window.localStorage.removeItem('user');
    },
};
