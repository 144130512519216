import { useEffect, useState } from 'react';
import { deleteCustomView } from '../api/customViews';
import { DeleteModal } from './DeleteModal';

type DeleteSavedViewModalProps = {
    id?: number;
    name: string;
    onCancel: () => void;
    onSuccessDelete: () => void;
    onConfirmClick?: () => void;
};

export function DeleteSavedViewModal(props: DeleteSavedViewModalProps) {
    const { id, name, onCancel = () => {}, onSuccessDelete = () => {}, onConfirmClick } = props;
    const [confirm, setConfirm] = useState(false);

    const { isError, isLoading } = deleteCustomView({
        savedItems: id,
        config: {
            enabled: confirm,
        },
    });

    useEffect(() => {
        if (confirm && !isLoading && !isError) {
            onSuccessDelete?.();
            setConfirm(false);
        }
    }, [confirm, isLoading, isError, onSuccessDelete]);

    const defaultHandleConfirm = () => {
        setConfirm(true);
    };

    const customHandleConfirm = () => {
        onConfirmClick?.();
    };

    return (
        <DeleteModal
            id={id || 0}
            title={`Are you sure you want to delete ${name}?`}
            onCancel={onCancel}
            onConfirm={onConfirmClick ? customHandleConfirm : defaultHandleConfirm}
        />
    );
}
