import { FormControlledTextField } from '@cfra-nextgen-frontend/shared/src/components/Form/FormControlledTextField';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

type ScreenerFormVirtualizeAutocompleteProps = {} & FilterProps;

export const ScreenerFormInputText: React.FC<ScreenerFormVirtualizeAutocompleteProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    component,
    fieldDefaultStyles = {},
    parentSectionKey,
    setValue,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const defaultValue = (filterMetadata.default_value || '') as string;
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);

    let filter = (
        <FormControlledTextField
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.Text,
                filterMetadataKey,
            })}
            validationRules={validationRules}
            placeholder={filterMetadata.placeholder || label}
            defaultValue={defaultValue}
            submitHandler={submitHandler}
            fieldDefaultStyles={fieldDefaultStyles}
            setValue={setValue}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
