import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';

export function SnackMessageForm({
    message,
    actionBtnText,
    onActionBtnClick,
}: {
    message: string;
    actionBtnText?: string;
    onActionBtnClick?: () => void;
}) {
    return (
        <>
            <div>{message}</div>
            {actionBtnText && (
                <div>
                    <LinkButton sx={{ color: '#3078B5', fontSize: '14px' }} onClick={onActionBtnClick}>
                        {actionBtnText}
                    </LinkButton>
                </div>
            )}
        </>
    );
}
