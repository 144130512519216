import { FilterMetadata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import {
    getDividerString,
    replaceAllDotsWithTabs,
    replaceAllTabsWithDots,
} from '@cfra-nextgen-frontend/shared/src/utils/strings';

export function getFilterLabel(filterMetadata: FilterMetadata, parentSectionKey: string) {
    const label = filterMetadata.label !== undefined ? filterMetadata.label : filterMetadata.item_metadata.label;
    const sections = filterMetadata.sections;
    const show_asterisk = sections[parentSectionKey]?.show_asterisk;

    return `${label}${show_asterisk ? '*' : ''}`;
}

export const stackLabelWithContainerProps = { disableStacking: true, labelLayout: 12, containerLayout: 12 };

export const dividerString = getDividerString('DIVIDER');
const formElementNameParts = ['componentName', 'filterMetadataKey', 'uniqueIdWithinGroup'];
export const formElementNamePartsTemplate = formElementNameParts.map((item) => '{' + item + '}').join(dividerString);

type FormElementNameParts = {
    componentName: string;
    filterMetadataKey: string;
    uniqueIdWithinGroup?: string; // for cases like filters in grid column, where all the filterMetadataKey is the same, but they should have separate states
};

export function combineIntoFormElementName(props: FormElementNameParts) {
    const extendedProps = formElementNameParts.reduce(
        (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue]: props[currentValue as keyof FormElementNameParts] || '',
        }),
        {} as FormElementNameParts,
    );

    extendedProps.filterMetadataKey = replaceAllDotsWithTabs(extendedProps.filterMetadataKey);

    return fillTemplate({
        templateName: 'formElementNamePartsTemplate',
        template: formElementNamePartsTemplate,
        dataObject: extendedProps,
    }) as string;
}

export function parseFormElementName(formElementName: string): FormElementNameParts {
    const parsedFormElementName = formElementName.split(dividerString).reduce((accumulator, currentValue, index) => {
        return { ...accumulator, [formElementNameParts[index]]: currentValue };
    }, {} as FormElementNameParts);

    parsedFormElementName.filterMetadataKey = replaceAllTabsWithDots(parsedFormElementName.filterMetadataKey);

    return parsedFormElementName;
}
