import React, { Dispatch, createContext, useMemo, useReducer } from 'react';
import { chipStateReducer } from './ResultsStateReducer';
import { ChipAction, ChipEvents, ChipItem, ChipState, ChipsState, ClearField, DirtyData } from './types';
interface ResultsContextProps {
    chipStateManager: ChipsState;
    chipEventsManager: ChipEvents;
}

export const ResultsContext = createContext<ResultsContextProps>({} as ResultsContextProps);

const InitialChipState: ChipState = {
    resultCount: -1,
    field: {} as ClearField,
    action: '',
    chipItems: undefined,
    filtersDirtyData: {},
};

const onDelete = function (dispatcher: Dispatch<ChipAction>) {
    return function (chips: Record<string, ChipItem>, key: string) {
        return function () {
            let dirtyData: DirtyData = {};
            let keyToDelete = key;

            if (chips) {
                dirtyData = Object.entries(chips)
                    .filter(([key, chipItem]) => keyToDelete !== key)
                    .reduce((dirtyData, [key, chipItem]) => {
                        let controlID = chipItem.stateData.controlID;
                        let chipState = chipItem.stateData.values;

                        dirtyData[controlID] =
                            controlID in dirtyData
                                ? [...dirtyData[controlID], ...(chipState as Array<any>)]
                                : chipState;
                        return dirtyData;
                    }, dirtyData);
            }

            dispatcher({
                type: 'SetChipDirtyState',
                newState: {
                    field: {
                        controlID: chips[key].stateData.controlID,
                        dirtyData,
                    },
                },
            });
        };
    };
};

const onClear = function (dispatcher: Dispatch<ChipAction>) {
    return function () {
        dispatcher({ type: 'ClearChips' });
    };
};

export function ResultsContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [chipState, chipStatedispatcher] = useReducer(chipStateReducer, InitialChipState);

    const value = {
        chipStateManager: {
            chipState: chipState,
            chipStateDispatcher: chipStatedispatcher,
        },
        chipEventsManager: {
            onChipDeleteClick: onDelete(chipStatedispatcher),
            onChipClearAllClick: onClear(chipStatedispatcher),
        },
    };

    return <ResultsContext.Provider value={value}>{memorizedChildren}</ResultsContext.Provider>;
}
