import { getCompanyDetailsLinkRenderer } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { FilterMetadata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import {
    applyFieldMetadataToValue,
    defaultNoResultsSymbol,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { Box, SxProps } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

const staticTextContainerStyles: SxProps = { overflowWrap: 'anywhere' };
const staticTextListStyles: SxProps = { display: 'flex', flexDirection: 'column' };

export default function ScreenerFormStaticText({
    filtersData,
    filterMetadataKey,
    layoutProps,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    defaultValue,
}: FilterProps) {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const stackLabelWithContainer = filterMetadata.sections[parentSectionKey]?.stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    let value: any = defaultValue !== undefined ? defaultValue : filterMetadata.default_value;

    if (Array.isArray(value)) {
        if (value.length === 0) {
            value.push(defaultNoResultsSymbol);
        }

        const itemList: any[] = [];
        value.forEach((eachVal, idx) => {
            let item: any = eachVal;

            if (eachVal !== defaultNoResultsSymbol && filterMetadata.link) {
                item = updateValueWithLink(eachVal, filterMetadata, parentSectionKey, label, idx);
            }

            itemList.push(item);
        });

        value = <Box sx={staticTextListStyles}>{React.Children.toArray(itemList)}</Box>;
    } else {
        if (value === null || value === undefined || value === '') {
            value = defaultNoResultsSymbol;
        } else {
            value = applyFieldMetadataToValue({ fieldMetadata: filterMetadata.item_metadata, rawValue: value });
        }

        if (value !== defaultNoResultsSymbol && filterMetadata.link) {
            value = updateValueWithLink(value, filterMetadata, parentSectionKey, label, 0);
        }
    }

    const filter = <Box sx={staticTextContainerStyles}>{value}</Box>;

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            disableStacking={false}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
}

function updateValueWithLink(
    value: any,
    filterMetadata: FilterMetadata,
    parentSectionKey: string,
    label: string,
    index: number,
): any {
    return getCompanyDetailsLinkRenderer({
        cardName: `${parentSectionKey}${label}ScreenerFormStaticText`,
        urlLinkPattern: filterMetadata?.item_metadata?.url_link?.pattern,
        target: filterMetadata?.link_target,
    })({
        value,
        data: filterMetadata?.item_metadata?.url_link?.params?.[index] || {},
    } as ICellRendererParams<any, any, any>);
}
