import { getIsActionMatch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/reducers';
import { Action, State } from './types';

export function customViewEditorReducer(state: State, action: Action): State {
    const isActionMatch = getIsActionMatch(action.type);

    if (isActionMatch('OpenCustomViewEditor')) {
        state = { ...state, openModal: true };
    }
    if (isActionMatch('CloseCustomViewEditor')) {
        state = { ...state, openModal: false };
    }

    if (isActionMatch('OpenNoColumnsSelectedModal')) {
        state = { ...state, openNoColumnsSelectedModal: true };
    }
    if (isActionMatch('CloseNoColumnsSelectedModal')) {
        state = { ...state, openNoColumnsSelectedModal: false };
    }

    if (isActionMatch('OpenExitConfirmationModal')) {
        state = { ...state, openExitConfirmationModal: true };
    }
    if (isActionMatch('CloseExitConfirmationModal')) {
        state = { ...state, openExitConfirmationModal: false };
    }

    if (isActionMatch('OpenNameViewModalInSaveMode')) {
        state = { ...state, nameOverlayMode: 'save' };
    }
    if (isActionMatch('OpenNameViewModalInRenameMode')) {
        state = { ...state, nameOverlayMode: 'rename' };
    }
    if (isActionMatch('CloseNameViewModal')) {
        state = { ...state, nameOverlayMode: false };
    }

    if (isActionMatch('SetResetColumnsPending')) {
        state = { ...state, resetColumnsState: true };
    }
    if (isActionMatch('SetResetColumnsDone')) {
        state = { ...state, resetColumnsState: false };
    }

    if (isActionMatch('SetRightColumnDirty')) {
        state = { ...state, isDirtyRightColumn: true };
    }
    if (isActionMatch('SetRightColumnClean')) {
        state = { ...state, isDirtyRightColumn: false };
    }

    if (isActionMatch('SetScreenerUpdateView')) {
        if (!action.newState?.screenerUpdateView) {
            throw new Error('Invalid screenerUpdateView new state.');
        }
        state = { ...state, screenerUpdateView: action.newState?.screenerUpdateView };
    }
    if (isActionMatch('RemoveScreenerUpdateView')) {
        state = { ...state, screenerUpdateView: undefined };
    }
    if (isActionMatch('SetEditActiveViewPending')) {
        state = { ...state, editActiveView: true };
    }
    if (isActionMatch('SetEditActiveViewDone')) {
        state = { ...state, editActiveView: false };
    }
    if (isActionMatch('OpenEditActiveViewConfirmationModal')) {
        state = { ...state, openEditActiveViewConfirmationModal: true };
    }
    if (isActionMatch('CloseEditActiveViewConfirmationModal')) {
        state = { ...state, openEditActiveViewConfirmationModal: false };
    }

    return state;
}
