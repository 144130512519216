import { Card as CFRACard, Typography } from '@cfra-nextgen-frontend/shared';
import { fontFamilies, minHorizontalPagePadding } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled } from '@mui/material';
import { CommonDescription } from 'features/cfraInsights/components/shared';
import { SingleThemeData } from 'features/cfraInsights/types/thematic';
import { Image, animation } from './shared';

type ThematicCardProps = {
    singleThemeData: SingleThemeData;
    horizontalCardPadding: number;
};

const Title = styled(Typography)(({ theme }) => ({
    color: '#000',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '20px',
    lineHeight: '32px',
}));

const CardDescription = styled(CommonDescription)(({ theme }) => ({
    color: '#000',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    lineHeight: '28px',
}));

const imageWidth = 380; // from figma
const cardPadding = 14;

export function Card(props: ThematicCardProps) {
    const { singleThemeData, horizontalCardPadding } = props;
    return (
        <CFRACard.Card
            sx={{
                width: `clamp(150px, 100vw - ${
                    2 * horizontalCardPadding + 2 * cardPadding + 2 * minHorizontalPagePadding
                }px, ${cardPadding * 2 + imageWidth}px)`, // needed on small screens
                height: '100%', // needed to make box shadow same height for all items in row
                img: {
                    transition: animation,
                },
                ':hover': {
                    boxShadow: '0 3px 10px 0 rgba(0,0,0,.16)',
                    img: {
                        transform: 'scale(1.2)',
                    },
                },
                transition: animation,
            }}>
            <Box sx={{ padding: `${cardPadding}px`, maxWidth: '100%', maxHeight: '100%' }}>
                <Box sx={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '205px', borderRadius: '4px' }}>
                    <Image
                        src={singleThemeData.image_url || ''}
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    />
                </Box>
                <Box sx={{ paddingTop: '20px' }}>
                    <Title textAlign='left' sx={{ paddingBottom: '5px' }}>
                        {singleThemeData.display_name}
                    </Title>
                    <CardDescription textAlign='left'>{singleThemeData.brief_description}</CardDescription>
                </Box>
            </Box>
        </CFRACard.Card>
    );
}
