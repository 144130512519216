import { Dispatch, createContext, useMemo, useState } from 'react';

type AnalyticsTickerSearchContextProps = {
    tickerSearchActionProps?: AnalyticsActionProps;
    setTickerSearchActionProps: Dispatch<AnalyticsActionProps | undefined>;
};

export const AnalyticsTickerSearchContext = createContext<AnalyticsTickerSearchContextProps>(
    {} as AnalyticsTickerSearchContextProps,
);

export function AnalyticsTickerSearchContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [tickerSearchActionProps, setTickerSearchActionProps] = useState<AnalyticsActionProps | undefined>(undefined);

    return (
        <AnalyticsTickerSearchContext.Provider value={{ tickerSearchActionProps, setTickerSearchActionProps }}>
            {memorizedChildren}
        </AnalyticsTickerSearchContext.Provider>
    );
}
