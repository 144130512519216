import { ScreenerAnalyticsServiceContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import { AnalyticsTickerSearchContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import { CustomViewEditorContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { ScreenerViewContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { WatchListContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { CustomViewEditorModal } from './CustomViewEditorModals';
import { FiltersModal } from './FiltersModal';
import { SaveScreenModal } from './SaveScreenModal';
import { ScreenerCard } from './ScreenerCard';

export const etfScreenerCardName = 'ETF Screener';

export function ScreenerHome() {
    return (
        <SaveScreenContextProvider>
            <WatchListContextProvider>
                <CustomViewEditorContextProvider>
                    <ScreenerViewContextProvider>
                        <FiltersModalContextProvider>
                            <ResultsContextProvider>
                                <ScreenerAnalyticsServiceContextProvider>
                                    <AnalyticsTickerSearchContextProvider>
                                        <ScreenerCard cardName={etfScreenerCardName} />
                                    </AnalyticsTickerSearchContextProvider>
                                    <FiltersModal etfScreenerCardName={etfScreenerCardName} />
                                    <SaveScreenModal />
                                </ScreenerAnalyticsServiceContextProvider>
                            </ResultsContextProvider>
                        </FiltersModalContextProvider>
                        <CustomViewEditorModal />
                    </ScreenerViewContextProvider>
                </CustomViewEditorContextProvider>
            </WatchListContextProvider>
        </SaveScreenContextProvider>
    );
}
