export enum AppRoutePaths {
    Home = '*',
    EtfDetails = '/etf',
    MarketTrends = '/market-trends',
    Screener = '/screener',
    CfraInsights = '/collections',
}

export enum PageNames {
    Home = 'Home',
    MarketTrends = 'Market Trends',
    Screener = 'Screener',
    CfraInsights = 'Discover',
}
