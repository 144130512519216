import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { ThemeResponse } from 'features/cfraInsights/types/thematic';
import { UseData } from 'utils';

export function getAllThematicThemes({ type = 'etf_thematic' }: SearchByParams) {
    return UseData<ThemeResponse>({
        requestQuery: getRequestQuery({ type }, 'screener-themes'),
        queryKey: ['getAllThemes', type],
        apiName: ApiNames.OpenSearch,
        requestType: RequestTypes.GET,
    });
}
