import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Divider, Tabs as MUITabs, SxProps, Tab, ThemeProvider, createTheme } from '@mui/material';

const tabsTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                scrollButtons: {
                    '&.Mui-disabled': {
                        width: 0,
                        opacity: 0,
                    },
                },
                indicator: {
                    backgroundColor: 'transparent',
                    webkitTransition: 'none',
                    transition: 'none',
                    '& > span': {
                        height: '2px',
                        display: 'flex',
                        backgroundColor: '#3078B5',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    flexDirection: 'row-reverse',
                    alignItems: 'end',
                    height: 50,
                    minHeight: 50,
                    color: '#0B2958',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: 15,
                    textTransform: 'none',
                    padding: '13px 0px',
                    marginLeft: 10,
                    marginRight: 10,
                    '&:first-of-type': {
                        marginLeft: 0,
                    },
                    '&:last-child': {
                        marginRight: 0,
                    },
                    '&.Mui-selected': {
                        color: '#3078B5',
                    },
                    '&[role="divider"]': {
                        margin: 0,
                    },
                    minWidth: 10,
                    '>.MuiTab-iconWrapper': {
                        fill: '#0B2958',
                        height: '100%',
                        marginBottom: '0px',
                        paddingLeft: '10px',
                        width: '14px',
                        visibility: 'hidden',
                        '&:hover': {
                            fill: 'rgba(48, 120, 181, 1)',
                        },
                    },
                    '&:hover': {
                        '>.MuiTab-iconWrapper': {
                            visibility: 'visible',
                        },
                        color: '#3078B5',
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: '2px',
                    height: '27px',
                    backgroundColor: '#EDF0F6',
                },
            },
        },
    },
});

export function Tabs(props: {
    activeTab: number | false;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
    tabItems: Array<string>;
    tabWithRightDividerIndex?: number;
    ariaLabel: string;
    sx?: SxProps;
    getTabIcon?: (tabName: string, index: number) => React.ReactElement | undefined;
    indicatorWidthAfterDividerTab?: string;
}) {
    const children: Array<React.ReactNode> = [];
    props.tabItems.forEach((tabName, index) => {
        children.push(<Tab key={index} label={tabName} value={index} icon={props.getTabIcon?.(tabName, index)} />);
        if (props.tabWithRightDividerIndex && index === props.tabWithRightDividerIndex) {
            children.push(<Tab key={'divider'} label='' icon={<Divider />} disabled role={'divider'} />);
        }
    });

    return (
        <ThemeProvider theme={tabsTheme}>
            <MUITabs
                aria-label={props.ariaLabel}
                variant='scrollable'
                allowScrollButtonsMobile
                value={props.activeTab}
                onChange={props.onChange}
                sx={props.sx}
                TabIndicatorProps={{
                    children: (
                        <span
                            style={{
                                width:
                                    props.activeTab &&
                                    props.tabWithRightDividerIndex &&
                                    props.activeTab > props.tabWithRightDividerIndex
                                        ? props.indicatorWidthAfterDividerTab
                                        : undefined,
                            }}
                        />
                    ),
                }}>
                {children}
            </MUITabs>
        </ThemeProvider>
    );
}
