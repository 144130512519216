import { OverridableComponent } from '@mui/material/OverridableComponent';
import SvgIcon, { SvgIconTypeMap } from '@mui/material/SvgIcon';

export function CFRAMuiIconWrapper({
    MuiIcon,
}: {
    MuiIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
}) {
    return (
        <SvgIcon viewBox='0 0 40 40' style={{ color: '#3078B5', fontSize: '15px' }}>
            <MuiIcon />
        </SvgIcon>
    );
}
