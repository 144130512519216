export function convertToUrlFormat(pageTitle: string) {
    return pageTitle.toLocaleLowerCase().replaceAll(' ', '-');
}

export function replaceAllDotsWithTabs(value: string) {
    return value.replaceAll('.', '\t');
}

export function replaceAllTabsWithDots(value: string) {
    return value.replaceAll('\t', '.');
}

export function getDividerString(dividerName: string, wrapperString: string = '___') {
    return `${wrapperString}${dividerName}${wrapperString}`;
}

export function quote(text: string): string {
    return `"${text}"`;
}

export function joinWithHtmlBr(errors: Array<string>): string {
    return errors.join('<br>');
}
