import { Modal } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import SaveModalForm, { SaveModalFormProps } from './SaveModalForm';

export function SaveModal(props: {
    title?: string;
    value?: string;
    openModal: boolean;
    callbackOnClose: () => void;
    saveType: SaveModalFormProps['saveType'];
    onPositiveAction: (data: string) => void;
    existingNames: Array<string>;
    positiveBtnText?: string;
    onIsDirtyChange?: (v: boolean) => void;
}) {
    return (
        <Modal
            title={props.title || `Save ${props.saveType}`}
            openModal={props.openModal}
            callbackOnClose={props.callbackOnClose}
            modalBoxStyles={{
                padding: '32px 30px',
                maxWidth: '480px',
                height: 'unset',
            }}
            closeButtonFontSize={20}
            titleAndDescriptionContainerStyle={{
                padding: '0px',
                fontWeight: 500,
            }}>
            {props.openModal && (
                <SaveModalForm
                    saveType={props.saveType}
                    onPositiveAction={props.onPositiveAction}
                    positiveBtnText={props.positiveBtnText || 'Save'}
                    existingNames={props.existingNames}
                    value={props.value}
                    onIsDirtyChange={props.onIsDirtyChange}
                />
            )}
        </Modal>
    );
}
