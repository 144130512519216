import {
    DataDisplay,
    ETFCard,
    ETFLinkButton,
    ETFTwoColumnGrid,
    RoundedTextButton,
} from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV1 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    DataPointFieldNameToFilterKey,
    DataPointFieldNameToLinkValue,
    DataPointsDisplayNames,
    FormatValueParams,
    assetClasses,
    formatValue,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNameToFormattingType,
    getDataPointsDisplayNames,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Grid, createTheme } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { EtfDetailsData } from '../types/research';
import { generateETFScreenerLink } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';


const CustomLinkButton = React.forwardRef(({ screenerLink, formatedValue }: {
    screenerLink: string,
    formatedValue: string
}, ref) => {
    return (
        <ETFLinkButton href={screenerLink} target='_blank' text={formatedValue}
            sx={{
                justifyContent: 'start',
                wordWrap: 'break-word',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
            }} />
    )
});

export default function Overview({ etfDetailsData }: { etfDetailsData: EtfDetailsData }) {
    const [isPrintView] = useState(!!window.matchMedia?.('print')?.matches);
    const [isExpanded, setExpanded] = useState(isPrintView);

    const theme = createTheme(tooltipTheme, {
        components: {
            MuiTooltip: {
                defaultProps: {
                    placement: 'top-start',
                },
            },
        },
    });

    // get display names list for all data points
    const DataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName();
    // create list of data points divided by sections
    const orderedDataPoints: DataPointsDisplayNames[] = [
        DataPointsDisplayNames.AssetClass,
        DataPointsDisplayNames.CFRACategoryOne,
        DataPointsDisplayNames.CFRACategoryTwo,
        DataPointsDisplayNames.AssetType,
        DataPointsDisplayNames.MultiAssetType,
        DataPointsDisplayNames.CommodityType,
        DataPointsDisplayNames.CurrencyPair,
        DataPointsDisplayNames.BondType,
        DataPointsDisplayNames.MarketCapRange,
        DataPointsDisplayNames.GrowthValueTilt,
        DataPointsDisplayNames.IndexWeightedScheme,
        DataPointsDisplayNames.SmartTraditionalBeta,
        DataPointsDisplayNames.CreditQuality,
        DataPointsDisplayNames.AverageMaturity,
        DataPointsDisplayNames.RegionOfExposure,
        DataPointsDisplayNames.RegionOfListing,
        DataPointsDisplayNames.Exchange,
        DataPointsDisplayNames.NumberOfHoldings,
        DataPointsDisplayNames.ExpenseRatio,
        DataPointsDisplayNames.InceptionDate,
        DataPointsDisplayNames.LegalStructure,
        DataPointsDisplayNames.Sponsor,
        DataPointsDisplayNames.FundFamily,
        DataPointsDisplayNames.UnderlyingIndex,
        DataPointsDisplayNames.ETFWebsite,
    ];
    // create matching between asset class and list of data point for it
    const assetClassToDataPoint: { [id: string]: Array<string> } = {
        [assetClasses.Bonds]: [
            DataPointsDisplayNames.BondType,
            DataPointsDisplayNames.CreditQuality,
            DataPointsDisplayNames.AverageMaturity,
        ],
        [assetClasses.EquitiesStocks]: [DataPointsDisplayNames.MarketCapRange, DataPointsDisplayNames.GrowthValueTilt],
        [assetClasses.TargetDateMultiAsset]: [DataPointsDisplayNames.MultiAssetType],
        [assetClasses.CommoditiesAndMetals]: [DataPointsDisplayNames.CommodityType],
        [assetClasses.Currency]: [DataPointsDisplayNames.CurrencyPair],
        [assetClasses.OtherAssetTypes]: [DataPointsDisplayNames.AssetType],
    };
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create a list of data points that have specification for the exact asset class
    let spesificDataPonts: Array<string> = [];
    Object.keys(assetClassToDataPoint).forEach(function (key) {
        spesificDataPonts = spesificDataPonts.concat(assetClassToDataPoint[key]);
    });
    // create instance of class FormatValueParams
    let formatValueParams = new FormatValueParams({
        source: etfDetailsData,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });

    // get formatted value for asset class
    const assetClass = formatValue(formatValueParams.create({ key: DataPointsDisplayNames.AssetClass }));

    // fill renderedItemValue with each column item
    const renderedItemValue: React.ReactNode[] = [];
    orderedDataPoints.forEach((itemName, index) => {
        const StyledItem = styled(ETFTwoColumnGrid.Item)(() => ({
            marginTop: index === 0 ? '0px' : '',
        }));

        // don't show items if they not applicable to this asset class
        if (
            assetClassToDataPoint.hasOwnProperty(assetClass) &&
            !assetClassToDataPoint[assetClass].includes(itemName) &&
            spesificDataPonts.includes(itemName)
        )
            return;

        if (renderedItemValue.length >= 10 * 2 && !isExpanded) return; // show only 10 data items if card is semi-colapsed

        // add data point name
        renderedItemValue.push(
            <DataDisplay.Tooltip title={itemName}>
                <StyledItem>{itemName}</StyledItem>
            </DataDisplay.Tooltip>,
        );

        // get formatted data point value
        let formatedValue = formatValue(formatValueParams.create({ key: itemName }));
        // for ETFWebsite data point add button
        if (itemName === DataPointsDisplayNames.ETFWebsite && formatedValue !== '-') {
            renderedItemValue.push(
                <Grid sx={{ verticalAlign: 'bottom' }}>
                    <ETFLinkButton href={formatedValue.toString()} target='_blank' text='Click here' />
                </Grid>,
            );
        } else {
            // for other data points add data point value
            const linkField = DataPointFieldNameToLinkValue[itemName];
            let screenerLink;
            if (linkField) {
                screenerLink = generateETFScreenerLink([{
                    filterName: DataPointFieldNameToFilterKey[itemName],
                    filterValue: (etfDetailsData as any)[linkField]
                }]);
            }
            renderedItemValue.push(
                <DataDisplay.Tooltip title={formatedValue}>
                    {screenerLink ?
                        <div>
                            <CustomLinkButton
                                screenerLink={screenerLink}
                                formatedValue={formatedValue.toString()} />
                        </div> :
                        <StyledItem>{formatedValue}</StyledItem>}
                </DataDisplay.Tooltip>
            );
        }
    });

    const getButtonText = () => (isExpanded ? 'View Less' : 'View More');
    const cardName = 'Overview';

    return (
        <ThemeProvider theme={theme}>
            <ETFCard.ETFCard cardLabel={cardName}>
                <ETFTwoColumnGrid.ETFTwoColumnGrid>
                    {React.Children.toArray(renderedItemValue)}
                </ETFTwoColumnGrid.ETFTwoColumnGrid>
                <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center', paddingTop: '28px', height: '68px' }}>
                    {!isPrintView && (
                        <RoundedTextButton
                            theme={roundedTextButtonThemeV1}
                            buttonText={getButtonText()}
                            onClickCallback={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: `click on ${getButtonText().toLowerCase()}`,
                                    cardName: cardName,
                                });
                                setExpanded(!isExpanded);
                            }}
                        />
                    )}
                </Grid>
            </ETFCard.ETFCard>
        </ThemeProvider>
    );
}
