import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Box } from '@mui/material';
import { Description, LabelStyle } from 'features/cfraInsights/components/shared';
import { CFRAInsightsHomeProps } from 'features/cfraInsights/types/thematic';
import { Grid } from './Grid';

const horizontalCardPadding = 20;

export const CFRA_INSIGHTS_CARD_NAME = 'CFRA ETF Collections';

export function CFRAInsightsHome(props: CFRAInsightsHomeProps) {
    const descriptionText =
        "Explore a dynamic realm of investment opportunities through our curated ETF collections. Engage with the forefront of investor and issuer interests, featuring selections that encompass both current market dynamics and long-term structural trends. Each collection is carefully crafted by CFRA's expert analysts, integrating a diverse array of ETFs across different types and categories to highlight a cohesive theme. Each collection serves as an expansive introductory platform for exploring various categories and trends, rather than constituting a compilation of specific ETF endorsements.";

    return (
        <ETFCard
            cardLabel={CFRA_INSIGHTS_CARD_NAME}
            labelStyle={{
                ...LabelStyle,
                textAlign: 'center',
                paddingTop: '16px',
            }}
            containerStyles={{
                padding: `37px ${horizontalCardPadding}px 49px ${horizontalCardPadding}px`,
                justifyContent: 'center',
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Description
                    sx={{ textAlign: 'center', paddingBottom: '36px', maxWidth: '1100px' }}
                    dangerouslySetInnerHTML={{ __html: descriptionText }}></Description>
            </Box>
            <Grid horizontalCardPadding={horizontalCardPadding} {...props} />
        </ETFCard>
    );
}
