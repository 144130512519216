import { Layout } from '@cfra-nextgen-frontend/shared';
import { Breadcrumbs } from '@cfra-nextgen-frontend/shared/src/components/Breadcrumbs';
import { PageWithBreadcrumbsInHeaderContext } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeaderContext';
import { ETFPageContainer } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFPageContainer';
import { Box, SxProps } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { invert } from 'lodash';
import React, { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export function ContentWrapper(props: {
    paddingTop?: number;
    paddingBottom?: number;
    children: React.ReactElement;
    outerContainerStyles?: SxProps;
    innerContainerPaddingBottom?: number;
}) {
    const { children, outerContainerStyles, innerContainerPaddingBottom, ...rest } = props;
    return (
        <ETFPageContainer containerStyles={outerContainerStyles}>
            <Layout.ETFTabContainer
                {...rest}
                paddingBottom={innerContainerPaddingBottom}
                sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'center',
                }}>
                {children}
            </Layout.ETFTabContainer>
        </ETFPageContainer>
    );
}

export function PageWithBreadcrumbsInHeader({
    pathMapping,
    includeHeader = true,
    contentOuterContainerStyles,
    contentInnerContainerPaddingBottom,
}: {
    pathMapping: Record<string, string>;
    includeHeader?: boolean;
    contentOuterContainerStyles?: SxProps;
    contentInnerContainerPaddingBottom?: number;
}) {
    pathMapping = { Home: '', ...pathMapping };
    const pathNamesMapping = invert(pathMapping);
    const pathNames = useLocation().pathname.split('/');
    const { rightSideSlotContent } = useContext(PageWithBreadcrumbsInHeaderContext);
    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            <Layout.ETFHeaderContainer
                shadow
                sx={{
                    zIndex: 1100,
                    position: isScreenDisplay ? 'sticky' : 'static',
                    top: '72px',
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Breadcrumbs
                            pathMapping={pathMapping}
                            analyticsCallback={(value: string) =>
                                globalThis.analytics?.registerAction?.({
                                    action: `navigation breadcrumbs : ${pathNamesMapping[value].toLocaleLowerCase()}`,
                                })
                            }
                        />
                        <Layout.HeaderName>
                            {includeHeader ? (
                                pathNamesMapping[pathNames[pathNames.length - 1]]
                            ) : (
                                <div style={{ height: '26px' }}></div>
                            )}
                        </Layout.HeaderName>
                    </Box>
                    {rightSideSlotContent && <Box>{rightSideSlotContent}</Box>}
                </Box>
            </Layout.ETFHeaderContainer>
            <ContentWrapper
                outerContainerStyles={contentOuterContainerStyles}
                innerContainerPaddingBottom={contentInnerContainerPaddingBottom}>
                <Outlet />
            </ContentWrapper>
        </>
    );
}
