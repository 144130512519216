import { textFieldTheme } from '@cfra-nextgen-frontend/shared/src/components/Form/FormControlledTextField';
import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { appTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { getDayjsUtcDate } from '@cfra-nextgen-frontend/shared/src/utils/time';
import { ThemeProvider } from '@emotion/react';
import { SxProps, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { inputBaseStyles } from './FormTextField';

const theme = createTheme(appTheme, {
    components: {
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    display: 'flex',
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    zIndex: 3000,
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    overflow: 'visible !important',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minWidth: '125px',
                    maxWidth: '275px',
                },
                input: {
                    ...inputBaseStyles,
                    ...inputFontStyle,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-focused)': {
                        top: '-10px', // center inactive label
                    },
                    ...inputFontStyle,
                },
                shrink: {
                    top: '1px !important',
                },
            },
        },
        MuiFormHelperText: {
            ...textFieldTheme?.components?.MuiFormHelperText,
        },
    },
});

type FormDatePickerProps = {
    validationRules?: any;
    fieldDefaultStyles?: SxProps;
} & CommonFormComponentProps;

export function FormDatePicker(props: FormDatePickerProps) {
    const [isError, setIsError] = useState<boolean>(false);
    const isBelow400pxMediaQuery = '(max-width:400px)';
    const isBelow400px = useMediaQuery(isBelow400pxMediaQuery);
    const defaultValue = props.getValues?.(props.name) || null;
    const shortcutsItems: PickersShortcutsItem<null>[] = [{ label: 'Reset', getValue: () => null }];

    return (
        <Controller
            rules={props.validationRules}
            name={props.name}
            defaultValue={defaultValue}
            control={props.control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                sx={props?.fieldDefaultStyles}
                                timezone='UTC'
                                label='Select date'
                                onChange={(event, validation) => {
                                    field.onChange(event);
                                    const _isError = Boolean(validation.validationError);
                                    if (isError !== _isError) {
                                        setIsError(_isError);
                                    }
                                    props.submitHandler?.();
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: Boolean(error?.message) || isError,
                                        helperText: error?.message || '',
                                        onBlur: (_) => {
                                            props.submitHandler?.();
                                        },
                                    },
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                    actionBar: { actions: [] },
                                }}
                                // in case react hook form state already contains string value of date we need to convert it to dayjs
                                value={getDayjsUtcDate(field.value)}
                                disableOpenPicker={isBelow400px}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                );
            }}
        />
    );
}
