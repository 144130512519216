import { ETFCard, NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerFilterNoResults } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal//etfScreenerFilterSearch/ScreenerFilterNoResults';
import { ScreenerFilterSearch } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal//etfScreenerFilterSearch/ScreenerFilterSearch';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerFiltersChipPanel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { getFiltersReactNodes } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/getFiltersReactNodes';
import {
    RhFormData,
    formatQuerystringDataToDirtyData,
    formatSavedFilterToDirtyData,
    getDirtyData,
    getPostAndChipsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getFiltersData } from '../api/filters';

type FiltersFormProps = {
    analyticsCardName: string;
    isFormOpen: boolean;
};

export function FiltersForm({ analyticsCardName, isFormOpen }: FiltersFormProps) {
    const { setFiltersPostData, setFiltersMetadata } = useContext(FiltersModalContext);
    const filtersDataUseQueryResult = getFiltersData({});
    const [filtersData, setFiltersData] = useState<FiltersData | undefined>(undefined);
    const [submittingData, setSubmittingData] = useState<{ [key: string]: any } | null>(null);
    const [labelTextToSearch, setLabelTextToSearch] = useState('');
    const [hasSearchMatch, setHasSearchMatch] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const {
        chipStateManager: { chipState, chipStateDispatcher },
    } = useContext(ResultsContext);
    const {
        saveScreenState: { selectedScreenValue },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);

    // keep the comment here to avoid the hard to detect issue in the feature: if import isValid property from formState, it causes 900+ unnecessary validation triggers, it influence on performance
    const {
        control,
        formState: { dirtyFields, errors, isValidating },
        getValues,
        setValue,
        handleSubmit,
        trigger,
        resetField,
        reset,
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const validate: (fieldName: string) => Promise<boolean | undefined> = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    useEffect(() => {
        if (labelTextToSearch.length > 0 && !isFormOpen) {
            setLabelTextToSearch('');
        }
    }, [isFormOpen, labelTextToSearch]);

    useEffect(() => {
        if (filtersDataUseQueryResult.data) {
            setFiltersMetadata(filtersDataUseQueryResult.data);
        }
    }, [filtersDataUseQueryResult, setFiltersMetadata]);

    useEffect(() => {
        if (chipState.action === 'Clear') {
            reset();
            setFiltersPostData(undefined);
            chipStateDispatcher({ type: 'ClearAction' });
        }
    }, [reset, setFiltersPostData, chipState.action, chipStateDispatcher]);

    useEffect(() => {
        if (chipState.action === 'Dirty' && filtersData) {
            let dirtyData = chipState.field.dirtyData;
            let controlID = chipState.field.controlID;

            if (dirtyData[controlID] === undefined) {
                resetField(controlID);
            } else {
                setValue(controlID, dirtyData[controlID]);
            }

            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        }
    }, [resetField, setFiltersPostData, filtersData, setValue, chipState.field, chipStateDispatcher, chipState.action]);

    useEffect(() => {
        //Default filter on screener when passed as querystring
        const allFilters = searchParams.get('filters')?.split('||');
        if (filtersData && allFilters && allFilters.length > 0) {
            let dirtyData: any = {};
            allFilters.forEach((filter) => {
                const [filterKey, filterValue] = filter.split('|');
                dirtyData = {
                    ...dirtyData,
                    ...formatQuerystringDataToDirtyData(filterKey, filterValue, filtersData),
                };
            });
            Object.keys(dirtyData).forEach((controlID) => {
                setValue(controlID, dirtyData[controlID], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                });
            });
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        }
    }, [filtersData, searchParams, chipStateDispatcher, setFiltersPostData, setValue]);

    useEffect(() => {
        if (filtersData && selectedScreenValue?.filters) {
            const { dirtyData, mismatchedSavedData } = formatSavedFilterToDirtyData(
                selectedScreenValue.filters,
                filtersData,
            );

            Object.keys(dirtyData).forEach((controlID) => {
                setValue(controlID, mismatchedSavedData[controlID] || dirtyData[controlID], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                });
            });
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
            saveScreenActionDispatcher({ type: 'SetSelectedScreenValue', payload: undefined });
        }
    }, [
        selectedScreenValue,
        chipStateDispatcher,
        setFiltersPostData,
        saveScreenActionDispatcher,
        setValue,
        filtersData,
        chipState.action,
    ]);

    useEffect(() => {
        const onSubmit = (data: RhFormData) => {
            const dirtyData = getDirtyData(dirtyFields, data);

            if (!filtersData) {
                return;
            }
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        };

        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [isValidating, errors, submittingData, dirtyFields, filtersData, setFiltersPostData, chipStateDispatcher]);

    const submitHandler: () => void = useCallback(
        () => handleSubmit((data) => setSubmittingData(data))(),
        [handleSubmit],
    );

    const reactNodes: Array<React.ReactNode> | null = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        setHasSearchMatch(false);

        return getFiltersReactNodes({
            filtersData: filtersData,
            control: control,
            analyticsCardName: analyticsCardName,
            getValues: getValues,
            validate: validate,
            submitHandler: submitHandler,
            setValue: setValue,
            labelTextToSearchRegEx: labelTextToSearch ? new RegExp(`${labelTextToSearch}`, 'i') : undefined,
            setHasSearchMatch,
        });
    }, [
        analyticsCardName,
        control,
        filtersData,
        getValues,
        validate,
        submitHandler,
        setValue,
        labelTextToSearch,
        setHasSearchMatch,
    ]);

    if (!filtersData && filtersDataUseQueryResult.isLoading) {
        return <ETFCard isLoading={filtersDataUseQueryResult.isLoading} loadingContainerStyles={{ margin: '0px' }} />;
    }

    if (
        !filtersDataUseQueryResult.isLoading &&
        !(
            filtersDataUseQueryResult.data &&
            filtersDataUseQueryResult.data.filter_metadata &&
            filtersDataUseQueryResult.data.section_mapping
        )
    ) {
        return <NoInformationAvailable />;
    }

    if (!filtersData) {
        setFiltersData(filtersDataUseQueryResult.data);
        return <></>;
    }

    return (
        <Grid
            className={labelTextToSearch.length > 0 ? 'screener-filter-applied' : ''}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <ScreenerFilterSearch
                key='FilterSearch'
                searchTerm={labelTextToSearch}
                setSearchTerm={setLabelTextToSearch}
            />
            {labelTextToSearch.length === 0 && <ScreenerFiltersChipPanel key='ScreenerFilterChips' />}
            {!hasSearchMatch && labelTextToSearch.length > 0 && <ScreenerFilterNoResults key='NoResults' />}
            <form
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: labelTextToSearch.length > 0 ? 'auto' : 'hidden',
                }}>
                {reactNodes}
            </form>
        </Grid>
    );
}
