import { CFRAChips, MaterialIcons } from '@cfra-nextgen-frontend/shared/';
import { chipItem } from '@cfra-nextgen-frontend/shared/src/components/CFRAChip/CFRAChipStack';
import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { FiltersModalContext } from './FiltersModalContext';
import { ResultsContext } from './ResultsContext';
import { Label, MainLabel, ScreenerChipTheme, TransparentButtonChip } from './ResultsPanelRowStyle';
import { ChipItem, OnChipDeleteClick } from './types';

export const getResultsCount = (count: number | undefined): any => {
    if (count === -1) {
        return 'Loading...';
    }
    return (count ? count.toString() : 'No') + ' results found';
};

const getChips = (onChipDeleteClick: OnChipDeleteClick, chips?: Record<string, ChipItem> | undefined): JSX.Element => {
    let chipItems = Array<chipItem>();

    if (chips) {
        chipItems = Object.entries(chips).map(([key, chipValue]) => {
            let label =
                (chipValue.chip.additionalLabels || '') +
                chipValue.chip.label +
                (chipValue.chip.values ? ': ' + chipValue.chip.values : '');

            return {
                chipKey: key,
                chipLabel: label,

                chipProps: {
                    title: label,
                    deleteIcon: <MaterialIcons.Clear style={{ fontSize: '13px', color: '#0B2958' }} />,
                    onDelete: onChipDeleteClick(chips, key),
                    onClick: () => {
                        //setFocusByControlID(chipValue.componentID);
                    },
                },
            };
        });

        return <CFRAChips key='AllChips' chipItems={chipItems} />;
    } else {
        return <></>;
    }
};

const getClearChip = (clearAll: () => void) => {
    return (
        <TransparentButtonChip
            key='Clear All'
            text='Clear'
            sx={{ margin: '4px 8px', padding: '4px 5px' }}
            startIcon={<MaterialIcons.Autorenew style={{ fontSize: '18px', color: '#3078B5' }} />}
            onClick={clearAll}
        />
    );
};

const ChipComponentList = (props: { chipItems: Record<string, ChipItem>; onChipDeleteClick: OnChipDeleteClick }) => {
    const { setOpenFiltersModal, setActiveIndex } = useContext(FiltersModalContext);

    const { chipItems, onChipDeleteClick } = props;

    if (Object.keys(chipItems).length === 0) {
        return null;
    }

    function handleOnChipClick(filterKey: string) {
        const activeIndexByKey: { [key: number]: any } = {};
        const { filterSections } = chipItems[filterKey].stateData;

        Object.keys(filterSections).forEach((key) => {
            const { orderIndex, parent = 'root' } = filterSections[key];
            activeIndexByKey[parent] = orderIndex;
        });

        setActiveIndex({ ...activeIndexByKey });
        setOpenFiltersModal(true);
    }

    const chipList = Object.entries(chipItems).map(([key, chipValue]) => {
        const { label, additionalLabels, values } = chipValue.chip || {};
        let chipLabel = `${additionalLabels || ''}${label}${values && `: ${values}`}`;

        return {
            chipKey: key,
            chipLabel,
            chipProps: {
                title: chipLabel,
                deleteIcon: <MaterialIcons.Clear style={{ fontSize: '13px', color: '#0B2958' }} />,
                onDelete: onChipDeleteClick(chipItems, key),
                onClick: () => handleOnChipClick(key),
            },
        };
    });

    return (
        <ThemeProvider key='ChipsTheme' theme={ScreenerChipTheme}>
            <CFRAChips key='AllChips' chipItems={chipList} />
        </ThemeProvider>
    );
};

export function ScreenerFiltersChipPanel() {
    const {
        chipStateManager: {
            chipState: { chipItems, resultCount },
        },
        chipEventsManager: { onChipClearAllClick, onChipDeleteClick },
    } = useContext(ResultsContext);

    return (
        <Grid container sx={{ paddingLeft: '29px', paddingRight: '15px', maxHeight: '30%' }}>
            <Grid
                item
                sx={{
                    width: '135px',
                }}>
                <Label>{getResultsCount(resultCount)}</Label>
            </Grid>
            <Grid
                item
                sx={{
                    width: 'calc(100% - 135px)',
                    maxHeight: '100%',
                    overflow: 'auto',
                    ...scrollbarThemeV2,
                }}>
                <Stack direction='row' flexWrap='wrap' display='flex' maxWidth={'100%'} alignItems='center'>
                    {chipItems && Object.keys(chipItems).length > 0 && (
                        <>
                            <ChipComponentList chipItems={chipItems} onChipDeleteClick={onChipDeleteClick} />
                            <Box display='flex' sx={{ ml: 'auto' }}>
                                {getClearChip(onChipClearAllClick)}
                            </Box>
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}

export function ScreenerResultsCountPanel() {
    const {
        chipStateManager: {
            chipState: { resultCount },
        },
    } = useContext(ResultsContext);

    return <MainLabel>{getResultsCount(resultCount)}</MainLabel>;
}

export function ScreenerResultsChipComponents() {
    const {
        chipStateManager: {
            chipState: { chipItems },
        },
        chipEventsManager: { onChipDeleteClick, onChipClearAllClick },
    } = useContext(ResultsContext);

    if (!chipItems) {
        return {
            chipList: [],
            clearButton: <></>,
        };
    }

    return {
        chipList: <ChipComponentList chipItems={chipItems} onChipDeleteClick={onChipDeleteClick} />,
        clearButton: getClearChip(onChipClearAllClick),
    };
}
