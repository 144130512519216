import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

export interface ScrollingStateProps {
    goingUp: boolean;
    setGoingUp: React.Dispatch<React.SetStateAction<boolean>>;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const options = {
    duration: 400,
    delay: 100,
    smooth: true,
};

export const animateScrollTo = (y: number) => {
    animateScroll.scrollTo(y, options);
};

export const animateScrollToTop = () => {
    animateScroll.scrollToTop(options);
};

const ScrollingContext = createContext<ScrollingStateProps>({} as ScrollingStateProps);

interface Props {
    children: React.ReactNode;
}

const ScrollingContextProvider: React.FC<Props> = ({ children }) => {
    const memorizedChildren = useMemo(() => children, [children]);
    const [goingUp, setGoingUp] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (goingUp !== false) {
            setGoingUp(false);
        }
        if (activeTab !== 0) {
            setActiveTab(0);
        }
    }, [location, activeTab, goingUp]);

    return (
        <ScrollingContext.Provider value={{ goingUp, setGoingUp, activeTab, setActiveTab }}>
            {memorizedChildren}
        </ScrollingContext.Provider>
    );
};

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export { ScrollToTop, ScrollingContext, ScrollingContextProvider };
