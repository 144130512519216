import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { Categories } from '@cfra-nextgen-frontend/shared/src/utils';
import { MarketTrend } from 'features/marketTrends/types/market';

export function getMinWidthForHeader(headerName: string): number {
    let resultMinWidth = 0;

    const dateRangesToMinWidth: Record<string, number> = {
        // includes paddings, and sort icon
        '1 Month': 135,
        '1 Year': 120,
        '3 Month': 135,
        YTD: 120,
    };

    const dateRange = Object.keys(dateRangesToMinWidth).find((dateRange) => headerName.indexOf(dateRange) > -1);

    if (dateRange) {
        resultMinWidth += dateRangesToMinWidth[dateRange];
        headerName = headerName.replace(dateRange, '');
    }

    const headerNameToMinWidth: Record<string, number> = {
        ' Flows ($)': 65,
        ' Flows to Asset': 65,
        ' Flows to Asset Ratio': 80,
        ' Performance': 70,
        ' Return': 25,
        'Asset Class': 240,
        'CFRA Category': 165,
        'CFRA Sub-category': 175,
        'ETF Name': 140,
        'Number of ETFs': 160,
        Sector: 220,
        Sponsor: 270,
        Ticker: 140,
        'Total Assets ($)': 195,
    };

    if (Object.keys(headerNameToMinWidth).includes(headerName)) {
        resultMinWidth += headerNameToMinWidth[headerName];
    }

    return resultMinWidth === 0 ? defaultMinWidth : resultMinWidth;
}

export function getNextLevelCategory(category: Categories) {
    const categoryIndex = Object.values(Categories).indexOf(category);
    if (categoryIndex === -1 || categoryIndex === Object.values(Categories).length - 1) {
        throw new Error(`Impossible to get next level category for - ${category}.`);
    }
    return Object.values(Categories)[categoryIndex + 1];
}

export function getAsOfDatePath(label: MarketTrend) {
    switch (label) {
        case MarketTrend.SectorPerformanceDetails:
        case MarketTrend.KeyAssetClassesDetails:
            return 'performance.as_of_date';
        default:
            return 'as_of_date';
    }
}

export function getAsOfDateMax(label: MarketTrend) {
    switch (label) {
        case MarketTrend.SectorPerformanceDetails:
        case MarketTrend.KeyAssetClassesDetails:
            return true;
        default:
            return false;
    }
}
