import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useState } from 'react';
import { getSavedItemsByType, updateSavedItemById } from '../api/userSavedItems';
import { SaveModal } from './SaveModal';

type RenameSavedItemModalProps = {
    id: number;
    name: string;
    savedItemType: UserSavedItemsLookupID;
    modalTitle?: string;
    confirmBtnText?: string;
    cancelBtnText?: string;
    onCancel: () => void;
    onConfirm: () => void;
};

const defaultModalTitle = 'Rename Saved Item';
const defaultConfirmBtnText = 'Confirm';
const defaultCancelBtnText = 'Cancel';

export function RenameSavedItemModal(props: RenameSavedItemModalProps) {
    const {
        id,
        name,
        savedItemType,
        modalTitle = defaultModalTitle,
        confirmBtnText = defaultConfirmBtnText,
        cancelBtnText = defaultCancelBtnText,
        onCancel = () => {},
        onConfirm = () => {},
    } = props;

    const [updatedName, setUpdatedName] = useState(undefined);
    const [wasFormDirty, setWasFormDirty] = useState(false);
    const [showExitConfirm, setShowExitConfirm] = useState(false);

    const analyticsCardName = `${modalTitle} close confirmation popup`;

    const updateSavedItemQry = updateSavedItemById({
        savedItems: id,
        requestBody: { name: updatedName },
        config: {
            enabled: updatedName !== undefined,
            cacheTime: 3000
        },
    });

    const isItemNameUpdated = Boolean(
        updatedName && updateSavedItemQry && !updateSavedItemQry.isLoading && updateSavedItemQry.data?.data?.id,
    );

    if (isItemNameUpdated) {
        onConfirm();
    }

    const getSavedItemsQuery = getSavedItemsByType({ types: [savedItemType] });

    const savedItemNameList = getSavedItemsQuery.data?.data?.map((item: { name: any }) => item?.name) || [];

    function handleOnModalClose() {
        if (wasFormDirty) {
            setShowExitConfirm(true);
        } else {
            onCancel();
        }
    }

    return (
        <>
            <SaveModal
                title={modalTitle}
                value={name}
                openModal={typeof id === 'number'}
                callbackOnClose={handleOnModalClose}
                saveType='Save screen'
                onPositiveAction={(data: any) => setUpdatedName(data)}
                existingNames={savedItemNameList}
                positiveBtnText='Save'
                onIsDirtyChange={(v) => v && setWasFormDirty(v)}
            />
            <ConfirmationModal
                openModal={showExitConfirm}
                modalText='Exit without saving?'
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelBtnText}`,
                        cardName: modalTitle,
                    });
                    setShowExitConfirm(false);
                }}
                cancelButtonText={cancelBtnText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmBtnText}`,
                        cardName: analyticsCardName,
                    });
                    setShowExitConfirm(false);
                    onCancel();
                }}
                confirmButtonText={confirmBtnText}
            />
        </>
    );
}
