import { Layout } from '@cfra-nextgen-frontend/shared';
import { ThematicDetailsLink } from 'features/cfraInsights/routes/CFRAInsightsRoutes';
import { ThemeData } from 'features/cfraInsights/types/thematic';
import { Card } from './Card';

type GridProps = {
    detailsPathMapping: Record<string, string>;
    horizontalCardPadding: number;
    themeData: ThemeData;
    pageName: string;
};

const columnSpacing = 6.25;
const maxGridWidth = 1324;

export function Grid({ detailsPathMapping, horizontalCardPadding, themeData, pageName }: GridProps) {
    return (
        <Layout.Grid
            item
            container
            columnSpacing={columnSpacing}
            rowSpacing={4.5}
            justifyContent='center'
            sx={{ maxWidth: `${maxGridWidth + 8 * columnSpacing}px` }}>
            {Object.entries(themeData).map(([key, value], index) => (
                <Layout.Grid key={index} item xs={'auto'} display='flex' justifyContent='center'>
                    <ThematicDetailsLink
                        pathName={value.display_name}
                        detailsPathMapping={detailsPathMapping}
                        analyticsCallback={() => {
                            globalThis.analytics?.registerAction?.({
                                action: `${pageName}: ${value.display_name?.toLowerCase()}`,
                                cardName: pageName,
                            });
                        }}>
                        <Card singleThemeData={value} horizontalCardPadding={horizontalCardPadding} />
                    </ThematicDetailsLink>
                </Layout.Grid>
            ))}
        </Layout.Grid>
    );
}
