import {
    SingleScreenerData,
    getFieldValueBySourceField,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { CustomValueFormatterTypes } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerData, ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import {
    formatNumberTo2DecimalPlaces,
    getMomentObjectFrom,
    getNumberWithCommas,
    getValuesByPath,
    removeInaccuracy,
    scaleCharacterToNumber,
    standardDateFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { ValueFormatterFunc } from 'ag-grid-community';
import moment from 'moment';
import { defaultValueTemplate, fillTemplate } from './templates';

export function applyScale({
    fieldMetadata,
    value,
}: {
    fieldMetadata: MetadataFieldDefinition;
    value: number;
}): number {
    if (fieldMetadata.scale) {
        if (!['h', 'k', 'm', 'b'].includes(fieldMetadata.scale.toLocaleLowerCase())) {
            throw new Error(
                `The scale for ${fieldMetadata.label} column contains value out of expected range - "${fieldMetadata.scale}".`,
            );
        }
        const isLowercaseScale = /^[a-z]+$/.test(fieldMetadata.scale);

        const coefficient =
            scaleCharacterToNumber[fieldMetadata.scale.toLocaleLowerCase() as keyof typeof scaleCharacterToNumber];

        if (coefficient) {
            return removeInaccuracy(isLowercaseScale ? value * coefficient : value / coefficient);
        }
    }
    return value;
}

function applyNumberFormat(fieldMetadata: MetadataFieldDefinition, value: number): string {
    const throwFormatError = () => {
        throw new Error(
            `The format for ${fieldMetadata.label} column contains value out of expected range - "${fieldMetadata.format}".`,
        );
    };

    if (!fieldMetadata.format) {
        throw new Error(`The format for ${fieldMetadata.label} is empty.`);
    }

    if (!fieldMetadata.format.includes('#') && !fieldMetadata.format.includes('.')) {
        throwFormatError();
    }

    let result;

    if (fieldMetadata.format.includes('#')) {
        result = String(Math.round(value));
    } else {
        let splittedFormat = fieldMetadata.format.split('.');
        if (splittedFormat.length < 2) {
            throwFormatError();
        }

        if (fieldMetadata.rounding_method === 'cut off') {
            result = formatNumberTo2DecimalPlaces(value, true);
        } else {
            result = parseFloat(String(value)).toFixed(splittedFormat[1].length);
        }
    }

    return result;
}

export function formatNumber({
    fieldMetadata,
    value,
    applyCommas = true,
    skipScale,
}: {
    fieldMetadata: MetadataFieldDefinition;
    value: number;
    applyCommas?: boolean;
    skipScale?: boolean;
}): string {
    if (!skipScale) {
        value = applyScale({ fieldMetadata, value });
    }

    let result = applyNumberFormat(fieldMetadata, value);

    if (applyCommas) {
        result = getNumberWithCommas(result);
    }

    return result;
}

function formatDate(fieldMetadata: MetadataFieldDefinition, value: string): string {
    return getMomentObjectFrom(value, fieldMetadata.input_format || standardDateFormat).format(fieldMetadata.format);
}

export const defaultNoResultsSymbol = '-';
export const defaultNoRatingText = 'Not Rated';

export function getScreenerValueFormatter(
    fieldMetadata: MetadataFieldDefinition,
    screenerData?: ScreenerEtfData | ScreenerData,
): ValueFormatterFunc {
    return function (params): string {
        if (
            fieldMetadata.value_formatter &&
            fieldMetadata.source_fields &&
            fieldMetadata.source_fields.length > 0 &&
            screenerData
        ) {
            switch (fieldMetadata.value_formatter) {
                case CustomValueFormatterTypes.IsUserEnabled:
                    return getBooleanStringValue(
                        isUserEnabledFormatter(fieldMetadata.source_fields!, {
                            rowData: params.data,
                            viewdata: screenerData._viewdata,
                            metadata: screenerData._metadata,
                        }),
                    );
                default:
                    return defaultNoResultsSymbol;
            }
        }

        if (!params.colDef.field) {
            return defaultNoResultsSymbol;
        }

        const rawValue =
            (getValuesByPath(params.data, params.colDef.field) as Array<string>)
                .filter((item) => Boolean(item))
                .sort()
                .join(', ') || undefined; // separate values by comma in the same cell

        return applyFieldMetadataToValue({ fieldMetadata, rawValue });
    };
}

export function getBooleanStringValue(value: any) {
    return value ? 'Yes' : 'No';
}

export function applyFieldMetadataToValue({
    fieldMetadata,
    rawValue,
    skipValueTemplate,
    skipScale,
}: {
    fieldMetadata: MetadataFieldDefinition;
    rawValue: any;
    skipValueTemplate?: boolean;
    skipScale?: boolean;
}) {
    let result = defaultNoResultsSymbol;

    if (fieldMetadata.type === 'boolean') {
        result = getBooleanStringValue(rawValue);
    } else if (rawValue === null || rawValue === undefined) {
        if (fieldMetadata.no_value_symbol) {
            return fieldMetadata.no_value_symbol;
        }
        return defaultNoResultsSymbol;
    }

    if (!['string', 'number', 'date', 'boolean'].includes(fieldMetadata.type)) {
        throw new Error(`Pointed invalid column metadata type - "${fieldMetadata.type}"`);
    }

    if (fieldMetadata.type === 'string') {
        result = String(rawValue);
    }

    if (fieldMetadata.type === 'number') {
        result = formatNumber({
            fieldMetadata: fieldMetadata,
            value: rawValue,
            skipScale: skipScale,
        });
    }

    if (fieldMetadata.type === 'date') {
        result = formatDate(fieldMetadata, rawValue);
    }

    if (skipValueTemplate) {
        return result;
    }

    const valueTemplate =
        !fieldMetadata.value_template && fieldMetadata.symbol === '%'
            ? defaultValueTemplate
            : fieldMetadata.value_template;
    if (valueTemplate) {
        result =
            fillTemplate({
                templateName: 'value_template',
                template: valueTemplate,
                dataObject: fieldMetadata,
                formattedValue: result,
            }) || result;
    }

    return result;
}

export const sliderApplyScaleToValue = ({
    value,
    isPercentageDataPoint,
    itemMetadata,
}: {
    value: number;
    isPercentageDataPoint: boolean;
    itemMetadata: MetadataFieldDefinition;
}) => {
    return applyScale({
        fieldMetadata: itemMetadata,
        value: isPercentageDataPoint ? Number(Math.ceil(value * 10000) / 10000) : Math.ceil(value),
    });
};

export function sliderUnApplyScaleFromNumericValue(
    fieldMetadata: MetadataFieldDefinition,
    valueWithAppliedFieldMetadata: string,
) {
    const parsedInputValue = parseFloat(valueWithAppliedFieldMetadata.replaceAll(',', ''));
    if (!fieldMetadata.scale) {
        return parsedInputValue;
    }
    const isLowerCaseScale = fieldMetadata.scale.toLocaleLowerCase() === fieldMetadata.scale;
    return applyScale({
        fieldMetadata: {
            ...fieldMetadata,
            scale: (isLowerCaseScale
                ? fieldMetadata.scale.toUpperCase()
                : fieldMetadata.scale.toLowerCase()) as typeof fieldMetadata.scale,
        },
        value: parsedInputValue,
    });
}

export function isUserEnabledFormatter(sourceFields: Array<string>, singleScreenerData: SingleScreenerData) {
    if (sourceFields.length !== 2) {
        throw new Error(`Missing source fields for ${CustomValueFormatterTypes.IsUserEnabled}`);
    }

    const fieldValues = sourceFields.map((sourceField) => {
        return getFieldValueBySourceField({ sourceField, ...singleScreenerData });
    });

    const [enabledDate, disabledDate] = fieldValues;

    const currentDateTime = moment.utc();
    const enableDate = moment.utc(enabledDate);
    const disableDate = moment.utc(disabledDate);

    const isEnabled =
        enableDate.isValid() &&
        enableDate.isBefore(currentDateTime) &&
        (!disableDate.isValid() || disableDate.isAfter(currentDateTime));

    return isEnabled;
}
