import { ETFDownloadButton, ETFMenu } from '@cfra-nextgen-frontend/shared';

export const getMenuItemName = (format: string) => {
    switch (format.toLocaleLowerCase()) {
        case 'csv':
        case 'xlsx':
            return 'Download Data';
        default:
            return `Download ${format}`;
    }
};

export function TableExportMenus({
    exportCallback,
    menuItemsProps,
    analyticsCallback,
    menuActionButton,
}: {
    exportCallback: () => void;
    menuItemsProps?: Map<string, () => void>;
    analyticsCallback?: (type: string) => void;
    menuActionButton?: React.ReactNode;
}) {
    const useOriginalItemsNames = Boolean(menuItemsProps);

    if (!menuItemsProps) {
        menuItemsProps = new Map<string, () => void>([['XLSX', exportCallback]]);
    }

    return ExportMenusBase({ menuItemsProps, useOriginalItemsNames, analyticsCallback, menuActionButton });
}

export const ExportMenusBase = ({
    menuItemsProps,
    useOriginalItemsNames,
    analyticsCallback,
    menuActionButton,
}: {
    menuItemsProps: Map<string, () => void>;
    useOriginalItemsNames?: boolean;
    analyticsCallback?: (type: string) => void;
    menuActionButton?: React.ReactNode;
}) => {
    const menuItems: Array<ETFMenu.MenuItemProps> = [];
    menuItemsProps.forEach((callback, item) => {
        menuItems.push({
            itemName: useOriginalItemsNames ? item : getMenuItemName(item),
            callback: () => {
                callback();
                analyticsCallback?.(item);
            },
        });
    });
    return (
        <ETFMenu.ETFMenu menuItems={menuItems}>
            {menuActionButton ? menuActionButton : <ETFDownloadButton />}
        </ETFMenu.ETFMenu>
    );
};
