import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import {
    getPostAndChipsData,
    getSaveFiltersFromDirtyData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { etfScreenerCardName } from 'features/etfScreener/components/ScreenerHome';
import { useContext, useEffect, useState } from 'react';
import { getFiltersData } from '../api/filters';
import { createSavedItem, getSavedItemsByType } from '../api/userSavedItems';
import { SaveModal } from './SaveModal';

export function SaveScreenModal() {
    const {
        saveScreenState: { openModal, saveScreenValue },
        saveScreenActionDispatcher,
    } = useContext(SaveScreenContext);
    const { filtersPostData } = useContext(FiltersModalContext);
    const {
        chipStateManager: {
            chipState: { filtersDirtyData },
        },
    } = useContext(ResultsContext);
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);
    const [wasFormDirty, setWasFormDirty] = useState(false);
    const [showExitConfirm, setShowExitConfirm] = useState(false);
    const [refetchScreenDataPending, setRefetchScreenDataPending] = useState(false);
    const [postRefetchActionsPending, setPostRefetchActionsPending] = useState(false);

    useEffect(() => {
        setWasFormDirty(false);
        setShowExitConfirm(false);
    }, [openModal]);

    const confirmButtonText = 'Confirm';
    const cancelButtonText = 'Cancel';
    const modalTitle = 'Save Screen';
    const analyticsCardName = `${modalTitle} close confirmation popup`;
    const filtersDataUseQueryResult = getFiltersData({});
    const saveScreenQuery = createSavedItem({
        requestBody: saveScreenValue,
        config: {
            enabled: saveScreenValue !== undefined,
        },
    });

    const getSavedItemsQuery = getSavedItemsByType({ types: [UserSavedItemsLookupID.ETFScreenerSavedScreen] });

    useEffect(() => {
        if (
            postRefetchActionsPending &&
            !getSavedItemsQuery.isRefetching &&
            !getSavedItemsQuery.isError &&
            getSavedItemsQuery?.data?.data
        ) {
            setPostRefetchActionsPending(false);
            saveScreenActionDispatcher({ type: 'SaveScreenAndClose', payload: undefined });
            saveScreenActionDispatcher({ type: 'SetSelectedScreen', payload: saveScreenQuery.data?.data });
        }
    }, [postRefetchActionsPending, getSavedItemsQuery, saveScreenActionDispatcher, saveScreenQuery.data?.data]);

    useEffect(() => {
        if (!saveScreenQuery.isLoading && saveScreenQuery.data?.data?.id) {
            const actionType = SavedItemOperations.SaveScreen;
            let analyticsFiltersData: Array<string> = [];

            const filterData = filtersDataUseQueryResult.data;
            if (filterData) {
                Object.values(getPostAndChipsData(filtersDirtyData, filterData).chipItems).forEach(({ chip }) => {
                    analyticsFiltersData.push(`${chip.label}${chip.values ? ` : ${chip.values}` : ''}`);
                });
            }

            registerAction({
                action: `${actionType} : ${getSelectedItem(saveScreenQuery.data.data)}`,
                cardName: `${etfScreenerCardName} : ${modalTitle}`,
                savedItemName: saveScreenQuery.data.data.name,
                savedItemId: saveScreenQuery.data.data.id,
                savedItemOperation: actionType,
                savedItemLength: analyticsFiltersData.length,
                list1: analyticsFiltersData,
            });
            setRefetchScreenDataPending(true);
        }
    }, [
        filtersDataUseQueryResult.data,
        filtersDirtyData,
        registerAction,
        saveScreenQuery?.data?.data,
        saveScreenQuery.isLoading,
    ]);

    useEffect(() => {
        if (refetchScreenDataPending) {
            setRefetchScreenDataPending(false);
            getSavedItemsQuery.refetch();
            setPostRefetchActionsPending(true);
        }
    }, [refetchScreenDataPending, getSavedItemsQuery, saveScreenQuery.data?.data]);

    const handleSaveScreenClick = (data: any) => {
        const filterData = filtersDataUseQueryResult.data;

        if (filterData) {
            const screenValue = {
                data: getFiltersReqBody(filtersPostData)?.['filters']?.['values'],
                filters: getSaveFiltersFromDirtyData(filtersDirtyData, filterData),
            };

            const payload = {
                name: data,
                saved_item_lid: 1,
                value: screenValue,
            };
            saveScreenActionDispatcher({ type: 'SaveScreen', payload });
        }
    };

    const savedScreenList = getSavedItemsQuery.data?.data?.map((item: { name: any }) => item?.name) || [];

    function handleOnModalClose() {
        if (wasFormDirty) {
            setShowExitConfirm(true);
        } else {
            saveScreenActionDispatcher({ type: 'CloseSaveScreenModal' });
        }
    }

    return (
        <>
            <SaveModal
                title={modalTitle}
                openModal={openModal}
                callbackOnClose={handleOnModalClose}
                saveType='Save screen'
                onPositiveAction={handleSaveScreenClick}
                existingNames={savedScreenList}
                onIsDirtyChange={(v) => v && setWasFormDirty(v)}
            />
            <ConfirmationModal
                openModal={showExitConfirm}
                modalText={`Exit without saving?`}
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelButtonText}`,
                        cardName: modalTitle,
                    });
                    setShowExitConfirm(false);
                }}
                cancelButtonText={cancelButtonText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmButtonText}`,
                        cardName: analyticsCardName,
                    });
                    setShowExitConfirm(false);
                    saveScreenActionDispatcher({ type: 'CloseSaveScreenModal' });
                }}
                confirmButtonText={confirmButtonText}
            />
        </>
    );
}
