import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';

export default function ScreenerSearchContainer(props: { children: React.ReactNode; itemStyle?: any }) {
    return (
        <Grid
            container
            sx={{
                paddingLeft: '29px',
                paddingRight: '30px',
            }}>
            <Grid
                item
                xs={12}
                sx={{
                    overflow: 'auto',
                    ...(props.itemStyle ? props.itemStyle : {}),
                }}>
                {props.children}
            </Grid>
        </Grid>
    );
}

export const fontStyling = {
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
};
