import { defaultAgGirdCardRowHeight } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils/objects';
import { RowHeightParams } from 'ag-grid-community';

export const noInformationAvailableCardStyles = { p: '28px' };
export const confirmButtonText = 'Confirm';
export const cancelButtonText = 'Cancel';
export const saveButtonText = 'Save Changes';
export const okButtonText = 'OK';

export const checkIfFilterInfoIsAvailable = (isLoading: boolean, data?: FiltersData) =>
    !isLoading && !(data && data.filter_metadata && data.section_mapping);

export const getRowHeightBasedOnListData = (screenerData?: ScreenerData) =>
    function (params: RowHeightParams): number | undefined | null {
        let rowHeight = defaultAgGirdCardRowHeight;

        if (!screenerData) {
            return rowHeight;
        }

        screenerData._viewdata.fields.forEach((fieldDict) => {
            const key = Object.keys(fieldDict)[0];
            let isListCell = fieldDict[key].cell_renderer_params
                ?.map((param) => param.component === 'list')
                .some((value) => value);
            if (!isListCell || !params.data) {
                return;
            }
            const dynamicHeight = getValuesByPath(params.data, key).length * defaultAgGirdCardRowHeight;
            if (dynamicHeight > rowHeight) {
                rowHeight = dynamicHeight;
            }
        });

        return rowHeight;
    };
