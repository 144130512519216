import { fontFamilies, fontWeights, MaxPageWidthString } from '@cfra-nextgen-frontend/shared/src/utils';
import Paper from '@mui/material/Paper';
import { styled, SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import Grid from '../layout/Grid/Grid';
import { Skeleton } from '../layout/Skeleton';

type ETFCardProps = {
    children?: React.ReactNode;
    cardLabel?: string;
    labelStyle?: object;
    isLoading?: boolean | undefined;
    containerStyles?: SxProps<Theme>;
    loadingContainerStyles?: SxProps;
    id?: string;
};

export const ItemHeader = styled(Paper)(({ theme }) => ({
    fontSize: '18px',
    color: '#002B5A',
    fontFamily: fontFamilies.GraphikMedium,
    paddingBottom: '36px',
    lineHeight: 1,
}));

export const ItemDescription = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    color: '#57626a',
    lineHeight: '26px',
    fontFamily: fontFamilies.GraphikRegular,
}));

export const StatisticBody = styled(Grid)(({ theme }) => ({
    ':not(:first-of-type):not(:last-child)': {
        paddingLeft: '18px',
        paddingRight: '18px',
    },
    ':first-of-type': {
        paddingRight: '18px',
    },
    ':last-child': {
        paddingLeft: '18px',
    },
}));

export const ItemValue = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: fontWeights.Light,
    textAlign: 'left',
    boxShadow: 'none',
    marginRight: '0px',
    marginTop: '7px',
    lineHeight: '22px',
}));

export const ItemSubHeader = styled(Paper)(({ theme }) => ({
    fontSize: '18px',
    fontWeight: fontWeights.Medium,
    paddingBottom: '16px',
}));

export const HorizontalPanel = styled(Grid)(({ theme }) => ({
    justifyContent: 'space-between',
    display: 'flex',
}));

export const ItemLabel = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: fontWeights.Medium,
    textAlign: 'left',
    marginTop: '7px',
    marginLeft: '0px',
    boxShadow: 'none',
    lineHeight: '22px',
}));

// props which u can override by createTheme
type CustomBoxProps = {
    paddingLeft?: string;
    paddingTop?: string;
    paddingBottom?: string;
    maxWidth?: string;
    padding?: string;
};

const CustomBox = styled(Grid, {
    name: 'MuiCustomBox',
    slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    overridesResolver: (props, styles) => [styles.root],
})<CustomBoxProps>(({ theme }) => ({
    borderBottom: 1,
    borderColor: '#EAEAEA',
    backgroundColor: '#fff',
    maxWidth: MaxPageWidthString,
    margin: 'auto',
    padding: '28px',
    width: '100%',
    marginBottom: '24px',
    borderRadius: '5px',
    ':last-child': {
        marginBottom: '0px',
    },
}));

type TotalRowBoxProps = {
    color?: string;
    fontSize?: number;
    fontFamily?: string;
};

export const TotalRowBox = styled(Grid, {
    name: 'MuiTotalRowBox',
    slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    overridesResolver: (props, styles) => [styles.root],
})<TotalRowBoxProps>(({ theme }) => ({
    color: '#57626a',
    fontSize: 14,
    fontFamily: fontFamilies.GraphikMedium,
    //lineHeight: '54px',
    paddingTop: '16px',
    paddingBottom: '12px',
    flexDirection: 'column',
    justifyContent: 'center',
}));

export const ETFCard = React.forwardRef<HTMLDivElement, ETFCardProps>((props, ref) => {
    const { children, cardLabel, labelStyle, isLoading, containerStyles, id } = props;
    if (isLoading) {
        return (
            <CustomBox sx={props.loadingContainerStyles}>
                <Skeleton />
            </CustomBox>
        );
    }

    return (
        <CustomBox
            container
            ref={ref}
            alignItems='baseline'
            sx={containerStyles}
            id={id}
            className={'data-cy-etf-card'}>
            {typeof cardLabel !== 'undefined' ? (
                <Grid item xs={12}>
                    <ItemHeader sx={labelStyle}>{cardLabel}</ItemHeader>
                </Grid>
            ) : null}
            {children}
        </CustomBox>
    );
});
