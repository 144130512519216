import {
    CreateSavedItemResponseTypes,
    SavedItemListResponseTypes,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { UseData } from 'utils';

export function getSavedItemsByType({ types = [] }: SearchByParams) {
    return UseData<SavedItemListResponseTypes>({
        requestQuery: getRequestQuery({ types: types }, 'saved-items'),
        queryKey: ['getSavedItemsByType', types],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.GET,
    });
}

export function getSavedItemById({ savedItems, config }: SearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`getSavedItemById received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseData<any>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['getSavedItemById', savedItems],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.GET,
        config,
    });
}

export function deleteSavedItemById({ savedItems, config }: SearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`deleteSavedItemById received unexpected type of savedItems - ${typeof savedItems}`);
    }
    return UseData<any>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey: ['deleteSavedItemById', savedItems],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.DELETE,
        config,
    });
}

export function createSavedItem({ requestBody, config }: SearchByParams) {
    return UseData<CreateSavedItemResponseTypes>({
        requestQuery: 'saved-items',
        queryKey: ['createSavedItem', requestBody],
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.POST,
        requestBody,
        config,
    });
}

export function updateSavedItemById({ savedItems, requestBody, config }: SearchByParams) {
    if (typeof savedItems != 'number') {
        throw new Error(`updateSavedItemById received unexpected type of savedItems - ${typeof savedItems}`);
    }
    const queryKey = ['updateSavedItemById', requestBody, config];
    queryClient.invalidateQueries(queryKey);
    return UseData<CreateSavedItemResponseTypes>({
        requestQuery: getRequestQuery({ savedItems: savedItems }),
        queryKey,
        apiName: ApiNames.UserManagement,
        requestType: RequestTypes.PUT,
        requestBody,
        config,
    });
}
