import { Layout } from '@cfra-nextgen-frontend/shared';
import { CustomTabPanel } from '@cfra-nextgen-frontend/shared/src/components/TabPanel/CustomTabPanel';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Tab, Tabs, ThemeProvider, createTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FiltersModalContext } from '../FiltersModalContext';
import { ContainerProps } from './types';

const tabsTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                scrollButtons: {
                    '&.Mui-disabled': {
                        width: 0,
                        opacity: 0,
                    },
                },
                root: {
                    borderBottom: '2px solid #EDF0F6',
                    paddingLeft: 30,
                    paddingRight: 30,
                },
                indicator: {
                    backgroundColor: '#3078B5',
                    height: 2,
                    webkitTransition: 'none',
                    transition: 'none',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    height: 50,
                    color: '#0B2958',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: 15,
                    textTransform: 'none',
                    padding: '13px 0px',
                    '&.Mui-selected': {
                        color: '#3078B5',
                    },
                    '& + &': {
                        marginLeft: 20,
                    },
                },
            },
        },
    },
});

const getNavigationBackgroundColorFor = (level?: number) => {
    if (!level) {
        return;
    }

    switch (level) {
        case 2:
            return '#F8F8F8';
        default:
            return '#FFF';
    }
};

export const FiltersTabs: React.FC<ContainerProps> = (props) => {
    const { activeIndex } = useContext(FiltersModalContext);
    const [currentActiveTab, setCurrentActiveTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => setCurrentActiveTab(newValue);
    const firstTab = props?.sectionsWithChildren?.[0];
    const parentSectionName = props.parentSection ? props.parentSection?.name.toLocaleLowerCase() : 'root';
    const parentSectionKeyString = firstTab?.section.parent ? ` (key ${firstTab.section.parent})` : '';
    const componentAriaLabel = `${parentSectionName} tabs` + parentSectionKeyString;
    const level = firstTab?.section.level;
    const tabsRowHeightInPX = 50;

    useEffect(() => {
        if (firstTab?.section) {
            setCurrentActiveTab(activeIndex[firstTab.section.parent || 'root'] || 0);
        }
    }, [activeIndex]);

    return (
        <ThemeProvider theme={tabsTheme}>
            <Tabs
                value={currentActiveTab}
                onChange={handleChange}
                aria-label={componentAriaLabel}
                variant='scrollable'
                allowScrollButtonsMobile
                sx={{
                    minHeight: `${tabsRowHeightInPX}px`,
                    backgroundColor: getNavigationBackgroundColorFor(level),
                }}>
                {props.sectionsWithChildren?.map((sectionWithChildren, index) => {
                    return (
                        <Tab
                            label={sectionWithChildren.section.name}
                            key={index}
                            {...Layout.a11yProps(index)}
                            onClick={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: `${componentAriaLabel} : ${sectionWithChildren.section.name.toLocaleLowerCase()}`,
                                    cardName: props.analyticsCardName,
                                });
                            }}
                        />
                    );
                })}
            </Tabs>
            {React.Children.toArray(
                props.sectionsWithChildren?.map((sectionWithChildren, index) => {
                    return (
                        <CustomTabPanel
                            index={index}
                            value={currentActiveTab}
                            style={{
                                overflowY: props.isSearchMode ? 'visible' : 'auto',
                            }}>
                            {sectionWithChildren.children}
                        </CustomTabPanel>
                    );
                }),
            )}
        </ThemeProvider>
    );
};
