import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@emotion/react';
import { SxProps } from '@mui/material';
import React from 'react';
import { StyledFormLabel } from './StyledFormLabel';

const maxMUIGridSize = 12;
const transformLayoutToPercents = (layout: number) => ((layout / maxMUIGridSize) * 100).toFixed(4);

export const FormLabelWithContainer: React.FC<{
    label: string;
    children: React.ReactNode;
    layoutProps?: LayoutProps;
    disableStacking?: boolean;
    labelLayout?: number;
    containerLayout?: number;
    wrapperStyles?: SxProps;
    childrenContainerStyles?: SxProps;
    hide: boolean;
}> = ({
    label,
    children,
    disableStacking,
    labelLayout = 5,
    layoutProps = { xs: 12 },
    hide,
    containerLayout = 12 - labelLayout,
    wrapperStyles,
    childrenContainerStyles,
}) => {
    const oneRowCSS = {
        justifyContent: 'start',
        width: `${transformLayoutToPercents(containerLayout)}% !important`,
    };

    let stackingRules = disableStacking
        ? { paddingLeft: '30px', ...oneRowCSS }
        : {
              width: '100%',
              justifyContent: 'start',
              paddingLeft: '30px',
              [breakpointsTheme.breakpoints.up('sm')]: oneRowCSS,
          };

    return (
        <ThemeProvider theme={breakpointsTheme}>
            <Grid
                item
                container
                sx={{
                    display: hide ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #EDF0F6',
                    borderRight: '0px',
                    marginRight: '-0.8px',
                    marginTop: '-0.8px',
                    minHeight: '43.6px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    ...wrapperStyles,
                }}
                {...layoutProps}>
                {label && (
                    <Grid
                        xs={disableStacking ? labelLayout : 12}
                        sm={labelLayout}
                        item
                        sx={{ paddingLeft: '30px', paddingBottom: '6px' }}>
                        <StyledFormLabel sx={{ lineHeight: 1.2 }}>{label}</StyledFormLabel>
                    </Grid>
                )}
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        paddingRight: '15px',
                        ...stackingRules,
                        ...childrenContainerStyles,
                    }}>
                    {children}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};
