import { IServerSideGetRowsParams, SortModelItem } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import {
    IMetadataFields,
    ScreenerData,
    ScreenerEtfData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';

export const getRowID = (params: any) => {
    // params.data should contain id, otherwise the grid will not work
    return params.data.id;
};

const getSortFields = (sortModel: SortModelItem[], metadataFields: IMetadataFields[]) => {
    let querySort = {};

    let getSortQueryField = (fieldName: string) =>
        metadataFields.filter((dict) => Object.keys(dict)[0] === fieldName)?.[0]?.[fieldName].source_field;

    if (sortModel.length > 0) {
        querySort = {
            sortDirection: sortModel.map((sort) => sort.sort).join(','),
            orderBy: sortModel.map((sort) => getSortQueryField(sort.colId)).join(','),
        };
    }
    return querySort;
};

type GetScreenerDataSSRFunc = (props: SearchByParams) => Promise<ScreenerEtfData | ScreenerData>;

export type GetSsrDataExportFn = ReturnType<typeof determineGetSsrDataExportFn>;

export const determineGetSsrDataExportFn = (
    getScreenerDataSSR: GetScreenerDataSSRFunc,
    resultsKey: 'etf_data' | 'data',
) =>
    function ({
        metadataFields,
        requestParams,
    }: {
        metadataFields: IMetadataFields[];
        requestParams?: SearchByParams;
    }) {
        return async function (sortColumns: SortModelItem[]) {
            return getScreenerDataSSR({
                ...requestParams,
                ...getSortFields(sortColumns, metadataFields),
            }).then((response: any) => {
                return response?.results?.[resultsKey] || [];
            });
        };
    };

export type GetDataSource = ReturnType<typeof determineGetDataSource>;

type DetermineGetDataSourceType = (
    getScreenerDataSSR: GetScreenerDataSSRFunc,
    resultsKey: 'etf_data' | 'data',
) => (props: {
    metadataFields: IMetadataFields[];
    etfData: Array<{
        [key: string]: any;
    }>;
    requestParams?: SearchByParams;
}) => {
    getRows(params: IServerSideGetRowsParams): Promise<void> | undefined;
};

export const determineGetDataSource: DetermineGetDataSourceType =
    (getScreenerDataSSR, resultsKey) =>
    ({ metadataFields, etfData, requestParams }) => ({
        getRows(params: IServerSideGetRowsParams) {
            if (
                params.request.sortModel.length === 0 &&
                (!params.request.startRow || (params.request.startRow && params.request.startRow === 0))
            ) {
                params.success({ rowData: etfData });
            } else {
                return getScreenerDataSSR({
                    ...requestParams,
                    from: params.request.startRow,
                    size: params.request.endRow,
                    ...getSortFields(params.request.sortModel, metadataFields),
                }).then((response: any) => {
                    if (response) {
                        params.success({ rowData: response.results?.[resultsKey] || [] });
                    }
                });
            }
        },
    });
