import { UnAggregatedScreenerFieldData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedViews';
import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { LinkTargetTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps } from '@mui/material/styles';

export type FiltersData = {
    section_mapping: SectionMapping;
    filter_metadata: FiltersMetadata;
    data: Data;
    isErroredResponse?: boolean;
};

type Data = {
    [key: string]: {
        items: Array<Item>;
        min?: number;
        max?: number;
        value?: string | number;
    };
};

export type Item = {
    key: number;
    value: string;
    count?: number;
};

export type StringKeyValueItemWithData = { key: string; value: string } & UnAggregatedScreenerFieldData;

export type FiltersMetadata = { [key: string]: FilterMetadata };

export type FilterMetadata = CommonFilterMetadata & Sections & RequestMapping & ItemData & FilterFileRelatedMetadata;

export type Containers = { [key: string]: { layout: LayoutProps } };

export type Section = {
    containers?: Containers;
    layout?: LayoutProps;
    styles?: {
        title: SxProps;
        block_below_children: SxProps;
    };
    level: number;
    name: string;
    order: number;
    parent?: string;
    show_section_title?: boolean;
};

type Option = {
    label?: string;
    key: number;
};

export type SectionMapping = {
    [key: string]: Section;
};

export enum Components {
    Autocomplete = 'autocomplete',
    AutoCompleteFreeSolo = 'autocomplete-freesolo',
    AutocompleteOutsideChips = 'autocomplete-outside-chips',
    AutocompletePicklist = 'autocomplete_picklist',
    AutocompleteSearchTable = 'autocomplete_with_search_and_table',
    Checkbox = 'checkbox',
    DatePicker = 'datepicker',
    DateRangePicker = 'daterangepicker',
    DraggablePicklist = 'draggable_picklist',
    Dropdown = 'dropdown',
    FilePicker = 'file_picker',
    Hidden = 'hidden',
    Rating = 'rating',
    Slider = 'slider',
    StaticText = 'static_text',
    Switch = 'switch',
    Text = 'text',
}

export type FilterFileRelatedMetadata = {
    file_validation_config?: FileValidationConfig;
    file_column_name_to_request_key?: Record<string, string>;
};

export type FileValidationConfig = {
    accept: string;
    columns: Array<SingleColumnValidationConfig>;
};

export type SingleColumnValidationConfig = {
    columnName: string;
    required?: boolean;
    validators?: Array<string>;
};

export type CommonFilterMetadata = {
    caption?: string;
    component: Components;
    default_value?: boolean | string | number | null;
    default_values: Array<number> | null;
    format?: string;
    hide?: boolean;
    label: string;
    link_target?: LinkTargetTypes;
    link?: boolean;
    options: Array<Option>;
    placeholder?: string;
    range: [number, number];
    request_default_value?: any;
    request_value: { value?: string; excluded_value?: string } | null;
    response_mapping?: string;
    response_value?: Record<string, string>;
    selected_label?: string;
    selected_template?: string;
    source_field?: string;
    sub_label?: string;
    validations?: Validations;
    helperTexts?: [string, string];
    dependencies?: Array<string>;
    dropField?: { create?: boolean; edit?: boolean };
    disabled?: boolean;
    makeReadOnlyOnDefaultValues?: boolean;
};

export type Validations = {
    [key: string]: { [key: string]: any };
};

export enum CustomValidationTypes {
    AllowDuplicates = 'allow_duplicates',
    Email = 'email',
}

export enum CustomValueFormatterTypes {
    IsUserEnabled = 'is_user_enabled',
    Format_Yes_No = 'format_yes_no',
}

export enum DisplayFormatterTypes {
    EnableOnlyWhenTrue = 'enable_only_when_true',
}

type RowFilterAction = {
    filter_value: string;
    request_values: Record<string, unknown>;
};

export type Sections = {
    sections: {
        [key: string]: {
            order: number;
            container?: string;
            layout?: LayoutProps;
            wrapper_styles?: SxProps;
            stack_label_and_filter?: boolean;
            show_asterisk?: boolean;
            actions?: Array<RowFilterAction>;
        };
    };
};

export type RequestMapping = {
    request_mapping: string | FilterRequestValues;
};

export type FilterRequestValues = {
    [key: string]: {
        request_value: { values?: Array<string>; excluded_values?: Array<string> };
    };
};

export type ItemData = {
    item_metadata: MetadataFieldDefinition;
};
