import { Typography, Utils } from '@cfra-nextgen-frontend/shared';
import { appTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Link, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { invert } from 'lodash';
import { Link as RouterLink, useLocation } from 'react-router-dom';

type BreadcrumbsProps = {
    pathMapping: Record<string, string>;
    analyticsCallback: (pathValue: string) => void;
};

let theme = createTheme(appTheme, {
    components: {
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: Utils.fontFamilies.GraphikMedium,
                    fontSize: '13px',
                    display: 'flex',
                    color: '#0B2958',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: Utils.fontFamilies.GraphikRegular,
                    fontSize: '13px',
                    color: '#3078B5',
                    display: 'flex',
                },
            },
        },
    },
});

export function Breadcrumbs({ pathMapping, analyticsCallback }: BreadcrumbsProps) {
    pathMapping = { Home: '', ...pathMapping };
    const pathNamesMapping = invert(pathMapping);
    const pathNames = useLocation().pathname.split('/');

    return (
        <ThemeProvider theme={theme}>
            <MuiBreadcrumbs aria-label='breadcrumb'>
                {pathNames.map((value: string, index: number) => {
                    const to = `/${pathNames.slice(index ? 1 : 0, index + 1).join('/')}`;
                    if (index + 1 === pathNames.length)
                        return <Typography key={index}>{pathNamesMapping[value]}</Typography>;

                    return (
                        <Link
                            underline='hover'
                            component={RouterLink}
                            to={to}
                            key={index}
                            onClick={() => analyticsCallback(value)}>
                            {pathNamesMapping[value]}
                        </Link>
                    );
                })}
            </MuiBreadcrumbs>
        </ThemeProvider>
    );
}
