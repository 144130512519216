import { Layout } from '@cfra-nextgen-frontend/shared';
import { ResultItem, ResultRow } from '@cfra-nextgen-frontend/shared/src/components/Dropdown/Dropdown';
import { breakpointsTheme, scrollbarTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { SearchResult } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch';
import { OPENSEARCH_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import {
    Autocomplete,
    InputBase,
    Popper,
    PopperPlacementType,
    PopperProps,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as SearchIcon } from 'assets/images/SearchIcon.svg';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosAuth } from 'utils/api-common';
import { AutoSuggestETF } from '../types/opensearch';

const axios = axiosAuth(OPENSEARCH_URL);

type TypeSearchProps = {
    showInput: boolean;
    setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
    analyticsAction: string;
    navigateOnClick?: boolean;
    setSelectedValue?: (value: AutoSuggestETF) => void;
    overrideSearchBarMainContainerStyle?: object;
    customTheme?: object;
    popperPlacement?: PopperPlacementType;
};

export function TypeSearch({
    showInput,
    setShowInput,
    setSelectedValue,
    analyticsAction,
    navigateOnClick = true,
    popperPlacement = 'bottom-end',
    overrideSearchBarMainContainerStyle = {},
    customTheme = undefined,
}: TypeSearchProps) {
    let theme = createTheme(breakpointsTheme);
    const navigate = useNavigate();
    const isBelow500px = useMediaQuery('(max-width:500px)');

    const SearchBarItemStyles = {
        '&:last-child': {
            paddingRight: '24px',
            paddingLeft: '24px',
            [theme.breakpoints.down('sm')]: {
                cursor: 'pointer',
            },
        },
        '&:first-of-type': {
            [theme.breakpoints.down('sm')]: {
                flexGrow: showInput ? 1 : '',
            },
        },

        display: 'flex',
        alignItems: 'center',
    };

    theme = createTheme(theme, {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        width: 24,
                        height: 24,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: 44,
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                        },
                    },
                    input: {
                        font: fontFamilies.GraphikRegular,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                        [theme.breakpoints.down('sm')]: {
                            width: !showInput && '0px',
                            padding: !showInput && '0px',
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '180px',
                        },
                        paddingLeft: '24px',
                    },
                },
                defaultProps: {
                    placeholder: 'Search by ticker or name',
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                        boxShadow: 'none',
                    },
                    root: {
                        [theme.breakpoints.down('sm')]: {
                            flexGrow: '1 !important',
                        },
                    },
                    listbox: {
                        padding: '12px 0px 12px',
                        ...scrollbarTheme,
                    },
                    option: {
                        padding: '12px 24px 12px !important',
                        ':hover': {
                            backgroundColor: '#f7f6f7',
                        },
                    },
                },
            },
        },
    });

    if (customTheme) {
        theme = createTheme(theme, customTheme);
    }

    const SearchBarMainContainerStyles = {
        width: 'auto',
        borderRadius: 24,
        backgroundColor: '#f2f5fc',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexGrow: showInput ? 1 : '',
        },
    };

    const fetch = React.useMemo(
        () =>
            debounce(async (request: { input: string }, callback: (results?: SearchResult<AutoSuggestETF>) => void) => {
                const result = await axios.get('/search/v1/suggester', { params: { q: request.input } });
                callback(result.data);
            }, 200),
        [],
    );

    const [inputValue, setInputValue] = React.useState('');
    const [previousInputValue, setPreviousInputValue] = React.useState('');
    const [value, setValue] = React.useState<AutoSuggestETF | null>(null);
    const [options, setOptions] = React.useState<readonly AutoSuggestETF[]>([]);
    const [noOptionsValue, setNoOptionsValue] = useState<string>('');
    const [inputboxFocused, setInputboxFocused] = useState<boolean>(false);

    const setInputValueHandler = (newValue: string): void => {
        setPreviousInputValue(inputValue);
        setInputValue(newValue);
    };

    const clean = function () {
        setValue(null);
        setInputValue('');
        setPreviousInputValue('');
    };

    React.useEffect(() => {
        let active = true;

        if (value) {
            if (navigateOnClick) {
                navigate(`/etf/ticker/${value.composite_ticker}/exchange/${value.exchange_code}`);
            }
            globalThis.analytics?.registerAction?.({
                action: analyticsAction,
                searchTerm: previousInputValue,
                searchResults: 'valid search',
                ticker: value.composite_ticker,
                etfName: value.composite_name,
                etfAssetClass: value.asset_class,
                etfExchange: value.exchange_code,
                cfraId: value.firstbridge_id,
            });
            clean();
            setSelectedValue?.(value);

            return;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return;
        }

        fetch({ input: inputValue }, (results?: SearchResult<AutoSuggestETF>) => {
            if (active) {
                let newOptions: readonly AutoSuggestETF[] = results ? results.hits.hits.map((hit) => hit._source) : [];
                if (!results || results.hits.hits.length === 0) {
                    globalThis.analytics?.registerAction?.({
                        action: analyticsAction,
                        searchTerm: inputValue,
                        searchResults: 'invalid search',
                    });
                    setNoOptionsValue(inputValue);
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, previousInputValue, fetch, navigate, navigateOnClick, setSelectedValue, analyticsAction]);

    const PopperMy = function (props: PopperProps) {
        return (
            <Popper
                {...props}
                style={{
                    width: isBelow500px ? 400 : 500,
                    zIndex: 3000,
                }}
                placement={popperPlacement}
                modifiers={[{ name: 'offset', options: { offset: [90, 10] } }]}
            />
        );
    };
    let boxBorder = {};
    if (inputboxFocused) {
        boxBorder = { border: '1px solid #1976d2' };
    }

    return (
        <ThemeProvider theme={theme}>
            <Layout.Grid
                container
                sx={{ ...SearchBarMainContainerStyles, ...overrideSearchBarMainContainerStyle, ...boxBorder }}>
                <Layout.Grid item sx={SearchBarItemStyles}>
                    <Autocomplete
                        open={options.length > 0 || noOptionsValue !== ''}
                        noOptionsText={`No suggestions for "${noOptionsValue}"`}
                        getOptionLabel={(option) => option.composite_name}
                        filterOptions={(x) => x}
                        options={options}
                        PopperComponent={PopperMy}
                        autoComplete
                        clearOnEscape
                        autoHighlight
                        value={value}
                        onClose={() => setShowInput(false)}
                        onBlur={() => setInputboxFocused(false)}
                        onChange={(event: any, newValue: AutoSuggestETF | null) => {
                            setValue(newValue);
                        }}
                        onFocus={() => setInputboxFocused(true)}
                        onInputChange={(event, newInputValue) => {
                            setInputValueHandler(newInputValue);
                            setNoOptionsValue('');
                        }}
                        renderInput={(params) => (
                            <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <ResultRow container alignItems='left'>
                                        <ResultItem item xs={2}>
                                            {option.composite_ticker}
                                        </ResultItem>
                                        <ResultItem item xs={6}>
                                            {option.composite_name}
                                        </ResultItem>
                                        <ResultItem item xs={4}>
                                            {option.asset_class}
                                        </ResultItem>
                                    </ResultRow>
                                </li>
                            );
                        }}
                    />
                </Layout.Grid>
                <Layout.Grid
                    item
                    sx={SearchBarItemStyles}
                    onClick={() => {
                        setShowInput(!showInput);
                    }}>
                    <SvgIcon viewBox='0 0 40 40'>
                        <SearchIcon />
                    </SvgIcon>
                </Layout.Grid>
            </Layout.Grid>
        </ThemeProvider>
    );
}
