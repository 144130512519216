import { RoundedIconTextButton } from '@cfra-nextgen-frontend/shared';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { WatchListIcon } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListIcon';
import { WatchListButtonTheme } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useContext, useState } from 'react';
import { getSavedItemById, getSavedItemsByType, updateSavedItemById } from '../../api/userSavedItems';
import { WatchListRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { createTheme } from '@mui/material';


type UpdateWatchlistButtonType = {
    cardName: string;
    disabled: boolean;
    buttonText?: string;
    appendWatchlistData?: WatchListRow[];
    onClickCallBack?: () => void;
    hideIcon?: boolean;
    customTheme?: object;
};

export function UpdateWatchlistButton(props: UpdateWatchlistButtonType) {
    const [updateWatchlistValue, setUpdateWatchlistValue] = useState<any>(undefined);

    const {
        watchListState: { selectedWatchlist },
        watchListDispatcher,
    } = useContext(WatchListContext);
    const { setSearchTerm } = useContext(FiltersModalContext);
    const { selectedRowIds, clearRowsSelections } = useContext(AgGridSelectedRowsContext);

    const buttonText = props.buttonText || 'Update Watchlist';

    const updateWatchlistQry = updateSavedItemById({
        savedItems: selectedWatchlist?.id,
        requestBody: updateWatchlistValue,
        config: {
            enabled: updateWatchlistValue !== undefined,
        },
    });

    const getWatchlistByIdQry = getSavedItemById({ savedItems: selectedWatchlist?.id });

    const refetchUpdatedData = Boolean(
        updateWatchlistValue &&
            updateWatchlistQry &&
            !updateWatchlistQry.isLoading &&
            updateWatchlistQry.data?.data?.id,
    );

    const getSavedItemsByTypeQry = getSavedItemsByType({
        types: [UserSavedItemsLookupID.Watchlist],
        config: { enabled: refetchUpdatedData },
    });

    if (refetchUpdatedData) {
        refetchWatchlists();
    }

    async function refetchWatchlists() {
        getSavedItemsByTypeQry.refetch();
        const { data, isRefetchError, isRefetching, isLoading } = await getWatchlistByIdQry.refetch();
        if (!isLoading && !isRefetching && !isRefetchError && data?.data?.id) {
            setSearchTerm('');
            clearRowsSelections();
            watchListDispatcher({ type: 'SetSelectedWatchlistDetails', payload: data?.data });
            setUpdateWatchlistValue(undefined);
        }
    }

    const handleUpdateScreenClick = () => {
        let selectedItems = selectedWatchlist?.value.values?.filter((item: { id: string }) => selectedRowIds.includes(item?.id)) || [];

        if (props.appendWatchlistData?.length && props.appendWatchlistData?.length > 0) {
            selectedItems = [...selectedItems, ...props.appendWatchlistData];
        }

        const payload = {
            name: selectedWatchlist?.name,
            value: {
                values: selectedItems,
            },
        };

        setUpdateWatchlistValue(payload);
    };

    let theme = WatchListButtonTheme;
    if (props.customTheme) {
        theme = createTheme(theme, props.customTheme);
    };

    return (
        <RoundedIconTextButton
            theme={theme}
            icon={!props.hideIcon ? <WatchListIcon /> : undefined}
            buttonText={buttonText}
            disabled={props.disabled}
            onClickCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${buttonText}`,
                    cardName: props.cardName,
                });
                handleUpdateScreenClick();
                props.onClickCallBack?.();
            }}></RoundedIconTextButton>
    );
}
