import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { SearchByParams, getRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UseData } from 'utils/api';

export function getFiltersData({ requestBody, securityType = 'etf' }: SearchByParams) {
    return UseData<FiltersData>({
        requestQuery: getRequestQuery({ securityType }, 'screener-filters'),
        queryKey: ['getFiltersData', securityType, requestBody],
        apiName: ApiNames.OpenSearch,
        requestType: RequestTypes.POST,
        requestBody: requestBody,
    });
}
